import React, { useContext, useEffect, useState, createContext } from "react";
import { Link, Outlet } from "react-router-dom";
import { AppContext } from "../../../App";

export const StaffSplitMembersDashboardContext = createContext(null);

const SplitMembersPage = () => {
    const [splitMemberCount, setSplitMemberCount] = useState(0);
    const [editRequestCount, setEditRequestCount] = useState(0);
    const [photoRequestCount, setPhotoRequestCount] = useState(0);
    const [updateCount, setUpdateCount] = useState(0);

    const { request } = useContext(AppContext);

    const handleSplitMemberCount = (newSplitMemberCount) => {
        setSplitMemberCount(newSplitMemberCount);
    };

    useEffect(() => {
        const fetchRequestCounts = async () => {
            try {
                const response = await request({
                    url: `/staff/split-members/request-counts`,
                    method: "GET",
                });

                const { editCount, photoCount } = response;
                setEditRequestCount(editCount);
                setPhotoRequestCount(photoCount);
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching request counts:", error);
            }
        };

        fetchRequestCounts();
    }, [request, updateCount]);

    const refreshCounts = () => {
        setUpdateCount((prev) => prev + 1);
    };

    const determineTab = () => {
        if (window.location.pathname.includes("edit-requests")) {
            return "edit";
        } else if (window.location.pathname.includes("photo-requests")) {
            return "photo";
        }

        return "split";
    };

    return (
        <div className="">
            <div className="divide-y divide-solid bg-white w-full" style={{ position: "fixed", top: 0, zIndex: 10 }}>
                <div className="flex flex-row w-1/2 pt-5 ml-10">
                    <Link
                        to={"/staff/split-members"}
                        className={`${determineTab() === "split" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7`}
                    >
                        SPLIT MEMBERS {determineTab() === "split" ? `(${splitMemberCount})` : null}
                    </Link>
                    <Link
                        to={"/staff/split-members/edit-requests"}
                        className={`${determineTab() === "edit" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7`}
                    >
                        PROFILE EDIT REQUESTS ({editRequestCount})
                    </Link>
                    <Link
                        to={"/staff/split-members/photo-requests"}
                        className={`${determineTab() === "photo" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7`}
                    >
                        PROFILE PHOTO REQUESTS ({photoRequestCount})
                    </Link>
                </div>

                <div></div>
            </div>

            <div className="mt-20">
                <StaffSplitMembersDashboardContext.Provider value={{ handleSplitMemberCount, refreshCounts }}>
                    <Outlet />
                </StaffSplitMembersDashboardContext.Provider>
            </div>
        </div>
    );
};

export default SplitMembersPage;
