import React, { createContext, useEffect, useMemo, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Routes, Route, useNavigate, Outlet, Navigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import mitt from "mitt"
import Staff from "./components/staff/Staff";
import { CallbackPage } from "./components/auth/CallbackPage";
import { AuthenticationGuard } from "./components/auth/AuthenticationGuard";
import { StaffAuthenticationGuard } from "./components/auth/StaffAuthenticationGuard";
import { Redirect } from "./components/auth/Redirect";
import WelcomeFlow from "./components/splitMember/WelcomeFlow";
import Timeline from "./components/splitMember/Timeline";
import Application from "./components/splitMember/Application";
import Dashboard from "./components/splitMember/Dashboard";
import FamilyDashboard from "./components/intendedParent/Dashboard";
import DonorSearch from "./components/intendedParent/DonorSearch";
import Favorites from "./components/intendedParent/Favorites";
import StaffSMFavorites from "./components/staff/split_members/split_member/Favorites"
import KeepDashboard from "./components/keepMember/KeepDashboard";
import Intakes from "./components/intendedParent/Intake";
import DonorProfile from "./components/intendedParent/DonorProfile";
import SMChecklist from "./components/splitMember/Checklist";
import SMProfile from "./components/splitMember/SMProfile";
import SMPhotos from "./components/splitMember/SMPhotos";
import SMDocuments from "./components/splitMember/SMDocuments";
import SMSettings from "./components/splitMember/SMSettings";
import IPChecklist from "./components/intendedParent/IPChecklist";
import IPDocuments from "./components/intendedParent/IPDocuments";
import IPDonorPhotos from "./components/intendedParent/IPDonorPhotos";
import IPSettings from "./components/intendedParent/IPSettings";
import HoldMatch, { HoldSuccess } from "./components/intendedParent/checkout/HoldMatch";
import FreezeAppFAQ from "./components/faq/FreezeAppFAQ";
import FreezeAppSubmittedFAQ from "./components/faq/FreezeAppSubmittedFAQ";
import FamilyFAQ from "./components/faq/FamilyFAQ";
import { setRefreshUser, setRequest } from "./util/helpers";
import FreezeLoginPage from "./components/auth/FreezeLoginPage";
import FamilyLoginPage from "./components/auth/FamilyLoginPage";
import FreezeSignupPage from "./components/auth/FreezeSignupPage";
import FamilySignupPage from "./components/auth/FamilySignupPage";
import FreezeActivationPage from "./components/auth/FreezeActivationPage";
import FamilyActivationPage from "./components/auth/FamilyActivationPage";
import StaffLoginPage from "./components/auth/StaffLoginPage";
import KeepChecklist from "./components/keepMember/KeepChecklist";
import KeepSettings from "./components/keepMember/KeepSettings";
import PDFProfile from "./components/staff/split_members/split_member/pdf_profile";
import { PageNotFoundCatchAll } from "./components/errors/RootErrorBoundary";
import { RootFallback } from './components/errors/RootErrorBoundary';
import LandingPage from "./components/auth/LandingPage";
import Searches from "./components/intendedParent/Searches";
import { IntakeRedirect } from "./components/auth/IntakeRedirect";
import SplitMembersPage from "./components/staff/split_members/SplitMembersPage";
import EditRequests from "./components/staff/split_members/EditRequests";
import PhotoRequests from "./components/staff/split_members/PhotoRequests";
import SplitMembers from "./components/staff/split_members/SplitMembers";
import SplitMemberPage from "./components/staff/split_members/split_member/SplitMemberPage";
import StaffSMOverview from "./components/staff/split_members/split_member/Overview";
import StaffSMProfile from "./components/staff/split_members/split_member/Profile";
import StaffSMActivity from "./components/staff/split_members/split_member/Activity";
import Documents from "./components/staff/files/Documents";
import Notes from "./components/staff/notes/Notes";
import AuditLogs from "./components/staff/audit_logs/AuditLogs";
import SMAdminChecklist from "./components/staff/split_members/split_member/Checklist";
import StaffSMViews from "./components/staff/split_members/split_member/Views";
import StaffSMPhotos from "./components/staff/split_members/split_member/StaffSMPhotos";
import PublicProfile from "./components/profile/Profile";
import PhotoViewer from "./components/staff/files/Photos/PhotoViewer"
import IntendedParentsPage from "./components/staff/intended_parents/IntendedParentsPage";
import IntendedParents from "./components/staff/intended_parents/IntendedParents";
import IntendedParentPage from "./components/staff/intended_parents/intended_parent/IntendedParentPage";
import StaffIPOverview from "./components/staff/intended_parents/intended_parent/Overview"
import StaffIPActivity from "./components/staff/intended_parents/intended_parent/Activity"
import StaffIPFavorites from "./components/staff/intended_parents/intended_parent/Favorites"
import StaffIPViews from "./components/staff/intended_parents/intended_parent/Viewed"
import StaffIPSearches from "./components/staff/intended_parents/intended_parent/Searches"
import MatchesPage from "./components/staff/matches/MatchesPage";
import Matches from "./components/staff/matches/Matches";
import MatchPage from "./components/staff/matches/match/MatchPage";


export const AppContext = createContext(null)

const emitter = mitt()

const App = () => {
    const [loggedInUser, setLoggedInUser] = useState(null)
    const { isLoading, getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate()

    const request = useMemo(() => {
        return setRequest(navigate, getAccessTokenSilently);
    }, [getAccessTokenSilently]);

    const refreshUser = useMemo(() => {
        return setRefreshUser(navigate, setLoggedInUser, request);
    }, [request, setLoggedInUser]);

    if (isLoading) {
        return <div></div>;
    }

    return (
        <AppContext.Provider value={{ loggedInUser, setLoggedInUser, refreshUser, request, emitter }}>
            <Outlet />
        </AppContext.Provider>
    )
}

const AppRouter = () => {
    return (
        <ErrorBoundary FallbackComponent={RootFallback}>
            <Routes>
                <Route element={<App />} >
                    <Route path="callback" element={<CallbackPage />} />
                    <Route path="redirect" element={<AuthenticationGuard component={Redirect} />} />
                    <Route path="family/intake" element={<AuthenticationGuard component={IntakeRedirect} />} />

                    <Route path="introscreener" element={<FreezeLoginPage />} />
                    <Route path="freeze/login" element={<FreezeLoginPage />} />
                    <Route path="freeze/signup" element={<FreezeSignupPage />} />
                    <Route path="freeze/activation" element={<FreezeActivationPage />} />

                    <Route path="freeze/welcome" element={<AuthenticationGuard component={WelcomeFlow} />} />
                    <Route path="freeze/timeline" element={<AuthenticationGuard component={Timeline} />} />
                    <Route path="freeze/application/faq" element={<AuthenticationGuard component={FreezeAppFAQ} />} />
                    <Route path="freeze/application" element={<AuthenticationGuard component={Application} />} />

                    <Route path="freeze" element={<AuthenticationGuard component={Dashboard} />}>
                        <Route path="dashboard" element={<SMChecklist />} />
                        <Route path="profile/photos/upload" element={<SMPhotos />} />
                        <Route path="profile" element={<SMProfile />} />
                        <Route path="documents" element={<SMDocuments />} />
                        <Route path="settings" element={<SMSettings />} />
                        <Route path="faq" element={<AuthenticationGuard component={FreezeAppSubmittedFAQ} />} />

                        <Route index element={<Navigate to="dashboard" replace />} />
                    </Route>
                    
                    <Route path="family/login" element={<FamilyLoginPage />} />
                    <Route path="family/signup" element={<FamilySignupPage />} />
                    <Route path="family/activation" element={<FamilyActivationPage />} />

                    <Route path="family/application" element={<AuthenticationGuard component={Intakes} />} />
                    
                    <Route path="family/donors/:userId/hold-match" element={<AuthenticationGuard component={HoldMatch} />} />
                    <Route path="family/donors/:userId/hold-success" element={<AuthenticationGuard component={HoldSuccess} />} />

                    <Route path="family" element={<AuthenticationGuard component={FamilyDashboard} />} >
                        <Route path="checklist" element={<IPChecklist />} />
                        <Route path="donors/:userId/photos" element={<IPDonorPhotos />} />
                        <Route path="donors/:userId" element={<DonorProfile />} />
                        <Route path="favorites" element={<Favorites tab="favorites" />} />
                        <Route path="filters" element={<Searches tab="searches" />} />
                        <Route path="donors" element={<DonorSearch />} />
                        <Route path="documents" element={<IPDocuments />} />
                        <Route path="settings" element={<IPSettings />} />
                        <Route path="faq" element={<AuthenticationGuard component={FamilyFAQ} />} />

                        <Route index element={<Navigate to="donors" replace />} />
                    </Route>

                    <Route path="keep" element={<AuthenticationGuard component={KeepDashboard} />}>
                        <Route path="checklist" element={<AuthenticationGuard component={KeepChecklist} />} />
                        <Route path="settings" element={<KeepSettings />} />
                        <Route path="faq" element={<AuthenticationGuard component={FreezeAppSubmittedFAQ} />} />

                        <Route index element={<Navigate to="checklist" replace />} />
                    </Route>

                    <Route path="staff/login" element={<StaffLoginPage />} />

                    <Route path="staff/split-members/:userId/ip-pdf" element={<StaffAuthenticationGuard component={PDFProfile} options={{admin: false}} />} />
                    <Route path="staff/split-members/:userId/admin-pdf" element={<StaffAuthenticationGuard component={PDFProfile} options={{admin: true}} />} />

                    <Route path="staff" element={<StaffAuthenticationGuard component={Staff} />} >
                        <Route path="split-members/:userId/public-profile/photos" element={<PhotoViewer staff={true} />} />
                        <Route path="split-members/:userId/public-profile" element={<PublicProfile staff={true} />} />

                        <Route path="split-members/:userId" element={<SplitMemberPage />} >
                            <Route path="overview" element={<StaffSMOverview />} />
                            <Route path="profile" element={<StaffSMProfile />} />
                            <Route path="activity" element={<StaffSMActivity />} >
                                <Route path="views" element={<StaffSMViews />} />
                                <Route path="favorites" element={<StaffSMFavorites />} />

                                <Route index element={<Navigate to="favorites" replace />} />
                            </Route>
                            <Route path="documents" element={<Documents />} />
                            <Route path="notes" element={<Notes />} />
                            <Route path="photos" element={<StaffSMPhotos />} />
                            <Route path="logs" element={<AuditLogs />} />
                            <Route path="checklist" element={<SMAdminChecklist />} />

                            <Route index element={<Navigate to="overview" replace />} />
                        </Route>

                        <Route path="split-members" element={<SplitMembersPage />} >
                            <Route path="edit-requests" element={<EditRequests />} />
                            <Route path="photo-requests" element={<PhotoRequests />} />
                            <Route index element={<SplitMembers />} />
                        </Route>

                        <Route path="intended-parents/:userId" element={<IntendedParentPage />} >
                            <Route path="overview" element={<StaffIPOverview />} />
                            <Route path="activity" element={<StaffIPActivity />} >
                                <Route path="viewed" element={<StaffIPViews />} />
                                <Route path="favorites" element={<StaffIPFavorites />} />
                                <Route path="searches" element={<StaffIPSearches />} />

                                <Route index element={<Navigate to="favorites" replace />} />
                            </Route>
                            <Route path="documents" element={<Documents />} />
                            <Route path="notes" element={<Notes />} />
                            <Route path="logs" element={<AuditLogs />} />

                            <Route index element={<Navigate to="overview" replace />} />
                        </Route>

                        <Route path="intended-parents" element={<IntendedParentsPage />} >
                            <Route index element={<IntendedParents />} />
                        </Route>

                        <Route path="matches/:matchId" element={<MatchPage />} />

                        <Route path="matches" element={<MatchesPage />} >
                            <Route index element={<Matches />} />
                        </Route>

                        <Route index element={<Navigate to="split-members" replace />} />
                    </Route>
                    
                    {/* 
                    <Route exact path="/staff/clinics" element={<StaffAuthenticationGuard component={Staff} options={{ page: "clinics" }} />} />
                    <Route exact path="/staff/clinics/:clinicId" element={<StaffAuthenticationGuard component={Staff} options={{ page: "clinic", tab: "staff" }} />} />
                    <Route exact path="/staff/clinics/:clinicId/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "clinic", tab: "staff" }} />} />
                    <Route exact path="/staff/clinics/:clinicId/staff" element={<StaffAuthenticationGuard component={Staff} options={{ page: "clinic", tab: "staff" }} />} />
                    <Route exact path="/staff/clinics/:clinicId/staff/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "clinic", tab: "staff" }} />} />
                    <Route exact path="/staff/clinics/:clinicId/intended-parents" element={<StaffAuthenticationGuard component={Staff} options={{ page: "clinic", tab: "intended-parents" }} />} />
                    <Route exact path="/staff/clinics/:clinicId/intended-parents/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "clinic", tab: "intended-parents" }} />} />
                    <Route exact path="/staff/clinics/:clinicId/matches" element={<StaffAuthenticationGuard component={Staff} options={{ page: "clinic", tab: "matches" }} />} />
                    <Route exact path="/staff/clinics/:clinicId/matches/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "clinic", tab: "matches" }} />} />

                    <Route exact path="/staff/account" element={<StaffAuthenticationGuard component={Staff} options={{ page: "settings" }} />} />
                    <Route exact path="/staff/account/" element={<StaffAuthenticationGuard component={Staff} options={{ page: "settings" }} />} /> */}

                    <Route path="/account/login" element={<LandingPage />} />
                    <Route path="/" element={<LandingPage />} />
                    <Route path="*" element={PageNotFoundCatchAll()} />
                </Route>
            </Routes>
        </ErrorBoundary>
    );
};

export default AppRouter;
