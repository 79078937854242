import React, { useContext } from "react";
import { StaffIntendedParentPageContext } from "./IntendedParentPage";
import { Link, Outlet } from "react-router-dom";

const Activity = () => {
    const { user } = useContext(StaffIntendedParentPageContext);

    const determineSubtab = () => {
        if (window.location.pathname.includes("viewed")) {
            return "viewed";
        } else if (window.location.pathname.includes("searches")) {
            return "searches";
        }

        return "favorites";
    };

    return (
        <div className="pb-5">
            <h3 className="mx-30">I'm looking for...</h3>
            <div className="p-2 rounded-lg border border-solid mx-30">{user.intended_parent.family.donor_preferences ? user.intended_parent.family.donor_preferences : null}</div>
            <div className={`bg-white w-full pb-5`}>
                <div className="ml-30 flex flex-row w-1/2 pt-5">
                    <Link
                        to={`/staff/intended-parents/${user.id}/activity/favorites`}
                        className={`${
                            determineSubtab() === "favorites" ? "border-secondary" : "border-transparent"
                        } text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                    >
                        FAVORITES
                    </Link>
                    <Link
                        to={`/staff/intended-parents/${user.id}/activity/viewed`}
                        className={`${
                            determineSubtab() === "viewed" ? "border-secondary" : "border-transparent"
                        } text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                    >
                        VIEWED
                    </Link>
                    <Link
                        to={`/staff/intended-parents/${user.id}/activity/searches`}
                        className={`${
                            determineSubtab() === "searches" ? "border-secondary" : "border-transparent"
                        } text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                    >
                        FILTERS
                    </Link>
                </div>
            </div>

            <div>
                <Outlet />
            </div>
        </div>
    );
};

export default Activity;
