import { useEffect, useState, useRef, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { AppContext } from "../../App";

const KeepErrorBoundary = ({ message }) => {
    const [profileMenu, setProfileMenu] = useState(false);
    const [mobileMenu, setMobileMenu] = useState(false);
    const [userRetrieved, setUserRetrieved] = useState(false);
    const { logout } = useAuth0();
    const menuRef = useRef(null);
    const { loggedInUser, refreshUser } = useContext(AppContext);

    useEffect(() => {
        const refresh = async () => {
            await refreshUser();
            setUserRetrieved(true);
        };

        if (loggedInUser === null) {
            refresh();
        } else {
            setUserRetrieved(true);
        }
    }, [loggedInUser, refreshUser, setUserRetrieved]);

    useEffect(() => {
        document.title = "Cofertility";
        const handleOutsideClick = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setProfileMenu(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top left corner
    }, []);

    const handleLogout = () => {
        logout({
            logoutParams: {
                returnTo: window.location.origin + "/freeze/login",
            },
        });
    };

    const toggleProfileMenu = () => {
        setProfileMenu(!profileMenu);
    };

    const toggleMobileMenu = () => {
        setMobileMenu(!mobileMenu);
    };

    const updatePathAndNavigate = (newPath) => {
        // Get the current URL object
        const currentUrl = new URL(window.location.href);

        // Update the pathname of the URL
        currentUrl.pathname = newPath;

        // Navigate to the updated URL in the same tab
        window.location.href = currentUrl.href;
    };

    const navigateToDashboard = () => {
        updatePathAndNavigate("/keep/checklist");
    };

    const navigateToLearn = () => {
        window.open("https://www.cofertility.com/freeze/learn", "_blank");
    };

    const navigateToFaq = () => {
        updatePathAndNavigate("/keep/faq");
    };

    const navigateToSettings = () => {
        updatePathAndNavigate("/keep/settings");
    };

    let footerLogo = <img alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/logo_white.svg`} />;

    if (!userRetrieved) {
        return null;
    }

    return (
        <div
            id="inner-body"
            className="min-h-screen flex flex-col bg-freeze-dashboard-mobile sm:bg-freeze-dashboard 2xl:bg-freeze-dashboard-xl h-full w-full bg-white-200 bg-cover bg-fixed bg-center sm:bg-top "
        >
            <header className="h-20 z-20">
                <nav className="bg-primary fixed w-full z-20">
                    <div className="">
                        <div className="flex justify-between md:grid grid-cols-3 items-center h-20">
                            <div className="flex items-center ml-5">
                                <div className="text-secondary">
                                    <img className="w-[165px] h-[32px] md:w-[180px] md:h-[42px]" height="31" width="250" alt="Cofertility logo" src={`${process.env.PUBLIC_URL}/images/logo_forest.svg`} /> 
                                </div>
                            </div>

                            <div className="hidden md:flex md:justify-center items-center navigation gap-x-5">
                                <div onClick={navigateToDashboard} className={`cursor-pointer`}>
                                    Home
                                </div>
                                <div onClick={navigateToLearn} className={`cursor-pointer`}>
                                    Learn
                                </div>
                            </div>

                            <div className="-ml-2 mr-2 flex justify-end md:hidden">
                                <button
                                    onClick={toggleMobileMenu}
                                    type="button"
                                    className="inline-flex items-center justify-center p-4 rounded-md text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                                    aria-controls="mobile-menu"
                                    aria-expanded={mobileMenu}
                                >
                                    <span className="sr-only">Open main menu</span>
                                    <div className="w-6 flex items-center justify-center relative">
                                        <span className={`${mobileMenu ? "translate-y-0 rotate-45" : "-translate-y-2"} transform transition w-full h-px bg-current absolute`}></span>
                                        <span className={`${mobileMenu ? "opacity-0 translate-x-3" : "opacity-100"} transform transition w-full h-px bg-current absolute`}></span>
                                        <span className={`${mobileMenu ? "translate-y-0 -rotate-45" : "translate-y-2"} transform transition w-full h-px bg-current absolute`}></span>
                                    </div>
                                </button>
                            </div>

                            <div ref={menuRef} className="hidden md:flex justify-end md:items-center mr-2">
                                <div className="relative max-h-10.5">
                                    <button
                                        onClick={toggleProfileMenu}
                                        type="button"
                                        className="header-menu-button"
                                        aria-haspopup="true"
                                        aria-labelledby="alpine-menu-label-1"
                                        id="alpine-menu-button-1"
                                        aria-expanded={profileMenu}
                                    >
                                        <span className="sr-only">Open user menu</span>
                                        <img
                                            className="h-10.5 w-10.5 rounded-full"
                                            src="https://images.unsplash.com/photo-1601814933824-fd0b574dd592?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1112&q=80"
                                            alt="Profile"
                                        />
                                    </button>

                                    <div
                                        className="origin-top-right absolute right-0 min-w-min p-6 bg-white focus:outline-none shadow-hard border border-black border-solid rounded-xl overflow-hidden"
                                        aria-orientation="vertical"
                                        role="menu"
                                        id="alpine-menu-items-1"
                                        aria-labelledby="alpine-menu-button-1"
                                        tabIndex="0"
                                        style={profileMenu ? {} : { display: "none" }}
                                    >
                                        <div role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabIndex="-1">
                                            <div
                                                onClick={navigateToSettings}
                                                className="cursor-pointer block mb-4 w-40 text-base font-normal uppercase tracking-widest"
                                                role="menuitem"
                                                tabIndex="-1"
                                                id="user-menu-item-0"
                                            >
                                                Settings
                                            </div>
                                            <div onClick={handleLogout} className="cursor-pointer block w-40 text-base font-normal uppercase tracking-widest" role="menuitem" tabIndex="-1">
                                                Logout
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="mobile-menu" className="mobile-menu" style={mobileMenu ? {} : { display: "none" }}>
                        <div className="p-6 pb-0 flex flex-col top-menu"></div>

                        <div className="mt-8 mx-6 pt-8 pb-3 border-t border-black bottom-menu">
                            <div className="space-y-4">
                                <div onClick={handleLogout} className="block mb-4 text-base font-light" role="menuitem" tabIndex="-1">
                                    Logout
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>

            <div className="w-full h-screen flex items-center justify-center">{message}</div>

            <footer className="bottom-0 left-0 w-full flex justify-between bg-black text-white sm:py-4 sm:px-10 px-6 py-5 font-normal">
                {footerLogo}
                <div>
                    <span className="hidden sm:inline">We're here to help. Email us at </span>
                    <a className="underline" href="mailto:support@cofertility.com">
                        support@cofertility.com
                    </a>
                </div>
            </footer>
        </div>
    );
};

export default KeepErrorBoundary;
