import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../App";

const WelcomeFlow = () => {
    document.title = "Cofertility | Welcome";
    const [userRetrieved, setUserRetrieved] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);

    const totalSlides = 3; // Total number of slides in the welcome flow
    const navigate = useNavigate();
    const { loggedInUser, refreshUser, request } = useContext(AppContext);

    useEffect(() => {
        const refresh = async () => {
            await refreshUser();
            setUserRetrieved(true);
        };

        if (loggedInUser === null) {
            refresh();
        } else if (loggedInUser.freeze_member.status === "disqualified") {
            navigate("/keep/checklist");
        } else {
            setUserRetrieved(true);
        }
    }, [loggedInUser, refreshUser, setUserRetrieved, navigate]);

    useEffect(() => {
        if (loggedInUser && loggedInUser.isWelcomed) {
            navigate("/freeze/application");
        }
    }, [navigate, loggedInUser]);

    const goToNextSlide = () => {
        window.scrollTo(0, 0);
        setCurrentSlide(currentSlide + 1);
    };

    const goToPreviousSlide = () => {
        window.scrollTo(0, 0);
        setCurrentSlide(currentSlide - 1);
    };

    const completeWelcomeFlow = async () => {
        // Logic to mark the welcome flow as completed
        try {
            await request({
                url: `/split-members/${loggedInUser.id}/welcome-flow`,
                method: "PUT",
            });
            navigate("/freeze/application");
        } catch (err) {
            // TODO: handle this error
            console.error(err);
        }
    };

    const getBackgroundClass = (index) => {
        if (index === 0) {
            return "bg-freeze-welcome";
        } else if (index === 1) {
            return "bg-freeze-guide";
        }
    };

    let logo = <img className="pb-2" height="127" width="165" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/freeze/logo.svg`} />;

    if (!userRetrieved) {
        return null;
    }

    return (
        <div id="inner-body" className={`min-h-screen flex flex-col bg-white-200 bg-cover bg-fixed bg-top ${getBackgroundClass(currentSlide)}`}>
            <header className="sm:py-8 sm:px-10 px-6 py-5  flex items-center justify-between">{logo}</header>
            <div id="app" className="pb-10 freeze">
                <div className="px-6 grow flex flex-col">
                    <div id="welcome" className="container mx-auto my-auto">
                        {currentSlide === 0 && (
                            <section id="container" className="mb-28 md:mb-0 bg-white-200 py-20 md:py-14 px-7 md:px-15 mx-auto max-w-welcome rounded-xl shadow-hard border-black border-2 pb-10">
                                <h1 className="display mb-2 text-center">Welcome to Cofertility, {loggedInUser.firstName}!</h1>
                                <p className="text-lg text-center">
                                    We believe that everyone deserves access to affordable and empowering reproductive healthcare options, and that egg freezing is an essential option for anyone
                                    planning to have kids someday, but not today.
                                </p>
                                <div className="!min-w-fit sm:min-w-full absolute bottom-14.5 left-5 right-5 sm:relative sm:bottom-0 sm:left-0 sm:right-0">
                                    <div href="#" className="button-primary mt-8 cursor-pointer" onClick={goToNextSlide}>
                                        Get Started
                                    </div>
                                </div>
                            </section>
                        )}

                        {currentSlide === 1 && (
                            <article id="container" className="pb-20 mb-24">
                                <section className="max-w-container mx-auto mt-2">
                                    <h1 className="max-w-container mx-auto">
                                        <span className="heading-underline">How Split works</span>
                                    </h1>
                                    <p>
                                        Freezing and donating your eggs is a big decision. Learn more about how the process works so that you have the knowledge needed to make the best choice for you.
                                    </p>
                                    <br />
                                    <p>Hear directly from our Medical Advisor to learn what you can expect as far as next steps.</p>
                                    <div className="my-10 rounded-xl border-2 border-black overflow-hidden">
                                        <div style={{ padding: "56.31% 0 0 0", position: "relative" }}>
                                            <iframe
                                                src="https://player.vimeo.com/video/799497808?h=b5269679ed&badge=0&autopause=0&player_id=0&app_id=58479&pip=false"
                                                frameBorder="0"
                                                allow="autoplay; fullscreen;"
                                                allowFullScreen=""
                                                style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }}
                                                title="Split Application Intro Screener"
                                                referrerPolicy="strict-origin"
                                                data-ready="true"
                                            ></iframe>
                                        </div>
                                        <script src="https://player.vimeo.com/api/player.js"></script>
                                    </div>
                                </section>
                                <section className="max-w-container mx-auto pb-13">
                                    <h1 className="display">
                                        <span className="heading-underline">Why Split?</span>
                                    </h1>
                                    <div className="grid grid-cols-2 grid-rows-2 gap-x-16">
                                        <div>
                                            <span className="subtitle-md">100% free egg freezing</span>
                                            <p>Yes, really. Freeze your eggs and get 10 years of free storage, without paying a penny.</p>
                                        </div>
                                        <div>
                                            <span className="subtitle-md">Learn about your fertility</span>
                                            <p>With all your screenings covered, you’ll get valuable insights into your fertility and personal health.</p>
                                        </div>
                                        <div>
                                            <span className="subtitle-md">Help another family grow</span>
                                            <p>Support a family’s journey to parenthood while investing in your reproductive future at the same time.</p>
                                        </div>
                                        <div>
                                            <span className="subtitle-md">Dedicated support, every step of the way</span>
                                            <p>You’ll be paired with a Member Advocate who will be with you for the entire process alongside access to our members-only community.</p>
                                        </div>
                                    </div>
                                </section>
                                <section className="max-w-container mx-auto relative bg-secondary rounded-xl border-2 border-black mt-10">
                                    <img
                                        className="absolute -top-8 mx-auto left-0 right-0 rounded-full border border-black"
                                        src={`${process.env.PUBLIC_URL}/images/welcome_flow_testimonial.jpg`}
                                        height="74"
                                        width="74"
                                        alt="testimonial"
                                    />
                                    <h3 className="mx-24 my-16 text-center text-white-200">
                                        "I love that I’m able to freeze eggs for myself while also giving to others. Thinking about what this could lead to floods me with happiness."
                                    </h3>
                                    <div className="absolute mx-auto left-0 right-0 text-sm text-black w-fit border-2 h-9 flex items-center -bottom-3 bg-white-200 rounded-full px-3 uppercase font-bold border-black">
                                        Kristen, Split member
                                    </div>
                                </section>
                            </article>
                        )}

                        {currentSlide !== 0 && (
                            <footer className="z-1 transition-all duration-300 fixed flex items-center text-black bg-tertiary-light w-full left-0 bottom-0 h-20 px-6 sm:px-10 border-t-3 border-black gap-x-6 lg:gap-x-0">
                                <div className="mx-auto md:mx-0 md:ml-auto">
                                    <div onClick={completeWelcomeFlow} className="lg:h-auto lg:!w-auto button-primary !no-underline gap-3 cursor-pointer">
                                        <span className="whitespace-nowrap">Start application</span>
                                        <span className="h-7 w-7">
                                            <svg viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M29.0725 15.4322C24.7347 15.4322 21.208 11.9056 21.208 7.56777C21.208 7.25306 20.9549 7 20.6402 7C20.3255 7 20.0725 7.25306 20.0725 7.56777C20.0725 10.9452 21.9445 13.8944 24.7022 15.4322H4.06777C3.75306 15.4322 3.5 15.6853 3.5 16C3.5 16.3147 3.75306 16.5678 4.06777 16.5678H24.7055C21.9445 18.1056 20.0757 21.0548 20.0757 24.4322C20.0757 24.7469 20.3288 25 20.6435 25C20.9582 25 21.2112 24.7469 21.2112 24.4322C21.2112 20.0944 24.7379 16.5678 29.0757 16.5678C29.3904 16.5678 29.6435 16.3147 29.6435 16C29.6435 15.6853 29.3904 15.4322 29.0757 15.4322H29.0725Z"
                                                    fill="currentColor"
                                                ></path>
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                            </footer>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WelcomeFlow;
