import { useEffect, useState, useContext } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { AppContext } from "../../App";
import { IPDashboardContext } from "./Dashboard";
import { Link, useNavigate } from "react-router-dom";
import { SimpleModal } from "../modals/SimpleModal";
import { EthnicitiesMap, RaceMap, SchoolRankingMap } from "../../util/helpers";

const Searches = ({ tab = "searches" }) => {
    const [searches, setSearches] = useState([]);
    const [refetchSearches, setRefetchSearches] = useState(0);
    const [hasError, setHasError] = useState(null);
    const [editSearch, setEditSearch] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [newName, setNewName] = useState("");
    const navigate = useNavigate();

    const { loggedInUser, request } = useContext(AppContext);
    const { setCurrentPage } = useContext(IPDashboardContext);

    setCurrentPage("favorites");

    useEffect(() => {
        const fetchFavorites = async () => {
            try {
                const response = await request({
                    url: `/family/donors/searches`,
                    method: "GET",
                });

                let { searches } = response;

                setSearches(searches);
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching searches:", error);
                setHasError(error);
            }
        };

        fetchFavorites();
    }, [request, refetchSearches, loggedInUser]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    const handleString = (s) => {
        if (!s) {
            return "";
        }

        if (s === "yes_both_sides") {
            return "Either mother's or father's side";
        }

        if (s.includes("_")) {
            return s
                .split("_")
                .map((word, i) => {
                    if (i === 0) {
                        return word[0].toUpperCase() + word.substring(1);
                    }

                    return word;
                })
                .join(" ");
        } else if (s.length > 1) {
            return s[0].toUpperCase() + s.substring(1);
        }

        return s;
    };

    const deleteSearch = async (searchID) => {
        try {
            await request({
                url: `/family/donors/searches/${searchID}`,
                method: "DELETE",
            });

            closeDeleteModal();
            setRefetchSearches((prev) => prev + 1);
        } catch (err) {
            console.log(err);
        }
    };

    const renameSearch = async (id, name) => {
        try {
            await request({
                url: `/family/donors/searches`,
                method: "PUT",
                data: {
                    id,
                    name,
                },
            });

            closeEditModal();
            setRefetchSearches((prev) => prev + 1);
        } catch (err) {
            console.log(err);
        }
    };

    const closeEditModal = () => {
        setEditSearch(null);
        setNewName("");
        setEditModal(false);
    };

    const closeDeleteModal = () => {
        setEditSearch(null);
        setDeleteModal(false);
    };

    const handleInputChange = (event, setInputState) => {
        const input = event.target.value;

        setInputState(input);
    };

    const displayCritiera = (criteria) => {
        let formatted = [];

        if (criteria.race) {
            let title = "Race";
            let text = "";
            criteria.race.forEach((r, i) => {
                if (i === 0) {
                    text += `${RaceMap[r].replace(" or ", "/")}`;
                } else {
                    text += `, ${RaceMap[r].replace(" or ", "/")}`;
                }
            });
            formatted.push(
                <div className="mb-2">
                    <div className="font-medium mr-1">{title}</div>
                    <div>{text}</div>
                </div>
            );
        }

        if (criteria.ethnicities) {
            let title = "Heritage";
            let text = "";
            criteria.ethnicities.forEach((r, i) => {
                if (i === 0) {
                    text += `${EthnicitiesMap[r]}`;
                } else {
                    text += `, ${EthnicitiesMap[r]}`;
                }
            });
            formatted.push(
                <div className="mb-2">
                    <div className="font-medium mr-1">{title}</div>
                    <div>{text}</div>
                </div>
            );
        }

        if (criteria.hair_color) {
            let title = "Hair color";
            let text = "";
            criteria.hair_color.forEach((r, i) => {
                if (i === 0) {
                    text += `${handleString(r)}`;
                } else {
                    text += `, ${handleString(r)}`;
                }
            });
            formatted.push(
                <div className="mb-2">
                    <div className="font-medium mr-1">{title}</div>
                    <div>{text}</div>
                </div>
            );
        }

        if (criteria.hair_type) {
            let title = "Hair type";
            let text = "";
            criteria.hair_type.forEach((r, i) => {
                if (i === 0) {
                    text += `${handleString(r)}`;
                } else {
                    text += `, ${handleString(r)}`;
                }
            });
            formatted.push(
                <div className="mb-2">
                    <div className="font-medium mr-1">{title}</div>
                    <div>{text}</div>
                </div>
            );
        }

        if (criteria.eye_color) {
            let title = "Eye color";
            let text = "";
            criteria.eye_color.forEach((r, i) => {
                if (i === 0) {
                    text += `${handleString(r)}`;
                } else {
                    text += `, ${handleString(r)}`;
                }
            });
            formatted.push(
                <div className="mb-2">
                    <div className="font-medium mr-1">{title}</div>
                    <div>{text}</div>
                </div>
            );
        }

        if (criteria.education_level) {
            let title = "Education";
            let text = "";
            criteria.education_level.forEach((r, i) => {
                if (i === 0) {
                    text += `${handleString(r)}`;
                } else {
                    text += `, ${handleString(r)}`;
                }
            });
            formatted.push(
                <div className="mb-2">
                    <div className="font-medium mr-1">{title}</div>
                    <div>{text}</div>
                </div>
            );
        }

        if (criteria.school_ranking) {
            let title = "School ranking";
            let text = "";
            criteria.school_ranking.forEach((r, i) => {
                if (i === 0) {
                    text += `${SchoolRankingMap[r]}`;
                } else {
                    text += `, ${SchoolRankingMap[r]}`;
                }
            });
            formatted.push(
                <div className="mb-2">
                    <div className="font-medium mr-1">{title}</div>
                    <div>{text}</div>
                </div>
            );
        }

        if (criteria.jewish_ancestry) {
            let title = `Jewish ancestry: `;
            let text = `${handleString(criteria.jewish_ancestry)}`;
            formatted.push(
                <div className="mb-2">
                    <div className="font-medium mr-1">{title}</div>
                    <div>{text}</div>
                </div>
            );
        }

        if (criteria.relationship_preferences) {
            let title = "Disclosure";
            let text = "";
            criteria.relationship_preferences.forEach((r, i) => {
                if (i === 0) {
                    text += `${handleString(r)}`;
                } else {
                    text += `, ${handleString(r)}`;
                }
            });
            formatted.push(
                <div className="mb-2">
                    <div className="font-medium mr-1">{title}</div>
                    <div>{text}</div>
                </div>
            );
        }

        if (criteria.aptitude) {
            let title = "Aptitudes";
            let text = "";
            criteria.aptitude.forEach((r, i) => {
                if (i === 0) {
                    text += `${handleString(r)}`;
                } else {
                    text += `, ${handleString(r)}`;
                }
            });
            formatted.push(
                <div className="mb-2">
                    <div className="font-medium mr-1">{title}</div>
                    <div>{text}</div>
                </div>
            );
        }

        if (criteria.personality) {
            let title = "Personality";
            let text = "";
            criteria.personality.forEach((r, i) => {
                if (i === 0) {
                    text += `${handleString(r)}`;
                } else {
                    text += `, ${handleString(r)}`;
                }
            });
            formatted.push(
                <div className="mb-2">
                    <div className="font-medium mr-1">{title}</div>
                    <div>{text}</div>
                </div>
            );
        }

        if (criteria.height) {
            let title = "Height";
            let text = "";
            let min = criteria.height.filter((x) => x.name === "height_min");
            let max = criteria.height.filter((x) => x.name === "height_max");

            if (min.length && !max.length) {
                text += `${min[0].value}" <`;
            } else if (!min.length && max.length) {
                text += `< ${max[0].value}"`;
            } else if (min.length && max.length) {
                text += `${min[0].value}" - ${max[0].value}"`;
            } else {
                text += "?";
            }
            formatted.push(
                <div className="mb-2">
                    <div className="font-medium mr-1">{title}</div>
                    <div>{text}</div>
                </div>
            );
        }

        return <div>{formatted}</div>;
    };

    return (
        <div id="inner-body" className="min-h-screen flex flex-col h-full w-full bg-white-200 bg-cover bg-fixed bg-center sm:bg-top ">
            {editModal ? (
                <SimpleModal
                    title="Rename filter"
                    inputs={[{ title: "Name", value: newName, setInputState: setNewName, handleChange: handleInputChange, placeholder: "Name your filter" }]}
                    close={closeEditModal}
                    save={() => renameSearch(editSearch.id, newName)}
                />
            ) : null}

            {deleteModal ? (
                <SimpleModal title={`Are you sure you want to delete '${editSearch.name}' filter?`} close={closeDeleteModal} save={() => deleteSearch(editSearch.id)} saveText="Delete" />
            ) : null}
            <div id="app" className="pb-10 pt-5">
                <div className={`bg-white-200 divide-y divide-solid divide-white-400 w-4/5 mx-auto pb-5`}>
                    <div className="flex flex-row w-full pt-5 justify-center pb-4">
                        <div
                            onClick={() => navigate(`/family/favorites`)}
                            className={`${tab === "favorites" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                        >
                            FAVORITE DONORS
                        </div>
                        <div
                            onClick={() => navigate(`/family/filters`)}
                            className={`${tab === "searches" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                        >
                            FAVORITE FILTERS
                        </div>
                    </div>

                    <div></div>
                </div>
                <div className="w-full flex justify-center">
                    <h1 className="text-left w-1/2">Favorite filters ({searches.length})</h1>
                </div>
                <div className="w-full flex flex-col items-center justify-center">
                    {searches.length ? (
                        searches.map((search) => {
                            return (
                                <div className={`px-6 py-9 w-11/12 md:w-1/2 bg-white mb-10 rounded-lg overflow-hidden`}>
                                    <div className="flex justify-between">
                                        <h3 className="">{search.name}</h3>
                                        <div>
                                            <div className="flex items-center">
                                                <button
                                                    className="bg-white text-secondary font-normal border-2 border-secondary rounded-full px-2 w-29 h-10 hover:bg-green-100 focus:outline-none focus:ring focus:ring-green-200 mr-4"
                                                    onClick={() => {
                                                        setNewName(search.name);
                                                        setEditModal(true);
                                                        setEditSearch(search);
                                                    }}
                                                >
                                                    Edit name
                                                </button>
                                                <svg
                                                    className="cursor-pointer"
                                                    onClick={() => {
                                                        setEditSearch(search);
                                                        setDeleteModal(true);
                                                    }}
                                                    width="28"
                                                    height="28"
                                                    viewBox="0 0 16 16"
                                                    fill="currentColor"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="whitespace-pre-line mt-3">{displayCritiera(search.criteria)}</div>
                                </div>
                            );
                        })
                    ) : (
                        <div className="min-h-svh flex mt-[10%] justify-center p-10">
                            <div className="hidden md:block">
                                <div className="text-center">
                                    No saved filters yet.{" "}
                                    <Link to={"/family/donors"} className="underline">
                                        Find donors
                                    </Link>{" "}
                                    and use filters to reflect your match preferences,
                                </div>
                                <div className="text-center">then hit “Save current filter.” This will allow you to come back to the search without inputting the filters again.</div>
                            </div>
                            <div className="md:hidden">
                                No saved filters yet.{" "}
                                <Link to={"/family/donors"} className="underline">
                                    Find donors
                                </Link>{" "}
                                and use filters to reflect your match preferences, then hit “Save current filter.” This will allow you to come back to the search without inputting the filters again.
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Searches;
