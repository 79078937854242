import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { redirectToFormsort } from "../../util/helpers";
import { AppContext } from "../../App";
import { SMDashboardContext } from "./Dashboard";
import { FreezeButton } from "../buttons/buttons";

const SMChecklist = () => {
    const [refetchChecklist, setRefetchChecklist] = useState(0);
    const [checklist, setChecklist] = useState(null);
    const [persForm, setPersForm] = useState(null);
    const [hasError, setHasError] = useState(null);
    const [activePhase, setActivePhase] = useState("");
    const [viewedPhase, setViewedPhase] = useState("");
    const [appSubmittedModal, setAppSubmittedModal] = useState(false);
    const [activeModal, setActiveModal] = useState(false);
    const navigate = useNavigate();
    const { loggedInUser, request } = useContext(AppContext);
    const { setCurrentPage } = useContext(SMDashboardContext);

    useEffect(() => {
        setCurrentPage("dashboard");
    });

    useEffect(() => {
        document.title = "Cofertility | Dashboard";
        const fetchChecklist = async () => {
            try {
                let response = await request({
                    url: `/checklist?program=split`,
                    method: "GET",
                });

                const { checklist } = response;

                setChecklist(checklist);

                outerloop: for (let i = 0; i < checklist.checklist_phases.length; i++) {
                    const phase = checklist.checklist_phases[i];
                    for (let j = 0; j < phase.checklist_tasks.length; j++) {
                        const task = phase.checklist_tasks[j];
                        for (let k = 0; k < task.subtasks.length; k++) {
                            if (task.subtasks[k].user_subtask.status === "active") {
                                setActivePhase(phase.name);
                                setViewedPhase(phase.name);
                                break outerloop;
                            }
                        }
                    }
                }
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching checklist:", error);
                setHasError(error);
            }
        };

        const fetchForm = async () => {
            try {
                const response = await request({
                    url: `/split-members/${loggedInUser.id}/form/freeze-personality-motivations`,
                    method: "GET",
                });

                const { form } = response;
                setPersForm(form);
            } catch (err) {
                console.log(err);
                setHasError(err);
            }
        };

        fetchChecklist();
        fetchForm();
    }, [request, loggedInUser, refetchChecklist]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top left corner

        if (loggedInUser.freeze_member.status === "active" && localStorage.getItem("activeModalSeen") !== "true") {
            setActiveModal(true);
        }
    }, [loggedInUser]);

    const toggleViewedPhase = (phaseName) => {
        if (viewedPhase === phaseName) {
            setViewedPhase("");
        } else {
            setViewedPhase(phaseName);
        }
    };

    const navigateToPhotos = () => {
        navigate("/freeze/profile/photos/upload");
    };

    const closeAppSubmittedModal = () => {
        setAppSubmittedModal(false);
    };

    const closeActiveModal = () => {
        setActiveModal(false);
        localStorage.setItem("activeModalSeen", true);
    };

    const ctaNavigate = async (cta) => {
        if (cta.includes("calendly")) {
            if (loggedInUser.freeze_member.status === "application_approved") {
                window.open(`${cta}?utm_content=${loggedInUser.id}`, "_blank");
            } else {
                // app not approved, has a 4 day buffer
                window.open(`https://calendly.com/d/gqk-ry3-qzt/split-program-zoom-call?utm_content=${loggedInUser.id}`, "_blank");
            }
        } else if (cta.includes("forms.cofertility")) {
            // we only want to allow them to edit their formsort personality when they are going through through checklist or revisions. After that they must submit edit request
            if (
                ["application_submission", "application_subhold", "application_subhold_med_review", "application_approved", "pp_completed", "pp_revisions"].includes(loggedInUser.freeze_member.status)
            ) {
                redirectToFormsort({ form: persForm, user: loggedInUser, newTab: false });
            }
        } else if (cta === "/profile/photos/upload") {
            // photos is slightly more nuanced. We still want the initial period locked down after their initial submission while we review. But once they are set to active they can add
            // more pics whenever they want and they will go to photo review
            if (
                ["application_submission", "application_subhold", "application_subhold_med_review", "application_approved", "pp_completed", "pp_revisions", "active"].includes(
                    loggedInUser.freeze_member.status
                )
            ) {
                navigateToPhotos();
            }
        } else if (cta.includes("/profile/submit")) {
            try {
                const response = await request({
                    url: `/split-members/${loggedInUser.id}/profile/submit`,
                    method: "POST",
                });

                setRefetchChecklist((prev) => prev + 1);
                setAppSubmittedModal(true);
            } catch (err) {
                console.log(err);
            }
        } else if (cta.includes("https://")) {
            window.open(cta, "_blank");
        } else {
            navigate(cta);
        }
    };

    const determineDisplayedTask = (task) => {
        let data = {
            totalSubtasks: 0,
            reqSubtasks: 0,
            completeReq: 0,
            blockingTask: null,
            activeTask: null,
            inactiveTask: null,
            lastComplete: null,
        };

        let subtask = task.subtasks[0];
        let taskComplete = false;

        task.subtasks.forEach((sub) => {
            data.totalSubtasks++;
            if (sub.required) data.reqSubtasks++;
            if (sub.user_subtask.status === "blocker" && data.blockingTask === null) data.blockingTask = sub;
            if (sub.user_subtask.status === "active" && data.activeTask === null) data.activeTask = sub;
            if (sub.user_subtask.status === "finished") {
                data.completeReq++;
                data.lastComplete = sub;
            }
            if (sub.user_subtask.status === "inactive" && data.inactiveTask === null) data.inactiveTask = sub;
        });

        if (data.blockingTask !== null) {
            subtask = data.blockingTask;
        } else if (data.activeTask !== null) {
            subtask = data.activeTask;
        } else if (data.reqSubtasks !== 0 && data.reqSubtasks === data.completeReq) {
            taskComplete = true;
            subtask = data.lastComplete;
        } else {
            subtask = data.inactiveTask;
        }

        return (
            <div
                key={task.id}
                className={`rounded-lg bg-white border-2 border-black px-6 pt-8 pb-4 mb-4 ${subtask.user_subtask.status === "inactive" ? "opacity-50" : ""} ${taskComplete ? "bg-opacity-0" : ""}`}
            >
                <div className="md:flex flex-col gap-4">
                    <div>
                        <div className="flex">
                            {taskComplete ? (
                                <img className="max-w-none h-10" src={`${process.env.PUBLIC_URL}/images/task_check.svg`} alt="Complete your application" width="40" height="40" />
                            ) : (
                                <img className="max-w-none h-10" src={`${process.env.PUBLIC_URL}/images/${task.icon}.svg`} alt="Complete your application" width="40" height="40" />
                            )}
                            <div className="ml-3">
                                <h3 className="">{task.name}</h3>
                                <p className="mb-4">{subtask.description}</p>
                            </div>
                        </div>
                        <div className="md:flex items-center sm:ml-[50px]">
                            {!taskComplete ? (
                                subtask.badge_override ? (
                                    <div className="bg-tertiary-light border border-black rounded px-3 text-center mb-2">
                                        <span className="uppercase text-xs leading-6 w-full text-nowrap">{subtask.badge_override}</span>
                                    </div>
                                ) : null
                            ) : null}

                            <div className="md:flex justify-end items-center w-full">
                                {!taskComplete ? (
                                    subtask.cta_button ? (
                                        <FreezeButton
                                            onClick={() => {
                                                if (subtask.user_subtask.status === "active" || subtask.user_subtask.status === "blocker") ctaNavigate(subtask.cta_button);
                                            }}
                                            label={subtask.cta_text}
                                            arrow={true}
                                            active={subtask.user_subtask.status === "active" || subtask.user_subtask.status === "blocker"}
                                        />
                                    ) : subtask.cta_text ? (
                                        <div className="italic p-4">{subtask.cta_text}</div>
                                    ) : null
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
                <span className="block md:hidden italic font-normal mt-6"></span>
            </div>
        );
    };

    return (
        <div
            id="inner-body"
            className="min-h-screen flex flex-col bg-freeze-dashboard-mobile sm:bg-freeze-dashboard 2xl:bg-freeze-dashboard-xl h-full w-full bg-white-200 text-[#191919] bg-cover bg-fixed bg-center sm:bg-top "
        >
            <div id="app" className="pb-10 freeze">
                <div className="relative w-full z-1 bg-freeze-primary mb-12 md:mb-22">
                    <header className="bg-freeze-primary px-6 sm:px-10 z-0">
                        <div className="flex flex-col gap-1 max-w-container mx-auto mt-8 ">
                            <div className="bg-primary">
                                <div className="max-w-container mx-auto flex flex-col gap-4">
                                    <div className="flex flex-col items-center">
                                        <img src={`${process.env.PUBLIC_URL}/images/split_dashboard_icon.svg`} alt="Split dashboard" />
                                        <h1 className="mb-7 mt-2 text-4xxl">Welcome, {loggedInUser.preferredName ? loggedInUser.preferredName : loggedInUser.firstName}!</h1>
                                        <div className="flex flex-wrap md:flex-nowrap justify-center">
                                            {checklist &&
                                                checklist.checklist_phases.map((phase, index) => (
                                                    <span key={phase.id} className={`pizza-tracker-item ${activePhase === phase.name ? "active" : ""}`}>
                                                        <span className={`pizza-tracker-order ${activePhase === phase.name ? "active" : ""}`}>{index + 1}</span>
                                                        <span className="pizza-tracker-title">{phase.name}</span>
                                                        <span className="text-xs font-light">{phase.timeline}</span>
                                                    </span>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="absolute left-0 w-full -z-1">
                            <svg viewBox="0 0 1440 49" fill="none" className="fill-freeze-primary w-full" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M2131 -301.5C2131 -107.924 1498.83 49.0004 719.004 49.0004C-60.8219 49.0004 -692.996 -107.924 -692.996 -301.5C-692.996 -318.767 -693 -351 -693 -351C-693 -351 -33.619 -351 719.004 -351C1446.7 -351 2131 -351 2131 -351C2131 -351 2131 -319.113 2131 -301.5Z"
                                    fill="#D2FC99"
                                ></path>
                            </svg>
                        </div>
                    </header>
                </div>

                {appSubmittedModal ? (
                    <div
                        className="modal"
                        style={{
                            position: "fixed",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 10,
                        }}
                    >
                        <div
                            className="modal-content w-full max-w-3xl"
                            style={{
                                backgroundColor: "white",
                                padding: "40px",
                                borderRadius: "5px",
                                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.3)",
                            }}
                        >
                            <div className="flex justify-end">
                                <span onClick={closeAppSubmittedModal} className="cursor-pointer">
                                    X
                                </span>
                            </div>
                            <h1 className="mb-4 flex flex-col">
                                <div className="mx-auto">Thanks!</div>
                                <div className="mx-auto">Your profile is under review.</div>
                            </h1>
                            <div className="mx-auto p-4 w-3/4 mb-5">
                                This can take up to 5 business days. Your member advocate will be reaching out soon for next steps. Please keep an eye on your email as we may have follow-up questions
                                on the information you’ve provided, and your response is required to keep the review process on track.
                            </div>
                            <div
                                onClick={closeAppSubmittedModal}
                                className="flex justify-center mx-auto bg-primary hover:bg-secondary border-2 border-secondary hover:border-primary rounded-full py-2 w-1/2 mt-2 cursor-pointer group"
                            >
                                <button className="text-secondary group-hover:text-primary font-medium px-2">Got it!</button>
                                <svg className="max-w-none h-8" width="30" height="30" viewBox="0 0 32 32" fill="" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M28.5725 15.4322C24.2347 15.4322 20.708 11.9056 20.708 7.56777C20.708 7.25306 20.4549 7 20.1402 7C19.8255 7 19.5725 7.25306 19.5725 7.56777C19.5725 10.9452 21.4445 13.8944 24.2022 15.4322H3.56777C3.25306 15.4322 3 15.6853 3 16C3 16.3147 3.25306 16.5678 3.56777 16.5678H24.2055C21.4445 18.1056 19.5757 21.0548 19.5757 24.4322C19.5757 24.7469 19.8288 25 20.1435 25C20.4582 25 20.7112 24.7469 20.7112 24.4322C20.7112 20.0944 24.2379 16.5678 28.5757 16.5678C28.8904 16.5678 29.1435 16.3147 29.1435 16C29.1435 15.6853 28.8904 15.4322 28.5757 15.4322H28.5725Z"
                                        className="fill-secondary group-hover:fill-primary"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                ) : null}

                {activeModal ? (
                    <div
                        className="modal"
                        style={{
                            position: "fixed",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 10,
                        }}
                    >
                        <div
                            className="modal-content w-full max-w-3xl"
                            style={{
                                backgroundColor: "white",
                                padding: "40px",
                                borderRadius: "5px",
                                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.3)",
                            }}
                        >
                            <h1 className="mb-4 flex flex-col">
                                <div className="mx-auto">Congrats!</div>
                            </h1>
                            <div className="mx-auto p-4 w-3/4 mb-5">
                                Your profile is live! Once we have a family interested in matching, we'll reach out to you to confirm your availability for next steps.
                            </div>
                            <div className="flex justify-center sm:mx-auto w-full sm:w-1/2">
                                <FreezeButton onClick={closeActiveModal} label={"Continue"} arrow={true} styles="!w-full" />
                            </div>
                        </div>
                    </div>
                ) : null}

                <section className="mx-6 md:mx-0 md:mb-24">
                    <div className="max-w-content mx-auto flex flex-col gap-4">
                        <div className="flex flex-col gap-4 relative">
                            {checklist &&
                                checklist.checklist_phases.map((phase, index) => {
                                    return (
                                        <div key={phase.id} className="border-b mt-4 !border-black pb-8 pt-4">
                                            <h2 className="mb-0 px-6 -mx-6 flex items-center">
                                                <span className={`pizza-tracker-order bg-transparent basis-10 font-body font-normal mr-4 ${phase.name === activePhase ? "active" : ""}`}>
                                                    {index + 1}
                                                </span>
                                                <div
                                                    onClick={() => toggleViewedPhase(phase.name)}
                                                    className="hover:text-secondary flex w-full items-center justify-between text-left !no-underline cursor-pointer flex-1 !font-normal"
                                                    aria-expanded={phase.name === viewedPhase}
                                                >
                                                    {phase.step_title}
                                                    <span className={`w-8 h-8 ml-4 transition-transform flex-shrink-0 ${phase.name === viewedPhase ? "-rotate-180" : ""}`}>
                                                        <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        </svg>
                                                    </span>
                                                </div>
                                            </h2>
                                            <div style={phase.name === viewedPhase ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                                <div className="mt-7 !mb-0">
                                                    <div className="mb-8"></div>
                                                    <div>{phase.checklist_tasks.map((task) => determineDisplayedTask(task))}</div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default SMChecklist;
