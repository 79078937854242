import { useEffect, useState, useRef, useContext, createContext } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { AppContext } from "../../App";

import TagManager from "@sooro-io/react-gtm-module";
import Cookies from "js-cookie";

export const IPDashboardContext = createContext(null);

const FamilyDashboard = ({ page }) => {
    const [profileMenu, setProfileMenu] = useState(false);
    const [matchesDropdown, setMatchesDropdown] = useState(false);
    const [mobileMenu, setMobileMenu] = useState(false);
    const [currentPage, setCurrentPage] = useState(page);
    const [userRetrieved, setUserRetrieved] = useState(false);
    const [contentLoaded, setContentLoaded] = useState(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const intakeComplete = queryParams.get("intake");

    const { logout } = useAuth0();
    const menuRef = useRef(null);
    const matchesRef = useRef(null);
    const navigate = useNavigate();
    const { loggedInUser, refreshUser } = useContext(AppContext);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
        });
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setContentLoaded(true);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const refresh = async () => {
            if (intakeComplete) {
                setTimeout(async () => {
                    await refreshUser();
                    setUserRetrieved(true);
                }, 1000);
            } else {
                await refreshUser();
                setUserRetrieved(true);
            }
        };

        if (loggedInUser === null) {
            refresh();
        } else if (!loggedInUser.appFinished) {
            navigate("/family/intake", { state: { userID: loggedInUser.id } });
        } else {
            setUserRetrieved(true);
        }
    }, [loggedInUser, refreshUser, setUserRetrieved, navigate, intakeComplete]);

    useEffect(() => {
        // Fire formSubmit if it hasn't already been fired
        const cookieName = "familySignup-fired";
        const cookieValue = Cookies.get(cookieName);
        if (loggedInUser && !cookieValue) {
            TagManager.dataLayer({
                dataLayer: {
                    event: "familySignup",
                    accountId: loggedInUser.id,
                },
            });
            Cookies.set(cookieName, "1");
        }
    }, [loggedInUser]);

    useEffect(() => {
        document.title = "Cofertility | Dashboard";

        const handleOutsideClick = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setProfileMenu(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    // useEffect(() => {
    //     const handleOutsideClick = (event) => {
    //         console.log(matchesRef.current);
    //         console.log(event.target);
    //         if (matchesRef.current && !matchesRef.current.contains(event.target)) {
    //             setMatchesDropdown(false);
    //         }
    //     };

    //     document.addEventListener("click", handleOutsideClick);

    //     return () => {
    //         document.removeEventListener("click", handleOutsideClick);
    //     };
    // }, []);

    const handleLogout = () => {
        logout({
            logoutParams: {
                returnTo: window.location.origin + "/family/login",
            },
        });
    };

    const toggleProfileMenu = () => {
        setProfileMenu(!profileMenu);
    };

    const toggleMatchesDropdown = () => {
        setMatchesDropdown(!matchesDropdown);
    };

    const toggleMobileMenu = () => {
        setMobileMenu(!mobileMenu);
    };

    const navigateToChecklist = () => {
        scrollToTop();
        navigate("/family/checklist");
    };

    const navigateToDonorSearch = () => {
        scrollToTop();
        setCurrentPage("donors");
        navigate("/family/donors");
    };

    const navigateToDocuments = () => {
        scrollToTop();
        setCurrentPage("documents");
        navigate("/family/documents");
    };

    const navigateToLearn = () => {
        window.open("https://www.cofertility.com/family/learn", "_blank");
    };

    const navigateToPricing = () => {
        window.open("https://www.cofertility.com/family/pricing", "_blank");
    };

    const navigateToFaq = () => {
        scrollToTop();
        navigate("/family/faq");
    };

    const navigateToSettings = () => {
        scrollToTop();
        setCurrentPage("settings");
        navigate("/family/settings");
    };

    const navigateToFavorites = () => {
        scrollToTop();
        setCurrentPage("favorites");
        navigate("/family/favorites");
    };

    const navigateToMyMatch = (id = null) => {
        if (!id) {
            id = loggedInUser.intended_parent.family.matches[0].freeze_member_id;
        }
        setCurrentPage("matches");
        scrollToTop();
        navigate(`/family/donors/${id}`);
    };

    let footerLogo = <img alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/logo_white.svg`} />;

    if (!userRetrieved) {
        return (
            <div className="absolute inset-0 flex justify-center items-center z-50">
                <img width={65} height={65} src={`${process.env.PUBLIC_URL}/images/loading_spinner.gif`} alt="Loading..." />
            </div>
        );
    }

    const multimatchDisplay = () => {
        if (!loggedInUser) return true;

        if (loggedInUser.intended_parent.family.status === "matched") {
            if (loggedInUser.intended_parent.family.multimatch) return true;
            return false;
        }

        return true;
    };

    const myMatchTab = () => {
        if (!loggedInUser) return null;

        if (loggedInUser.intended_parent.family.status === "matched" && loggedInUser.intended_parent.family.matches && loggedInUser.intended_parent.family.matches.length === 1) {
            return (
                <div onClick={() => navigateToMyMatch()} className={`${currentPage === "match" ? "active-w" : ""} !text-white hover:font-medium w-[120px] cursor-pointer text-nowrap`}>
                    My match
                </div>
            );
        } else if (loggedInUser.intended_parent.family.status === "matched" && loggedInUser.intended_parent.family.matches && loggedInUser.intended_parent.family.matches.length > 1) {
            return (
                <div ref={matchesRef}>
                    <div
                        onClick={toggleMatchesDropdown}
                        className={`${currentPage === "match" ? "active-w" : ""} !text-white hover:font-medium ${matchesDropdown ? "font-medium" : ""} w-[110px] cursor-pointer text-nowrap`}
                    >
                        My Matches
                    </div>
                    <div className="hidden md:flex z-20">
                        <div className="relative max-h-10.5">
                            <div
                                className="origin-top-right absolute mt-2 min-w-min bg-white focus:outline-none shadow-hard border border-black border-solid rounded-xl"
                                style={matchesDropdown ? {} : { display: "none" }}
                            >
                                <div role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabIndex="-1">
                                    {loggedInUser.intended_parent.family.matches.map((match, i) => (
                                        <div
                                            onClick={() => navigateToMyMatch(match.freeze_member.id)}
                                            className={`cursor-pointer block px-4 pb-2 ${i === 0 ? "pt-2" : ""} w-40 text-base font-normal uppercase tracking-widest hover:font-semibold`}
                                            role="menuitem"
                                            tabIndex="-1"
                                            id="user-menu-item-0"
                                        >
                                            Donor {match.freeze_member.donorCode}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    };

    return (
        <div id="inner-body" className={`min-h-screen flex flex-col h-full w-full bg-white-200 bg-cover bg-fixed bg-center sm:bg-top`}>
            <header className="h-16 sm:h-20">
                <nav className="fixed bg-secondary w-full z-20">
                    <div>
                        <div className="flex justify-between md:grid grid-cols-3 items-center h-16 sm:h-20">
                            <div className="flex items-center ml-5">
                                <div className="text-secondary">
                                    <img className="w-[123px] h-[24px] sm:w-[165px] sm:h-[32px] md:w-[180px] md:h-[35px]" height="31" width="250" alt="Cofertility logo" src={`${process.env.PUBLIC_URL}/images/logo_white.svg`} /> 
                                </div>
                            </div>

                            <div className="hidden md:flex md:space-x-4 lg:space-x-8 items-center navigation z-20">
                                {multimatchDisplay() ? (
                                    <div
                                        onClick={navigateToDonorSearch}
                                        className={`${
                                            currentPage === "donors" || currentPage === "profile" || currentPage === "profilePhotos" ? "active-w" : ""
                                        } !text-white hover:font-medium w-[120px] cursor-pointer text-nowrap`}
                                    >
                                        Find donors
                                    </div>
                                ) : null}
                                {myMatchTab()}
                                <div onClick={navigateToChecklist} className={`${currentPage === "checklist" ? "active-w" : ""} !text-white hover:font-medium w-[90px] cursor-pointer`}>
                                    Checklist
                                </div>
                                <div onClick={navigateToDocuments} className={`${currentPage === "documents" ? "active-w" : ""} !text-white hover:font-medium w-[110px] cursor-pointer`}>
                                    Documents
                                </div>
                                <div onClick={navigateToLearn} className={`!text-white hover:font-medium w-[57px] cursor-pointer`}>
                                    Learn
                                </div>
                                <div onClick={navigateToPricing} className={`!text-white hover:font-medium w-[72px] cursor-pointer`}>
                                    Pricing
                                </div>
                                {loggedInUser && loggedInUser.isStaff ? (
                                    <div onClick={() => navigate("/staff")} className={`${currentPage === "admin" ? "active-w" : ""} !text-white hover:font-medium w-[56px] cursor-pointer z-20`}>
                                        Admin
                                    </div>
                                ) : null}
                            </div>

                            <div className="hidden sm:flex md:hidden"></div>
                            <div className="flex items-center justify-end">
                                {multimatchDisplay() ? (
                                    <div onClick={navigateToFavorites} className="flex items-center lg:mr-7 font-normal text-white cursor-pointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#ED795E" class="bi bi-heart-fill mr-3" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314" />
                                        </svg>
                                        <span className="hidden lg:flex">FAVORITES</span>
                                    </div>
                                ) : null}
                                <div ref={menuRef} className="hidden md:flex">
                                    <div className="relative max-h-10.5">
                                        <button
                                            onClick={toggleProfileMenu}
                                            type="button"
                                            className="header-menu-button items-center !pb-0"
                                            aria-haspopup="true"
                                            aria-labelledby="alpine-menu-label-1"
                                            id="alpine-menu-button-1"
                                            aria-expanded={profileMenu}
                                        >
                                            <span className="sr-only">Open user menu</span>
                                            <span className={`hidden sm:flex`}>
                                                <span className={`w-10 h-10 rounded-full px-2 py-2 text-black text-sm mr-2`} style={{ backgroundColor: "#D2E9E6" }}>
                                                    {loggedInUser.firstName ? loggedInUser.firstName.charAt(0).toUpperCase() + loggedInUser.lastName.charAt(0).toUpperCase() : "?"}
                                                </span>
                                            </span>
                                        </button>

                                        <div
                                            className="origin-top-right absolute right-2 min-w-min p-6 bg-white focus:outline-none shadow-hard border border-black border-solid rounded-xl overflow-hidden"
                                            aria-orientation="vertical"
                                            role="menu"
                                            id="alpine-menu-items-1"
                                            aria-labelledby="alpine-menu-button-1"
                                            tabIndex="0"
                                            style={profileMenu ? {} : { display: "none" }}
                                        >
                                            <div role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabIndex="-1">
                                                <div
                                                    onClick={navigateToSettings}
                                                    className="cursor-pointer block mb-4 w-40 text-base font-normal uppercase tracking-widest"
                                                    role="menuitem"
                                                    tabIndex="-1"
                                                    id="user-menu-item-0"
                                                >
                                                    Settings
                                                </div>
                                                <div
                                                    onClick={navigateToFaq}
                                                    className="cursor-pointer block mb-4 w-40 text-base font-normal uppercase tracking-widest"
                                                    role="menuitem"
                                                    tabIndex="-1"
                                                    id="user-menu-item-1"
                                                >
                                                    FAQS
                                                </div>
                                                <div onClick={handleLogout} className="cursor-pointer block w-40 text-base font-normal uppercase tracking-widest" role="menuitem" tabIndex="-1">
                                                    Logout
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-9 md:hidden" onClick={toggleMobileMenu}>
                                    {mobileMenu ? (
                                        <svg className="bi bi-x -ml-1" xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="white" viewBox="0 0 16 16">
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                        </svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" class="bi bi-list" viewBox="0 0 16 16">
                                            <path
                                                fill-rule="evenodd"
                                                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                                            />
                                        </svg>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="mobile-menu" className="mobile-menu-family overflow-y-auto" style={mobileMenu ? {} : { display: "none" }}>
                        <div className="p-6 pb-0 flex flex-col top-menu text-white">
                            {multimatchDisplay() ? (
                                <div
                                    onClick={navigateToDonorSearch}
                                    className={`${currentPage === "donors" || currentPage === "profile" || currentPage === "profilePhotos" ? "!text-family-apricot" : "!text-white"} cursor-pointer`}
                                >
                                    Find donors
                                </div>
                            ) : null}
                            {myMatchTab()}
                            <div onClick={navigateToChecklist} className={`${currentPage === "checklist" ? "!text-family-apricot" : "!text-white"} cursor-pointer`}>
                                Checklist
                            </div>
                            <div onClick={navigateToDocuments} className={`${currentPage === "documents" ? "!text-family-apricot" : "!text-white"} cursor-pointer`}>
                                Documents
                            </div>
                            <div onClick={navigateToLearn} className={`${currentPage === "learn" ? "!text-family-apricot" : "!text-white"} cursor-pointer`}>
                                Learn
                            </div>
                            <div onClick={navigateToPricing} className={`${currentPage === "pricing" ? "!text-family-apricot" : "!text-white"} cursor-pointer`}>
                                Pricing
                            </div>
                        </div>

                        <div className="mt-8 mx-6 pt-8 pb-3 border-t border-white bottom-menu ">
                            <div className="space-y-4 text-white">
                                {multimatchDisplay() ? (
                                    <div
                                        onClick={navigateToFavorites}
                                        className={`${currentPage === "favorites" ? "!text-family-apricot" : "!text-white"} block mb-4 text-base font-light cursor-pointer`}
                                    >
                                        Favorites
                                    </div>
                                ) : null}
                                <div
                                    onClick={navigateToSettings}
                                    className={`${currentPage === "settings" ? "!text-family-apricot" : "!text-white"} block mb-4 text-base font-light cursor-pointer`}
                                    role="menuitem"
                                    tabIndex="-1"
                                    id="user-menu-item-0"
                                >
                                    Settings
                                </div>
                                <div
                                    onClick={navigateToFaq}
                                    className={`${currentPage === "faq" ? "!text-family-apricot" : "!text-white"} block mb-4 text-base font-light cursor-pointer`}
                                    role="menuitem"
                                    tabIndex="-1"
                                    id="user-menu-item-1"
                                >
                                    FAQS
                                </div>
                                <div onClick={handleLogout} className={`!text-white block mb-4 text-base font-light cursor-pointer`} role="menuitem" tabIndex="-1">
                                    Logout
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>

            <IPDashboardContext.Provider value={{ setCurrentPage, user: loggedInUser, userType: "family" }}>
                <Outlet />
            </IPDashboardContext.Provider>

            {contentLoaded ? (
                <footer className=" bottom-0 left-0 w-full flex justify-between bg-black text-white sm:py-4 sm:px-10 px-6 py-5 font-normal">
                    {footerLogo}
                    <div>
                        <span className="hidden sm:inline">We're here to help. Email us at </span>
                        <a className="underline" href="mailto:match@cofertility.com">
                            match@cofertility.com
                        </a>
                    </div>
                </footer>
            ) : null}
        </div>
    );
};

export default FamilyDashboard;
