import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Filter } from "../../filter/Filter";
import { InputFilter } from "../../filter/InputFilter";
import { DateFilter } from "../../filter/DateFilter";
import { DropdownSelectFilter } from "../../filter/DropdownSelectFilter";
import { matchStatuses, paymentStatuses } from "../../../util/statuses";
import CreateMatchModal from "./CreateMatchModal";
import { AppContext } from "../../../App";
import { StaffMatchesDashboardContext } from "./MatchesPage";

const Matches = () => {
    const [matches, setMatches] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [filters, setFilters] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [showMatchModal, setShowMatchModal] = useState(false);
    const [matchedDateRange, setMatchedDateRange] = useState([null, null]);
    const [orderBy, setOrderBy] = useState("created_at");
    const [orderDir, setOrderDir] = useState("desc");
    const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [hasError, setHasError] = useState(null);
    const filterRef = useRef(null);
    const statusDropdownRef = useRef(null);
    const { request } = useContext(AppContext);
    const { handleMatchCount } = useContext(StaffMatchesDashboardContext);

    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Staff | Matches";
        const fetchMatches = async () => {
            try {
                let filterString = "";
                if (filters.length) {
                    filters.forEach((filter) => {
                        if (filter.name.includes("range")) {
                            if (filter.value[0] !== null) {
                                filterString += `&${filter.name}_min=${filter.value[0]}`;
                            }
                            if (filter.value[1] !== null) {
                                filterString += `&${filter.name}_max=${filter.value[1]}`;
                            }
                        } else if (filter.name === "status") {
                            filter.value.forEach((v) => {
                                filterString += `&${filter.name}=${v}`;
                            });
                        } else {
                            filterString += `&${filter.name}=${filter.value}`;
                        }
                    });
                }

                const response = await request({
                    url: `/staff/matches?page=${page}${filterString}&orderBy=${orderBy}&orderDir=${orderDir}`,
                    method: "GET",
                });

                const { results, count } = response;
                handleMatchCount(count);

                if (page !== 1) {
                    setMatches((prev) => [...prev, ...results]);
                } else {
                    setMatches(results);
                }
                setHasMore(results.length > 0);
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching checklist:", error);
                setHasError(error);
            }
        };

        fetchMatches();
    }, [request, page, orderBy, orderDir, filters]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    useEffect(() => {
        const handleScroll = () => {
            if (hasMore && Math.ceil(window.innerHeight + document.documentElement.scrollTop) >= document.documentElement.offsetHeight) {
                setPage((prevPage) => prevPage + 1);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [hasMore]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                setShowFilters(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (statusDropdownRef.current && !statusDropdownRef.current.contains(event.target)) {
                setStatusDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const navigateToSplitMemberPage = (userId) => {
        navigate(`/staff/split-members/${userId}`);
    };

    const navigateToIntendedParentPage = (userId) => {
        navigate(`/staff/intended-parents/${userId}`);
    };

    const navigateToClinicPage = (clinicId) => {
        navigate(`/staff/clinics/${clinicId}`);
    };

    const navigateToMatchPage = (matchId) => {
        navigate(`/staff/matches/${matchId}`);
    };

    const extractDate = (dateTimeString) => {
        const options = { weekday: "short", year: "numeric", month: "short", day: "numeric" };
        const formattedDate = new Date(dateTimeString).toLocaleDateString("en-US", options);
        return formattedDate;
    };

    const handleOrdering = (prop) => {
        if (orderBy === prop) {
            if (orderDir === "desc") {
                setOrderDir("asc");
            } else {
                setOrderDir("desc");
            }
        } else {
            if (prop.includes("last_name")) {
                setOrderBy(prop);
                setOrderDir("asc");
            } else {
                setOrderBy(prop);
                setOrderDir("desc");
            }
        }
        setPage(1);
    };

    const handleNameSearch = (name, event) => {
        const search = event.target.value;

        const filteredFilters = structuredClone(filters).filter((filter) => filter.name !== name);

        if (search.length) {
            filteredFilters.push({
                name: name,
                value: search,
            });
        }

        setPage(1);
        setFilters(filteredFilters);
    };

    const clearNameSearch = (name) => {
        const filteredFilters = structuredClone(filters).filter((filter) => filter.name !== name);

        setPage(1);
        setFilters(filteredFilters);
    };

    const handleDateFilter = (name, update, setState) => {
        let filteredFilters = structuredClone(filters).filter((filter) => filter.name !== name);

        if (update[0] === null && update[1] === null) {
            setState([null, null]);
        } else {
            filteredFilters.push({
                name: name,
                value: [update[0] !== null ? update[0].getTime() : null, update[1] !== null ? update[1].getTime() : null],
            });

            setState(update);
        }

        setPage(1);
        setFilters(filteredFilters);
    };

    const handleStatusClick = (statusArray, setStatusArray, setDropdownOpen, name, status) => {
        let ns = [];
        if (statusArray.includes(status)) {
            ns = structuredClone(statusArray).filter((s) => s !== status); // Remove if already selected
        } else {
            ns = [...structuredClone(statusArray), status]; // Add if not selected
        }

        let filteredFilters = structuredClone(filters).filter((filter) => filter.name !== name);
        if (ns.length) {
            filteredFilters.push({
                name: name,
                value: ns,
            });
        }
        setPage(1);
        setFilters(filteredFilters);
        setStatusArray(ns);
        setDropdownOpen(false);
    };

    const clearAllStatuses = () => {
        let filteredFilters = structuredClone(filters).filter((filter) => filter.name !== "status");
        setPage(1);
        setFilters(filteredFilters);
        setSelectedStatuses([]);
    };

    const clearAllFilters = () => {
        setFilters([]);
        setMatchedDateRange([null, null]);
        setSelectedStatuses([]);
        setPage(1);
    };

    return (
        <div className="mx-10 mb-20">
            <div className="mb-5" style={{ position: "fixed", top: 80, right: 20, zIndex: 10 }}>
                <button
                    className="bg-white text-secondary border border-secondary rounded-full py-2 px-6 font-semibold hover:bg-green-100 focus:outline-none focus:ring focus:ring-green-200 mr-3"
                    onClick={() => setShowMatchModal(!showMatchModal)}
                >
                    Create a match
                </button>

                {showMatchModal ? <CreateMatchModal closeModal={() => setShowMatchModal(false)} /> : null}

                <button
                    className="bg-white text-secondary border border-secondary rounded-full py-2 px-6 font-semibold hover:bg-green-100 focus:outline-none focus:ring focus:ring-green-200"
                    onClick={() => setShowFilters(!showFilters)}
                >
                    Filters
                </button>
                {showFilters ? (
                    <Filter isStaff={true} filterRef={filterRef} clearAllFilters={clearAllFilters} showFilters={showFilters} setShowFilters={setShowFilters}>
                        {[
                            <InputFilter
                                name="ip_name"
                                title="IP Name"
                                placeholder="IP Name"
                                clearState={clearNameSearch}
                                clearValue="ip_name"
                                filters={filters}
                                handleNameSearch={handleNameSearch}
                            />,
                            <InputFilter
                                name="sm_name"
                                title="SM Name"
                                placeholder="SM Name"
                                clearState={clearNameSearch}
                                clearValue="sm_name"
                                filters={filters}
                                handleNameSearch={handleNameSearch}
                            />,
                            <DateFilter
                                name="match_range"
                                title="Matched"
                                placeholder="Matched Range"
                                dateRange={matchedDateRange}
                                setDateRange={setMatchedDateRange}
                                handleDateFilter={handleDateFilter}
                            />,
                            <DropdownSelectFilter
                                title="Status"
                                name="status"
                                label="Filter by status"
                                dropdownRef={statusDropdownRef}
                                dropdownOpen={statusDropdownOpen}
                                setDropdownOpen={setStatusDropdownOpen}
                                options={matchStatuses}
                                selected={selectedStatuses}
                                setSelected={setSelectedStatuses}
                                handleSelection={handleStatusClick}
                                clearSelected={clearAllStatuses}
                            />,
                        ]}
                    </Filter>
                ) : null}
            </div>
            <table className="table-fixed w-full mt-8">
                <thead>
                    <tr className="w-full">
                        <th className="w-[30px] text-left py-2 border-b border-dashed border-gray-300">#</th>
                        <th className="w-[30%] text-left py-2 border-b border-dashed border-gray-300 px-5">
                            <span onClick={() => handleOrdering("created_at")} className="flex items-center">
                                <span className="mr-1">Matched</span>
                                <span className="flex items-center">
                                    {orderBy === "created_at" && orderDir === "asc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                    ) : null}
                                    {orderBy === "created_at" && orderDir === "desc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                        </svg>
                                    ) : null}
                                </span>
                            </span>
                        </th>
                        <th className="w-[30%] text-left py-2 border-b border-dashed border-gray-300">
                            <span onClick={() => handleOrdering("intended_parents.last_name")} className="flex items-center">
                                <span className="mr-1">Intended Parent</span>
                                <span className="flex items-center">
                                    {orderBy === "intended_parents.last_name" && orderDir === "desc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                    ) : null}
                                    {orderBy === "intended_parents.last_name" && orderDir === "asc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                        </svg>
                                    ) : null}
                                </span>
                            </span>
                        </th>
                        <th className="w-[30%] text-left py-2 border-b border-dashed border-gray-300">
                            <span onClick={() => handleOrdering("freeze_members.last_name")} className="flex items-center">
                                <span className="mr-1">Split Member</span>
                                <span className="flex items-center">
                                    {orderBy === "freeze_members.last_name" && orderDir === "desc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                    ) : null}
                                    {orderBy === "freeze_members.last_name" && orderDir === "asc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                        </svg>
                                    ) : null}
                                </span>
                            </span>
                        </th>
                        {/* <th className="w-[10%] text-left py-2 border-b border-dashed border-gray-300">
                            <span onClick={() => handleOrdering("member_advocates.last_name")} className="flex items-center">
                                <span className="mr-1">Advocate</span>
                                <span className="flex items-center">
                                    {orderBy === "member_advocates.last_name" && orderDir === "desc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                    ) : null}
                                    {orderBy === "member_advocates.last_name" && orderDir === "asc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                        </svg>
                                    ) : null}
                                </span>
                            </span>
                        </th>
                        <th className="w-[15%] text-left py-2 border-b border-dashed border-gray-300">
                            <span onClick={() => handleOrdering("clinics.name")} className="flex items-center">
                                <span className="mr-1">Clinic</span>
                                <span className="flex items-center">
                                    {orderBy === "clinics.name" && orderDir === "desc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                    ) : null}
                                    {orderBy === "clinics.name" && orderDir === "asc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                        </svg>
                                    ) : null}
                                </span>
                            </span>
                        </th>
                        <th className="w-[13%] text-left py-2 border-b border-dashed border-gray-300">
                            <span onClick={() => handleOrdering("clinic_staff.last_name")} className="flex items-center">
                                <span className="mr-1">Doctor</span>
                                <span className="flex items-center">
                                    {orderBy === "clinic_staff.last_name" && orderDir === "desc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                    ) : null}
                                    {orderBy === "clinic_staff.last_name" && orderDir === "asc" ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                        </svg>
                                    ) : null}
                                </span>
                            </span>
                        </th> */}
                        <th className="w-[13%] text-left py-2 border-b border-dashed border-gray-300">
                            <span className="flex items-center">
                                <span className="mr-1">Status</span>
                            </span>
                        </th>
                        {/* <th className="w-[10%] text-left py-2 border-b border-dashed border-gray-300">
                            <span className="flex items-center">
                                <span className="mr-1">Payment</span>
                            </span>
                        </th> */}
                    </tr>
                </thead>
                <tbody>
                    {matches &&
                        matches.map((match) => {
                            return (
                                <tr key={match.id}>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300 text-secondary">
                                        <Link className="cursor-pointer" to={`/staff/matches/${match.id}`}>
                                            {match.match_number}
                                        </Link>
                                    </td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300 px-5">{extractDate(match.created_at)}</td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300 text-secondary cursor-pointer">
                                        <Link to={`/staff/intended-parents/${match.family.intended_parents[0].user.id}`}>
                                            <span className="w-6 h-6 rounded-full px-2 py-1 text-black text-sm mr-2" style={{ backgroundColor: "#D2E9E6" }}>
                                                {match.family.intended_parents[0].user.firstName.charAt(0).toUpperCase()}
                                            </span>
                                            <span className="font-medium">
                                                {match.family.intended_parents[0].user.firstName} {match.family.intended_parents[0].user.lastName}
                                            </span>
                                        </Link>
                                    </td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300 text-secondary cursor-pointer">
                                        <Link to={() => `/staff/split-members/${match.freeze_member.id}`}>
                                            <span className="w-6 h-6 rounded-full px-2 py-1 text-black text-sm mr-2" style={{ backgroundColor: "#D2E9E6" }}>
                                                {match.freeze_member.firstName.charAt(0).toUpperCase()}
                                            </span>
                                            <span className="font-medium">{match.freeze_member.donorCode}</span>
                                        </Link>
                                    </td>
                                    {/* <td className="text-left py-2 border-b border-dashed border-gray-300">
                                        <span>{match.member_advocate ? `${match.member_advocate.firstName} ${match.member_advocate.lastName[0]}.` : "-"}</span>
                                    </td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300 text-secondary cursor-pointer">
                                        <span
                                            onClick={() => {
                                                if (match.clinic) navigateToClinicPage(match.clinic.id);
                                            }}
                                            className="font-medium"
                                        >
                                            {match.clinic ? match.clinic.name : "-"}
                                        </span>
                                    </td>
                                    <td className="text-left py-2 border-b border-dashed border-gray-300">
                                        <span>{match.doctor ? `${match.doctor.first_name} ${match.doctor.last_name}` : "-"}</span>
                                    </td> */}
                                    <td className="text-left py-2 border-b border-dashed border-gray-300">
                                        <span
                                            className="text-secondary px-2 py-1 rounded-md borde w-1/2 font-medium text-sm"
                                            style={{
                                                backgroundColor: matchStatuses.filter((status) => status.value === match.status)[0].bg,
                                                color: matchStatuses.filter((status) => status.value === match.status)[0].color,
                                            }}
                                        >
                                            {matchStatuses.filter((status) => status.value === match.status)[0].label}
                                        </span>
                                    </td>
                                    {/* <td className="text-left py-2 border-b border-dashed border-gray-300">
                                    <span className="text-secondary px-2 py-1 rounded-md borde w-1/2 font-medium text-sm" style={{ backgroundColor: paymentStatuses.filter(status => status.value === match.payment_status)[0].bg, color: paymentStatuses.filter(status => status.value === match.payment_status)[0].color }}>
                                    { paymentStatuses.filter(status => status.value === match.payment_status)[0].label }
                                    </span>
                                </td> */}
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
};

export default Matches;
