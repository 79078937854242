import { useEffect, useState, useRef, useContext, createContext } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { AppContext } from "../../App";

export const SMDashboardContext = createContext(null);

const Dashboard = () => {
    const [currentPage, setCurrentPage] = useState("dashboard");
    const [profileMenu, setProfileMenu] = useState(false);
    const [mobileMenu, setMobileMenu] = useState(false);
    const [userRetrieved, setUserRetrieved] = useState(false);
    const [contentLoaded, setContentLoaded] = useState(false);

    const { logout } = useAuth0();
    const menuRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { loggedInUser, refreshUser } = useContext(AppContext);

    useEffect(() => {
        const timer = setTimeout(() => {
            setContentLoaded(true);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const refresh = async () => {
            await refreshUser();
            setUserRetrieved(true);
        };

        if (
            loggedInUser === null ||
            (loggedInUser && loggedInUser.freeze_member && loggedInUser.freeze_member.status === "application_in_progress" && location.state?.from === "/freeze/application")
        ) {
            refresh();
        } else if (!loggedInUser.freeze_member) {
            navigate("/family/login");
        } else if (loggedInUser.freeze_member.status === "disqualified") {
            navigate("/keep/checklist");
        } else if (loggedInUser.freeze_member.status === "application_in_progress") {
            navigate("/freeze/application");
        } else {
            setUserRetrieved(true);
        }
    }, [location, loggedInUser, refreshUser, setUserRetrieved, navigate]);

    useEffect(() => {
        document.title = "Cofertility | Freeze Dashboard";
        const handleOutsideClick = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setProfileMenu(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
        });
    };

    const handleLogout = () => {
        logout({
            logoutParams: {
                returnTo: window.location.origin + "/freeze/login",
            },
        });
    };

    const toggleProfileMenu = () => {
        setProfileMenu(!profileMenu);
    };

    const toggleMobileMenu = () => {
        setMobileMenu(!mobileMenu);
    };

    const navigateToDashboard = () => {
        scrollToTop();
        navigate("/freeze/dashboard");
    };

    const navigateToProfile = () => {
        scrollToTop();
        navigate("/freeze/profile");
    };

    const navigateToDocuments = () => {
        scrollToTop();
        navigate("/freeze/documents");
    };

    const navigateToLearn = () => {
        window.open("https://www.cofertility.com/freeze/learn", "_blank");
    };

    const navigateToFaq = () => {
        scrollToTop();
        setCurrentPage("faq");
        navigate("/freeze/faq");
    };

    const navigateToSettings = () => {
        scrollToTop();
        navigate("/freeze/settings");
    };

    let footerLogo = <img alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/logo_white.svg`} />;

    if (!userRetrieved) {
        return null;
    }

    return (
        <div
            id="inner-body"
            className={`min-h-screen flex flex-col bg-freeze-dashboard-mobile sm:bg-freeze-dashboard 2xl:bg-freeze-dashboard-xl h-full w-full bg-white-200 bg-cover bg-fixed bg-center sm:bg-top`}
        >
            <header className="h-20 z-20">
                <nav className="bg-primary fixed w-full z-20">
                    <div className="">
                        <div className="flex justify-between md:grid grid-cols-3 items-center h-20">
                            <div className="flex items-center ml-5">
                                <div className="text-secondary">
                                    <img className="w-[165px] h-[32px] md:w-[180px] md:h-[42px]" height="31" width="250" alt="Cofertility logo" src={`${process.env.PUBLIC_URL}/images/logo_forest.svg`} /> 
                                </div>
                            </div>

                            <div className="hidden md:flex md:justify-center items-center navigation gap-x-5">
                                <div onClick={navigateToDashboard} className={`${currentPage === "dashboard" ? "active" : ""} cursor-pointer text-nowrap hover:font-medium w-[53px] !text-black`}>
                                    Home
                                </div>
                                {loggedInUser.freeze_member.status !== "application_in_progress" &&
                                loggedInUser.freeze_member.status !== "application_submission" &&
                                loggedInUser.freeze_member.status !== "application_subhold" &&
                                loggedInUser.freeze_member.status !== "application_subhold_med_review" &&
                                loggedInUser.freeze_member.status !== "retired" ? (
                                    <div
                                        onClick={navigateToProfile}
                                        className={`${currentPage === "profile" || currentPage === "photos" ? "active" : ""} cursor-pointer text-nowrap hover:font-medium w-[71px] !text-black`}
                                    >
                                        Profile
                                    </div>
                                ) : null}
                                {loggedInUser.freeze_member.status !== "retired" ? (
                                    <div onClick={navigateToDocuments} className={`${currentPage === "documents" ? "active" : ""} cursor-pointer text-nowrap hover:font-medium w-[110px] !text-black`}>
                                        Documents
                                    </div>
                                ) : null}
                                <div onClick={navigateToLearn} className={`${currentPage === "learn" ? "active" : ""} cursor-pointer text-nowrap hover:font-medium w-[57px] !text-black`}>
                                    Learn
                                </div>
                            </div>

                            <div className="-ml-2 mr-2 flex justify-end md:hidden">
                                <button
                                    onClick={toggleMobileMenu}
                                    type="button"
                                    className="inline-flex items-center justify-center p-4 rounded-md text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                                    aria-controls="mobile-menu"
                                    aria-expanded={mobileMenu}
                                >
                                    <span className="sr-only">Open main menu</span>
                                    <div className="w-6 flex items-center justify-center relative">
                                        <span className={`${mobileMenu ? "translate-y-0 rotate-45" : "-translate-y-2"} transform transition w-full h-px bg-current absolute`}></span>
                                        <span className={`${mobileMenu ? "opacity-0 translate-x-3" : "opacity-100"} transform transition w-full h-px bg-current absolute`}></span>
                                        <span className={`${mobileMenu ? "translate-y-0 -rotate-45" : "translate-y-2"} transform transition w-full h-px bg-current absolute`}></span>
                                    </div>
                                </button>
                            </div>

                            <div ref={menuRef} className="hidden md:flex justify-end md:items-center mr-2">
                                <div className="relative max-h-10.5">
                                    <button
                                        onClick={toggleProfileMenu}
                                        type="button"
                                        className="header-menu-button !pb-0"
                                        aria-haspopup="true"
                                        aria-labelledby="alpine-menu-label-1"
                                        id="alpine-menu-button-1"
                                        aria-expanded={profileMenu}
                                    >
                                        <span className="sr-only">Open user menu</span>
                                        <span className={`${loggedInUser.user_photos ? "flex justify-center" : ""}`}>
                                            <span
                                                className={`${loggedInUser.user_photos ? "relative" : ""} w-10 h-10 rounded-full px-2 py-2 text-black text-sm mr-2`}
                                                style={{ backgroundColor: "#D2E9E6" }}
                                            >
                                                {loggedInUser.user_photos ? (
                                                    <img
                                                        src={loggedInUser.user_photos[0].cropped_source}
                                                        alt={loggedInUser.user_photos[0].name}
                                                        className="absolute inset-0 w-full h-full object-cover rounded-full"
                                                    />
                                                ) : loggedInUser.firstName ? (
                                                    loggedInUser.firstName.charAt(0).toUpperCase()
                                                ) : (
                                                    "?"
                                                )}
                                            </span>
                                        </span>
                                    </button>

                                    <div
                                        className="origin-top-right absolute right-0 min-w-min p-6 bg-white focus:outline-none shadow-hard border border-black border-solid rounded-xl overflow-hidden"
                                        aria-orientation="vertical"
                                        role="menu"
                                        id="alpine-menu-items-1"
                                        aria-labelledby="alpine-menu-button-1"
                                        tabIndex="0"
                                        style={profileMenu ? {} : { display: "none" }}
                                    >
                                        <div role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabIndex="-1">
                                            <div
                                                onClick={navigateToSettings}
                                                className="cursor-pointer block mb-4 w-40 text-base font-normal uppercase tracking-widest"
                                                role="menuitem"
                                                tabIndex="-1"
                                                id="user-menu-item-0"
                                            >
                                                Settings
                                            </div>
                                            <div
                                                onClick={navigateToFaq}
                                                className="cursor-pointer block mb-4 w-40 text-base font-normal uppercase tracking-widest"
                                                role="menuitem"
                                                tabIndex="-1"
                                                id="user-menu-item-1"
                                            >
                                                FAQS
                                            </div>
                                            <div onClick={handleLogout} className="cursor-pointer block w-40 text-base font-normal uppercase tracking-widest" role="menuitem" tabIndex="-1">
                                                Logout
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="mobile-menu" className="mobile-menu" style={mobileMenu ? {} : { display: "none" }}>
                        <div className="p-6 pb-0 flex flex-col top-menu">
                            <div onClick={navigateToDashboard} className={`${currentPage === "dashboard" ? "active" : ""} cursor-pointer`}>
                                Home
                            </div>
                            {loggedInUser.freeze_member.status !== "application_in_progress" &&
                            loggedInUser.freeze_member.status !== "application_submission" &&
                            loggedInUser.freeze_member.status !== "application_subhold" &&
                            loggedInUser.freeze_member.status !== "application_subhold_med_review" ? (
                                <div onClick={navigateToProfile} className={`${currentPage === "profile" || currentPage === "photos" ? "active" : ""} cursor-pointer`}>
                                    Profile
                                </div>
                            ) : null}
                            <div onClick={navigateToDocuments} className={`${currentPage === "documents" ? "active" : ""} cursor-pointer`}>
                                Documents
                            </div>
                            <div onClick={navigateToLearn} className={`${currentPage === "learn" ? "active" : ""} cursor-pointer`}>
                                Learn
                            </div>
                        </div>

                        <div className="mt-8 mx-6 pt-8 pb-3 border-t border-black bottom-menu">
                            <div className="space-y-4">
                                <div onClick={navigateToSettings} className="block mb-4 text-base font-light active" role="menuitem" tabIndex="-1" id="user-menu-item-0">
                                    Settings
                                </div>
                                <div onClick={navigateToFaq} className="block mb-4 text-base font-light active" role="menuitem" tabIndex="-1" id="user-menu-item-1">
                                    FAQS
                                </div>
                                <div onClick={handleLogout} className="block mb-4 text-base font-light" role="menuitem" tabIndex="-1">
                                    Logout
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>

            <main className="flex-grow">
                <SMDashboardContext.Provider value={{ setCurrentPage, user: loggedInUser, userType: "split-members" }}>
                    <Outlet />
                </SMDashboardContext.Provider>
            </main>

            <footer className="bottom-0 left-0 w-full flex justify-between bg-black text-white sm:py-4 sm:px-10 px-6 py-5 font-normal">
                {footerLogo}
                <div>
                    <span className="hidden sm:inline">We're here to help. Email us at </span>
                    <a className="underline" href="mailto:freeze-support@cofertility.com">
                        freeze-support@cofertility.com
                    </a>
                </div>
            </footer>
        </div>
    );
};

export default Dashboard;
