import React from "react";

export const DropdownSelectFilter = ({ title, name, label, dropdownRef, dropdownOpen, setDropdownOpen, options, selected, setSelected, handleSelection, clearSelected }) => {
    return (
        <div key={name} className="!text-[#191919]">
            <h4 className="p-1 my-1 ">{title}</h4>
            <div className="relative p-1" ref={dropdownRef}>
                <button onClick={() => setDropdownOpen(!dropdownOpen)} className="w-3/4 py-2 px-2 rounded-md border bg-white text-gray-700 border-solid border-black flex justify-between">
                    {selected.length ? (
                        selected.map((selection) => {
                            const option = options.filter((s) => s.value === selection)[0];
                            return (
                                <span key={option.label} className={`px-2 py-1 rounded-md font-medium text-xs mx-1 flex items-center `} style={{ backgroundColor: option.bg, color: option.color }}>
                                    {option.label}
                                    <div onClick={() => handleSelection(selected, setSelected, setDropdownOpen, name, selection)} className="ml-1 inline-block">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x cursor-pointer" viewBox="0 0 16 16">
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                        </svg>
                                    </div>
                                </span>
                            );
                        })
                    ) : (
                        <span className="">{label}</span>
                    )}
                    <span onClick={() => clearSelected(name, setSelected)}>x</span>
                </button>

                {dropdownOpen && (
                    <div className="absolute mt-1 ml-1 w-[73%] py-2 left-0 right-0 bg-white rounded-md shadow-lg" style={{ zIndex: 1000 }}>
                        {options.map((option) => (
                            <div
                                key={option.value}
                                onClick={() => handleSelection(selected, setSelected, setDropdownOpen, name, option.value)}
                                className={`w-11/12 px-2 py-1 rounded-md font-medium text-xs my-2 mx-auto  ${selected.includes(option.value) ? "border-2 border-solid border-black" : ""}`}
                                style={{ backgroundColor: option.bg, color: option.color }}
                            >
                                {option.label}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className="w-full border-b border-solid border-gray-300 my-4"></div>
        </div>
    );
};
