import React, { useState, useRef, useEffect, useContext, createContext, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import { extractDate, formatPhoneNumber, setDateOfBirth } from "../../util/helpers";

export const StaffDashboardContext = createContext(null);

const Staff = () => {
    const [socket, setSocket] = useState(null);
    const [profileMenu, setProfileMenu] = useState(false);
    const [powersearchOpen, setPowersearchOpen] = useState(false);
    const [powersearch, setPowersearch] = useState("");
    const [psReults, setPSResults] = useState([]);
    const { logout } = useAuth0();
    const menuRef = useRef(null);
    const navigate = useNavigate();
    const debounceTimeoutRef = useRef(null);
    const powerSearchRef = useRef(null);

    const { request, loggedInUser, refreshUser } = useContext(AppContext);

    const memoizedContextValue = useMemo(() => ({}), []);

    useEffect(() => {
        const refresh = async () => {
            await refreshUser();
        };

        if (loggedInUser === null) {
            refresh();
        }
    }, [loggedInUser, refreshUser]);

    // useEffect(() => {
    //     const ws = new WebSocket(process.env.REACT_APP_API_SERVER_URL + "/staff/websocket");

    //     ws.onopen = () => {
    //         console.log("Connect to server websocket");
    //         setSocket(ws);
    //     };

    //     ws.onmessage = (event) => {
    //         var message = JSON.parse(event.data);
    //         console.log("Got message", message);

    //         // if (message.type === "refresh") {
    //         //     console.log("refreshing inbox");
    //         //     setRefetchInbox((prev) => prev + 1);

    //         //     const currentSelectedUser = selectedUserRef.current;
    //         //     console.log("currentSelectedUser", currentSelectedUser);
    //         //     if (currentSelectedUser && message.content === currentSelectedUser.id) {
    //         //         console.log("refreshing chat");
    //         //         setRefetchChat((prev) => prev + 1);
    //         //     }
    //         // }
    //     };

    //     ws.onerror = (error) => {
    //         console.log("Got error", error);
    //     };

    //     ws.onclose = () => {
    //         console.log("Disconnected");
    //     };

    //     return () => {
    //         ws.close();
    //     };
    // }, []);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setProfileMenu(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (powerSearchRef.current && !powerSearchRef.current.contains(event.target)) {
                closePowersearch();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleLogout = () => {
        logout({
            logoutParams: {
                returnTo: window.location.origin + "/staff/login",
            },
        });
    };

    const toggleProfileMenu = () => {
        setProfileMenu(!profileMenu);
    };

    let sidebarLogo = (
        <div className="flex-shrink-0 flex items-center pl-10">
            <img className="child-left block xl:hidden" src={`${process.env.PUBLIC_URL}/images/logo_c.svg`} alt="" width="24px" height="265" />
            <img className="child-left hidden xl:block h-8 w-auto " src={`${process.env.PUBLIC_URL}/images/logo_white.svg`} alt="" width="257" height="50" />
        </div>
    );

    const closePowersearch = () => {
        document.body.style.overflow = "unset";
        setPSResults([]);
        setPowersearch("");
        setPowersearchOpen(false);
    };

    const handleInputChange = (event) => {
        let search = event.target.value;
        setPowersearch(search);

        clearTimeout(debounceTimeoutRef.current);
        debounceTimeoutRef.current = setTimeout(async () => {
            if (search.length) {
                search = search.replaceAll("+", "%2B");
                try {
                    const response = await request({
                        url: `/staff/powersearch?query=${search}`,
                        method: "GET",
                    });

                    const { results } = response;

                    setPSResults(results);
                } catch (error) {
                    console.error("Error fetching search results:", error);
                }
            } else {
                setPSResults([]);
            }
        }, 300);
    };

    const navigateToUserPage = (user) => {
        closePowersearch();
        if (user.freeze_member) {
            navigate(`/staff/split-members/${user.id}`);
        } else if (user.intended_parent) {
            navigate(`/staff/intended-parents/${user.id}`);
        }
    };

    return (
        <div className="flex">
            <div className="w-1/6 flex flex-col">
                <header x-data="{open: false}" className="h-20 z-10">
                    <nav className="bg-black fixed w-1/6 h-full">
                        <div className="container max-w-outer">
                            <div className="flex h-20" style={{ alignItems: "center", justifyContent: "space-between" }}>
                                {sidebarLogo}

                                <button
                                    onClick={() => {
                                        setPowersearchOpen(true);
                                        document.body.style.overflow = "hidden";
                                    }}
                                    className="focus:outline-none"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 fill-current text-gray-500 hover:text-gray-600" viewBox="0 0 24 24">
                                        <path d="M10 18a7.952 7.952 0 004.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0018 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z" />
                                    </svg>
                                </button>

                                <div ref={menuRef} className=" child-right flex">
                                    <div className="relative max-h-10.5">
                                        <button
                                            onClick={toggleProfileMenu}
                                            type="button"
                                            className="header-menu-button"
                                            aria-haspopup="true"
                                            aria-labelledby="alpine-menu-label-1"
                                            id="alpine-menu-button-1"
                                            aria-expanded={profileMenu}
                                        >
                                            <span className="sr-only">Open user menu</span>
                                            <img
                                                className="hidden xl:block h-10.5 w-10.5 rounded-full"
                                                src="https://images.unsplash.com/photo-1601814933824-fd0b574dd592?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1112&q=80"
                                                alt="Profile"
                                            />
                                        </button>

                                        <div
                                            className="origin-top-right absolute right-0 min-w-min p-6 bg-white focus:outline-none shadow-hard border border-black border-solid rounded-xl overflow-hidden"
                                            aria-orientation="vertical"
                                            role="menu"
                                            id="alpine-menu-items-1"
                                            aria-labelledby="alpine-menu-button-1"
                                            tabIndex="0"
                                            style={profileMenu ? {} : { display: "none" }}
                                        >
                                            <div role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabIndex="-1">
                                                <div onClick={handleLogout} className="cursor-pointer block w-40 text-base font-normal uppercase tracking-widest" role="menuitem" tabIndex="-1">
                                                    Logout
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <Link
                                    to={"/staff/split-members"}
                                    className={`${
                                        window.location.pathname.includes("split-member") ? "bg-white text-black" : "bg-black text-white"
                                    } bg-black border-transparent inline-flex items-center w-full pl-10 py-5 border-b-2 text-sm font-medium cursor-pointer`}
                                >
                                    SPLIT MEMBERS
                                </Link>
                                <Link
                                    to={"/staff/intended-parents"}
                                    className={`${
                                        window.location.pathname.includes("intended-parent") ? "bg-white text-black" : "bg-black text-white"
                                    } bg-black border-transparent inline-flex items-center w-full pl-10 py-5 border-b-2 text-sm font-medium cursor-pointer`}
                                >
                                    INTENDED PARENTS
                                </Link>
                                <Link
                                    to={"/staff/matches"}
                                    className={`${
                                        window.location.pathname.includes("match") ? "bg-white text-black" : "bg-black text-white"
                                    } bg-black border-transparent inline-flex items-center w-full pl-10 py-5 border-b-2 text-sm font-medium cursor-pointer`}
                                >
                                    MATCHES
                                </Link>
                                {/* <Link
                                    to={"/staff/clinics"}
                                    className={`${
                                        options.page === "clinics" || options.page === "clinic" ? "bg-white text-black" : "bg-black text-white"
                                    } bg-black border-transparent inline-flex items-center w-full pl-10 py-5 border-b-2 text-sm font-medium cursor-pointer`}
                                >
                                    CLINICS
                                </Link> */}
                                <Link
                                    to={"/family/donors"}
                                    className={`bg-black text-white border-transparent inline-flex items-center w-full pl-10 py-5 border-b-2 text-sm font-medium cursor-pointer`}
                                >
                                    ACTIVE DONOR PLATFORM
                                </Link>
                                {/* <Link
                                    to={"/staff/account"}
                                    className={`${
                                        options.page === "settings" ? "bg-white text-black" : "bg-black text-white"
                                    } bg-black border-transparent inline-flex items-center w-full pl-10 py-5 border-b-2 text-sm font-medium cursor-pointer`}
                                >
                                    MY ACCOUNT
                                </Link> */}
                            </div>
                        </div>
                    </nav>
                </header>
            </div>

            <div id="staff-content" className="w-5/6 flex flex-col">
                {powersearchOpen ? (
                    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div ref={powerSearchRef} className="modal-content w-full max-w-3xl bg-white p-10 rounded-md shadow-md relative max-h-[500]">
                            <div>
                                <h3 className="text-2xl font-semibold mb-4">
                                    <span className="heading-underline-forest-50 ">Member Search</span>
                                </h3>
                                <input
                                    autoFocus
                                    value={powersearch}
                                    onChange={handleInputChange}
                                    className="w-full py-5 px-5 rounded-lg border border-gray-300 mb-1"
                                    placeholder="Search for any user..."
                                />
                                <div className="overflow-y-auto max-h-[300px] ml-2 divide-y divide-dashed">
                                    {psReults.map((user) => {
                                        return (
                                            <div key={user.id} onClick={() => navigateToUserPage(user)} className={`flex my-3 bg-gray-100 rounded-md items-center p-3`}>
                                                <div className="w-7 mr-2 mt-1 flex">
                                                    <h1 className="flex items-center justify-center w-7 h-7 rounded-full px-2 py-1 text-black text-sm" style={{ backgroundColor: "#D2E9E6" }}>
                                                        {user.firstName.charAt(0).toUpperCase()}
                                                    </h1>
                                                </div>
                                                <div className="flex-grow flex flex-col text-sm">
                                                    <div className="flex justify-between items-center">
                                                        <span>
                                                            <span className="font-semibold">
                                                                {user && user.donorCode ? `[${user.donorCode}]` : null} {user.firstName} {user.lastName}{" "}
                                                                {user.preferredName ? `"${user.preferredName}"` : null} {user.pronouns ? `(${user.pronouns})` : null}
                                                            </span>
                                                            <span className="text-gray-400 ml-2">| {user.freeze_member ? "Freeze Member" : "Intended Parent"}</span>
                                                        </span>
                                                        <span className="mr-3 flex items-center">
                                                            <span className="text-gray-400">DOB: {setDateOfBirth(user.dateOfBirth)}</span>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="font-base text-xs">
                                                            {user.email} | {formatPhoneNumber(user.phoneNumber)} | {user.city ? `${user.city},` : null} {user.state}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="grid grid-cols-2 gap-x-6 gap-y-4 rounded-md p-4">
                                    <div className="col-span-2 flex justify-end mt-4">
                                        <button onClick={closePowersearch} className="mr-2 px-4 py-2 rounded-md bg-gray-300 hover:bg-gray-400">
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}

                <StaffDashboardContext.Provider value={memoizedContextValue}>
                    <Outlet />
                </StaffDashboardContext.Provider>

                {/* {options.page === "splitMember" ? <SplitMemberPage tab={options.tab} subtab={options.subtab} /> : null}
                {options.page === "publicProfile" ? <PublicProfile staff={true} /> : null}
                {options.page === "publicProfilePhotos" ? <PhotoViewer staff={true} /> : null}

                {options.page === "intendedParent" ? <IntendedParentPage tab={options.tab} subtab={options.subtab} /> : null}
                {options.page === "intendedParents" ? <IntendedParentsPage tab={options.tab} /> : null}

                {options.page === "match" ? <MatchPage /> : null}
                {options.page === "matches" ? <Matches /> : null}

                {options.page === "clinic" ? <ClinicPage tab={options.tab} /> : null}
                {options.page === "clinics" ? <Clinics /> : null}

                {options.page === "settings" ? <DevSettings /> : null} */}
            </div>
        </div>
    );
};

export default Staff;
