import React, { createContext, useState } from "react";
import { Link, Outlet } from "react-router-dom";

export const StaffIntendedParentsDashboardContext = createContext(null);

const IntendedParentsPage = () => {
    const [ipCount, setIpCount] = useState(0);

    const handleIpCount = (newIpCount) => {
        setIpCount(newIpCount);
    };

    const determineTab = () => {
        return "intendedParents";
    };

    return (
        <div className="">
            <div className="divide-y divide-solid pb-10">
                <div className="flex flex-row w-1/2 pt-5 ml-10">
                    <Link
                        to={"/staff/intended-parents"}
                        className={`${determineTab() === "intendedParents" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7`}
                    >
                        INTENDED PARENTS ({ipCount})
                    </Link>
                </div>

                <div></div>
            </div>

            <div>
                <StaffIntendedParentsDashboardContext.Provider value={{ handleIpCount }}>
                    <Outlet />
                </StaffIntendedParentsDashboardContext.Provider>
            </div>
        </div>
    );
};

export default IntendedParentsPage;
