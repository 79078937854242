import React, { useCallback, useContext, useState, useEffect } from "react";
import { loadStripe } from '@stripe/stripe-js';
import {
    CheckoutProvider,
} from '@stripe/react-stripe-js';
import {
    useNavigate,
    useParams
} from "react-router-dom";

import { AppContext } from "../../../App";
import CheckoutForm from './CheckoutForm';
import PayButton from "./PayButton";
import { redirectToFormsort } from "../../../util/helpers";

import TagManager from "@sooro-io/react-gtm-module";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
    {
        betas: ['custom_checkout_beta_5'],
    }
);

const HoldMatch = () => {
    const [clientSecret, setClientSecret] = useState(null);
    const [donorProfilePhoto, setDonorProfilePhoto] = useState(null);
    const [donorCode, setDonorCode] = useState(null);
    const [ipFirstName, setIpFirstName] = useState(null);
    const [ipInitials, setIpInitials] = useState(null);
    const [ipAbbreviatedName, setIpAbbreviatedName] = useState(null);
    const { loggedInUser, request } = useContext(AppContext);
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            const response = await request({
                url: "/payment/create-checkout-session",
                method: "POST",
                data: {
                    donor_user_id: params.userId,
                }
            });
            const data = await response;
            setClientSecret(data.clientSecret);
            setDonorProfilePhoto(data.donorProfilePhoto);
            setDonorCode(data.donorCode);
            setIpFirstName(data.ipFirstName);
            setIpInitials(data.ipInitials);
            setIpAbbreviatedName(data.ipAbbreviatedName);
        }
        fetchData()
        document.title = "Cofertility | Place a hold";
    }, [params.userId]);

    useEffect(() => {
        if (loggedInUser && params.userId) {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'placeHoldClick',
                    accountId: loggedInUser.id,
                    targetId: params.userId
                },
            })
        }
    }, [loggedInUser, params.userId]);

    if (clientSecret) {
        return (
            <div id="inner-body" className="hold-match">
                <button className="mt-8 ml-8" onClick={() => { navigate(`/family/donors/${params.userId}`) }}>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.57012 15.4322C7.9079 15.4322 11.4346 11.9056 11.4346 7.56777C11.4346 7.25306 11.6876 7 12.0023 7C12.3171 7 12.5701 7.25306 12.5701 7.56777C12.5701 10.9452 10.6981 13.8944 7.94035 15.4322H28.5748C28.8895 15.4322 29.1426 15.6853 29.1426 16C29.1426 16.3147 28.8895 16.5678 28.5748 16.5678H7.9371C10.6981 18.1056 12.5669 21.0548 12.5669 24.4322C12.5669 24.7469 12.3138 25 11.9991 25C11.6844 25 11.4313 24.7469 11.4313 24.4322C11.4313 20.0944 7.90466 16.5678 3.56688 16.5678C3.25217 16.5678 2.99911 16.3147 2.99911 16C2.99911 15.6853 3.25217 15.4322 3.56688 15.4322H3.57012Z" fill="#ECEDED" />
                    </svg>
                </button>
                <div id="hold-match-container">
                    <div class="left-section">
                        <h1 className="text-neutral-200">
                            We're excited to make your family building dreams a reality
                            {ipFirstName !== "" ? `, ${ipFirstName}!` : "!"}
                        </h1>
                        <div class="profile">
                            <div className="donor-oocyte">
                                <svg className="photo-mask" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                    <defs>
                                        <clipPath id="photoShape">
                                            <path
                                                d="M100 15c45 0 80 25 85 70 5 50-20 80-80 105s-85-25-90-75c-5-45 35-100 85-100z"
                                                transform="rotate(45 100 100)"
                                            />
                                        </clipPath>
                                    </defs>
                                    <image href={donorProfilePhoto} width="200" height="200" clipPath="url(#photoShape)" />
                                </svg>
                                <p className="text-neutral-200 font-display text-xl">Donor {donorCode}</p>
                            </div>
                            <div className="ip-oocyte">
                                <svg class="icon-mask" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                    <defs>
                                        <clipPath id="iconShape">
                                            <path d="M100 25c55 0 70 30 80 70s-35 105-80 90-70-30-80-80 40-80 90-80z" />
                                        </clipPath>
                                    </defs>
                                    <rect width="200" height="200" clip-path="url(#iconShape)" fill="#7B803B" />
                                    <text
                                        x="50%"
                                        y="55%"
                                        fill="#fff"
                                        font-size="70"
                                        text-anchor="middle"
                                        font-family="seriouslyNostalgic, serif"
                                        dy=".3em"
                                    >
                                        {ipInitials}
                                    </text>
                                </svg>
                                <p className="text-neutral-200 font-display text-xl">{ipAbbreviatedName}</p>
                            </div>
                        </div>
                    </div>

                    <div className="right-section bg-neutral-200">
                        <div>
                            <h2 className="inline-block eyebrow text-secondary">MATCH INITIATION FEE</h2>
                            <p className="amount inline-block float-right text-2xl font-bold text-secondary">$500</p>
                        </div>
                        <p className="text-xs my-2">Please note that the cost of your match will vary depending on whether the donor is in the fresh or frozen program (noted on her profile). After you place a hold on this donor, a member of our team will reach out shortly to discuss the next steps. In the meantime, you can also visit <a href="https://www.cofertility.com/family/pricing" className="underline" title="Cofertility Pricing">cofertility.com/family/pricing</a>.</p>
                        <p className="text-xs my-2">The Match Initiation Fee is non-refundable but if the donor does not pass your clinic's medical screening, this fee is covered under our Baby Guarantee. After you place a hold on this match, you will no longer be able to browse other donors on the Family by Co platform.</p>
                        <div id="stripe-container">
                            <CheckoutProvider
                                stripe={stripePromise}
                                options={{ clientSecret }}
                            >
                                <CheckoutForm />
                                <PayButton />
                            </CheckoutProvider>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
}

export default HoldMatch;


const HoldSuccess = () => {
    const [status, setStatus] = useState(null);
    const [donorProfilePhoto, setDonorProfilePhoto] = useState(null);
    const [ipInitials, setIpInitials] = useState(null);
    const [intakeFormData, setIntakeFormData] = useState(null);
    const [matchConfirmationFormData, setMatchConfirmationFormData] = useState(null);
    const { loggedInUser, refreshUser, request } = useContext(AppContext);
    const params = useParams();

    const [count, setCount] = useState(4); // Initial countdown value
    const navigate = useNavigate();

    useEffect(() => {
        const refresh = async () => {
            await refreshUser();
        };

        if (loggedInUser === null) {
            refresh();
        }
    }, [loggedInUser, refreshUser]);

    useEffect(() => {
        document.title = "Cofertility | Place a hold";
        const fetchCheckoutSession = async function () {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const sessionId = urlParams.get('session_id');
            const response = await request({
                url: `/payment/session-status?session_id=${sessionId}`,
                method: "GET"
            });
            const data = await response;
            setStatus(data.status);
            setDonorProfilePhoto(data.donorProfilePhoto);
            setIpInitials(data.ipInitials);
            setMatchConfirmationFormData(data.ipMatchConfirmationForm);
            setIntakeFormData(data.ipIntakeForm);
        }
        fetchCheckoutSession()
    }, [params]);

    useEffect(() => {
        if (loggedInUser) {
            TagManager.dataLayer({
                dataLayer: {
                    event: "donorHold",
                    accountId: loggedInUser.id,
                },
            });
        }
    }, [loggedInUser]);

    useEffect(() => {
        if (count === 0) {
            if (status === 'complete') {
                redirectToFormsort({
                    form: matchConfirmationFormData,
                    user: loggedInUser,
                    preload: intakeFormData,
                });
            } else {
                window.location.reload();
            }
        }

        const timer = setInterval(() => {
            setCount((prevCount) => prevCount - 1);
        }, 1000);

        return () => clearInterval(timer); // Cleanup the timer
    }, [count, navigate]);

    return (
        <div id="inner-body" className="hold-match">
            <section id="hold-success" className="m-auto">
                <h1 className="text-neutral-200 text-center">{status === "complete" ? "Fantastic! We have placed a hold on this match." : "Fantastic! We're finalizing your hold now."}</h1>
                <div class="profile">
                    <div className="donor-oocyte">
                        <svg className="photo-mask" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                            <defs>
                                <clipPath id="photoShape">
                                    <path
                                        d="M100 15c45 0 80 25 85 70 5 50-20 80-80 105s-85-25-90-75c-5-45 35-100 85-100z"
                                        transform="rotate(45 100 100)"
                                    />
                                </clipPath>
                            </defs>
                            <image href={donorProfilePhoto} width="200" height="200" clipPath="url(#photoShape)" />
                        </svg>
                    </div>
                    <div className="ip-oocyte">
                        <svg class="icon-mask" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                            <defs>
                                <clipPath id="iconShape">
                                    <path d="M100 25c55 0 70 30 80 70s-35 105-80 90-70-30-80-80 40-80 90-80z" />
                                </clipPath>
                            </defs>
                            <rect width="200" height="200" clip-path="url(#iconShape)" fill="#7B803B" />
                            <text
                                x="50%"
                                y="55%"
                                fill="#fff"
                                font-size="70"
                                text-anchor="middle"
                                font-family="seriouslyNostalgic, serif"
                                dy=".3em"
                            >
                                {ipInitials}
                            </text>
                        </svg>
                    </div>
                </div>
                <h1 className="text-neutral-200 text-center my-12">We just have a few more questions.</h1>
                <p className="text-neutral-200 text-center text-sm">Redirecting in {count}...</p>
            </section >
        </div>
    )
}

export { HoldSuccess };
