import { useContext } from "react";
import UploadGallery from "../../files/Photos/UploadGallery";
import { StaffSplitMemberPageContext } from "./SplitMemberPage";

const StaffSMPhotos = () => {
    const { user, userType, setRefreshHeader, staff } = useContext(StaffSplitMemberPageContext);

    document.title = `Staff | ${user.firstName} ${user.lastName[0]}. | Photos`;

    return <UploadGallery user={user} userType={userType} staff={staff} setRefreshHeader={setRefreshHeader} />;
};

export default StaffSMPhotos;
