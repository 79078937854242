import FamilyErrorBoundary from "./FamilyErrorBoundary";
import FreezeErrorBoundary from "./FreezeErrorBoundary";
import KeepErrorBoundary from "./KeepErrorBoundary";
import StaffErrorBoundary from "./StaffErrorBoundary";

export function RootFallback({ error, resetErrorBoundary }) {
    let logo = <img className="pb-2" height="127" width="165" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/logo.svg`} />;

    const message = () => {
        if (error.response) {
            if (error.response.status === 404) {
                return <h1>This service doesn't exist!</h1>;
            }

            if (error.response.status === 401) {
                return <h1>You aren't authorized to see this</h1>;
            }

            if (error.response.status === 503) {
                return <h1>Looks like our API is down</h1>;
            }

            if (error.response.status === 418) {
                return <h1>🫖</h1>;
            }
        }

        return <h1>Oops! Something went wrong</h1>;
    };

    const getComponent = () => {
        const url = window.location.href;

        if (url.includes("/staff")) {
            return <StaffErrorBoundary error={error} message={message()} />;
        } else if (url.includes("/freeze/dashboard") || url.includes("/freeze/profile") || url.includes("/freeze/documents") || url.includes("/freeze/settings") || url.includes("/freeze/faq")) {
            return <FreezeErrorBoundary error={error} message={message()} />;
        } else if (url.includes("/family/checklist") || url.includes("/family/donors") || url.includes("/family/documents") || url.includes("/family/settings") || url.includes("/family/faq")) {
            return <FamilyErrorBoundary error={error} message={message()} />;
        } else if (url.includes("/family")) {
            return (
                <div id="inner-body" className={`min-h-screen flex flex-col bg-white bg-cover bg-fixed bg-top`}>
                    <header className="sm:py-8 sm:px-10 px-6 py-5  flex items-center bg-secondary justify-between">{logo}</header>
                    <div id="app" className="pb-10 freeze flex items-center justify-center">
                        <div role="alert">{message()}</div>
                    </div>
                </div>
            );
        } else if (url.includes("/keep")) {
            return <KeepErrorBoundary error={error} message={message()} />;
        }

        return (
            <div id="inner-body" className={`min-h-screen flex flex-col bg-white bg-cover bg-fixed bg-top`}>
                <header className="sm:py-8 sm:px-10 px-6 py-5  flex items-center bg-primary justify-between">{logo}</header>
                <div id="app" className="pb-10 freeze flex items-center justify-center">
                    <div role="alert">{message()}</div>
                </div>
            </div>
        );
    };

    return getComponent();
}

export function PageNotFoundCatchAll() {
    let logo = <img className="w-[123px] h-[24px] sm:w-[165px] sm:h-[32px] md:w-[180px] md:h-[35px]" height="31" width="250" alt="Cofertility logo" src={`${process.env.PUBLIC_URL}/images/logo_white.svg`} />;

    const message = () => {
        return <h1>Page not found!</h1>;
    };

    const getComponent = () => {
        const url = window.location.href;

        if (url.includes("/staff")) {
            return <StaffErrorBoundary error={new Error("Page not found!")} message={message()} />;
        } else if (url.includes("/freeze/dashboard") || url.includes("/freeze/profile") || url.includes("/freeze/documents") || url.includes("/freeze/settings") || url.includes("/freeze/faq")) {
            return <FreezeErrorBoundary error={new Error("Page not found!")} message={message()} />;
        } else if (url.includes("/family/checklist") || url.includes("/family/donors") || url.includes("/family/documents") || url.includes("/family/settings") || url.includes("/family/faq")) {
            return <FamilyErrorBoundary error={new Error("Page not found!")} message={message()} />;
        } else if (url.includes("/family")) {
            return (
                <div id="inner-body" className={`min-h-screen flex flex-col bg-white bg-cover bg-fixed bg-top`}>
                    <header className="sm:py-8 sm:px-10 px-6 py-5  flex items-center bg-secondary justify-between">{logo}</header>
                    <div id="app" className="pb-10 freeze flex items-center justify-center">
                        <div role="alert">{message()}</div>
                    </div>
                </div>
            );
        } else if (url.includes("/keep")) {
            return <KeepErrorBoundary error={new Error("Page not found!")} message={message()} />;
        }

        return (
            <div id="inner-body" className={`min-h-screen flex flex-col bg-white bg-cover bg-fixed bg-top`}>
                <header className="sm:py-8 sm:px-10 px-6 py-5  flex items-center bg-primary justify-between">{logo}</header>
                <div id="app" className="pb-10 freeze flex items-center justify-center">
                    <div role="alert">{message()}</div>
                </div>
            </div>
        );
    };

    return getComponent();
}
