import React, { useEffect, useState, useRef, useContext } from "react";
import { useSwipeable } from "react-swipeable";
import { AppContext } from "../../../../App";
import { EthnicitiesMap, formatPhoneNumber, RaceMap, SchoolRankingMap } from "../../../../util/helpers";
import { useParams } from "react-router-dom";
import AptitudeChart from "../../../profile/AptitudeChart";

const PDFProfile = ({ options }) => {
    const [user, setUser] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0);
    const [photos, setPhotos] = useState([]);
    const [activeCarouselIndex, setActiveCarouselIndex] = useState(0);
    const [forClinic, setForClinic] = useState(options.admin);
    const [amh, setAMH] = useState("");
    const [answers, setAnswers] = useState(null);
    const [groups, setGroups] = useState(null);
    const [famMedHist, setFamMedHist] = useState(null);
    const [hasError, setHasError] = useState(null);
    const { request } = useContext(AppContext);

    const params = useParams();
    const userId = params.userId;

    const persRef = useRef(null);
    const physAttrsRef = useRef(null);
    const eduWorkRef = useRef(null);
    const famAttrRef = useRef(null);
    const fertRef = useRef(null);
    const medHistRef = useRef(null);
    const famMedHistRef = useRef(null);

    const swipeHandlers = useSwipeable({
        onSwiped: (eventData) => {
            const { dir } = eventData;
            if (dir === "Left") {
                handleCarouselNext();
            } else if (dir === "Right") {
                handleCarouselPrev();
            }
        },
    });

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await request({
                    url: `/staff/split-members/${userId}/pdf-profile`,
                    method: "GET",
                });

                const { user, answers, groupQs, photos, famMedHist, amh } = response;

                setUser(user);
                setAnswers(answers);
                setGroups(groupQs);
                setFamMedHist(famMedHist);
                setPhotos(photos);
                setAMH(amh);

                if (forClinic) {
                    document.title = `${user.donorCode}_admin`;
                } else {
                    document.title = `${user.donorCode}`;
                }
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching profile:", error);
                setHasError(error);
            }
        };

        fetchProfile();
    }, [request, userId, refreshKey, forClinic]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    const formatString = ({ answer, type = null, keys = false, props = null, textArea = false }) => {
        if (!answer) {
            return <span className="italic text-gray-300 font-normal">Empty</span>;
        }

        if (type !== null) {
            if (type === "obj_array") {
                let final = [];
                let ta = "";
                answer.forEach((a, i) => {
                    let result = "";
                    let divs = [];
                    props.forEach((p, j) => {
                        if (!a[p]) {
                            return;
                        }

                        if (p === "degree_type") {
                            a[p] = a[p].toUpperCase();
                        }

                        let bit = `${keys ? `${formatString({ answer: p })}: ` : ""}${formatString({ answer: a[p] })}`;
                        divs.push(<div>{bit}</div>);
                        if (j === 0) {
                            result += bit;
                            return;
                        }

                        if (keys) {
                            result += `\r\n${bit}`;
                        } else {
                            result += ` - ${bit}`;
                        }
                    });
                    if (i === 0) {
                        ta += `${result}`;
                    } else {
                        ta += `\r\n\r\n${result}`;
                    }

                    if (keys) {
                        final.push(<div className="my-2">{divs}</div>);
                    } else {
                        final.push(<div>{result}</div>);
                    }
                });

                if (textArea) {
                    return ta;
                }

                return final;
            } else if (type === "family") {
                let final = [];
                let ta = "";
                props.forEach((p, i) => {
                    if (i === 0 && answers[p]) {
                        ta += `${formatString({ answer: p })}: ${formatString({ answer: answers[p].answer.value })}`;
                        final.push(<div>{`${formatString({ answer: p })}: ${formatString({ answer: answers[p].answer.value })}`}</div>);
                        return;
                    } else if (answers[p]) {
                        ta += `\r\n${formatString({ answer: p })}: ${formatString({ answer: answers[p].answer.value })}`;
                        final.push(<div>{`${formatString({ answer: p })}: ${formatString({ answer: answers[p].answer.value })}`}</div>);
                    }
                });
                final.push(<br />);
                if (textArea) {
                    return ta;
                }

                return final;
            } else if (type === "sibling") {
                let final = [];
                let ta = "";
                props.forEach((p, i) => {
                    if (i === 0 && answer[p]) {
                        ta += `${formatString({ answer: p })}: ${formatString({ answer: answer[p] })}`;
                        final.push(<div>{`${formatString({ answer: p })}: ${formatString({ answer: answer[p] })}`}</div>);
                        return;
                    } else if (answer[p]) {
                        ta += `\r\n${formatString({ answer: p })}: ${formatString({ answer: answer[p] })}`;
                        final.push(<div>{`${formatString({ answer: p })}: ${formatString({ answer: answer[p] })}`}</div>);
                    }
                });
                final.push(<br />);
                if (textArea) {
                    return ta;
                }

                return final;
            }
        }

        if (Array.isArray(answer)) {
            return answer
                .map((sen, i) => {
                    let remUnd = sen.split("_").join(" ");
                    if (i === 0) {
                        return remUnd[0].toUpperCase() + remUnd.slice(1);
                    }

                    return remUnd;
                })
                .join(", ");
        }

        if (typeof answer !== "string") {
            return answer;
        }

        if (answer === "bachelors_degree") {
            return "Bachelor's degree";
        }

        if (answer === "masters_degree") {
            return "Master's degree";
        }

        answer = answer.replace("death_age", "age_of_death_and_cause");

        answer = answer
            .split(" - ")
            .map((w) => (w[0] ? w[0].toUpperCase() + w.slice(1) : ""))
            .join(" - ");
        let split = answer.split(".");
        let capped = split.map((sen, i) => {
            let remUnd = sen.split("_").join(" ");
            if (i === 0) {
                return remUnd[0].toUpperCase() + remUnd.slice(1);
            }

            return remUnd;
        });
        return capped.join(". ");
    };

    const shorthandDate = (dateTimeString) => {
        if (!dateTimeString) {
            return "";
        }

        const options = { year: "numeric", month: "numeric", day: "numeric" };
        const formattedDate = new Date(dateTimeString).toLocaleDateString("en-US", options);
        return formattedDate;
    };

    const headerQuestion = (answer, group = false, labelOverride = null) => {
        if (!answer) {
            return <div></div>;
        }

        return (
            <div className="mb-8 break-inside-avoid">
                <div className="flex md:items-center">
                    <h3 className="italic">{labelOverride ? labelOverride : answer.question.label}</h3>
                </div>
                <div className="whitespace-pre-line">{formatString({ answer: answer.answer.value })}</div>
            </div>
        );
    };

    const simpleQuestion = (answer, group = false, valOverride = null, labelOverride = null, type = null, props = null) => {
        return (
            <div className="mb-5">
                <div className="flex items-center">
                    <div className="font-semibold">{labelOverride ? labelOverride : answer.question.label}</div>
                </div>
                <div>
                    {formatString({
                        answer: valOverride ? valOverride : answer.answer.value,
                        type,
                        props,
                    })}
                </div>
            </div>
        );
    };

    const conditionalQuestion = (conditional, answer = null, group = false, valOverride = null, labelOverride = null, type = null, props = null) => {
        return (
            <div className="mb-5 break-inside-avoid">
                <div className="flex items-center">
                    <div className="font-semibold">{labelOverride ? labelOverride : answer.question.label}</div>
                </div>
                <div className="mt-2">
                    {!conditional || conditional.answer.value === "no" ? (
                        <span className="font-normal">No</span>
                    ) : (
                        formatString({
                            answer: valOverride ? valOverride : answer ? answer.answer.value : null,
                            type,
                            props,
                        })
                    )}
                </div>
            </div>
        );
    };

    const personalitySection = () => {
        return (
            <div id="personality" class="pt-10 break-inside-avoid">
                <div class="grid-cols-12 lg:grid block gap-6">
                    <div class="col-span-4 col-start-1">
                        <div class="flex items-center">
                            <img className="mr-2" height="50" width="50" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/profile/personality_lightbulb.svg`} />
                            <h2 className="w-full text-left">
                                <span class="heading-underline text-left">Personality & motivations</span>
                            </h2>
                        </div>
                    </div>
                    <div class="col-span-8 col-start-5 mt-3 relative">
                        <div class="flex flex-col gap-x-16 lg:grid grid-cols-8 gap-y-12 mt-10 md:mt-5">
                            <div class="col-span-4 border border-black rounded-lg text-center bg-tertiary-light relative bg-freeze-category bg-cover py-12 flex flex-col gap-y-3.5 break-inside-avoid">
                                <h4 class="bg-white border border-black rounded-full italic px-4 py-1 shadow-medium absolute -top-0 ml-1/2 -translate-x-1/2">My personality</h4>
                                <div class="flex flex-col my-auto">
                                    <div class="grid grid-cols-3 px-6 text-sm items-center place-items-center">
                                        <div
                                            class={`col-span-1 place-items-center text-center rounded-full w-26 py-2 ${
                                                answers["logical_creative"] && answers["logical_creative"].answer.value === "logical" ? "bg-primary border-2 border-black" : "bg-gray-100"
                                            }`}
                                        >
                                            Logical
                                        </div>
                                        <div className="col-span-1 place-items-center w-full border-b border-solid border-black my-10"></div>
                                        <div
                                            class={`col-span-1 place-items-center text-center rounded-full w-26 py-2 ${
                                                answers["logical_creative"] && answers["logical_creative"].answer.value === "creative" ? "bg-primary border-2 border-black" : "bg-gray-100"
                                            }`}
                                        >
                                            Creative
                                        </div>
                                    </div>
                                    <div class="grid grid-cols-3 px-6 text-sm items-center place-items-center">
                                        <div
                                            class={`col-span-1 place-items-center text-center rounded-full w-26 py-2 ${
                                                answers["serious_silly"] && answers["serious_silly"].answer.value === "serious" ? "bg-primary border-2 border-black" : "bg-gray-100"
                                            }`}
                                        >
                                            Serious
                                        </div>
                                        <div className="col-span-1 place-items-center w-full border-b border-solid border-black my-10"></div>
                                        <div
                                            class={`col-span-1 place-items-center text-center rounded-full w-26 py-2 ${
                                                answers["serious_silly"] && answers["serious_silly"].answer.value === "silly" ? "bg-primary border-2 border-black" : "bg-gray-100"
                                            }`}
                                        >
                                            Silly
                                        </div>
                                    </div>
                                    <div class="grid grid-cols-3 px-6 text-sm items-center place-items-center">
                                        <div
                                            class={`col-span-1 place-items-center text-center rounded-full w-26 py-2 ${
                                                answers["introvert_extrovert"] &&
                                                (answers["introvert_extrovert"].answer.value === "introverted" || answers["introvert_extrovert"].answer.value === "ambiverted")
                                                    ? "bg-primary border-2 border-black"
                                                    : "bg-gray-100"
                                            }`}
                                        >
                                            Introvert
                                        </div>
                                        <div className="col-span-1 place-items-center w-full border-b border-solid border-black my-10"></div>
                                        <div
                                            class={`col-span-1 place-items-center text-center rounded-full w-26 py-2 ${
                                                answers["introvert_extrovert"] &&
                                                (answers["introvert_extrovert"].answer.value === "extroverted" || answers["introvert_extrovert"].answer.value === "ambiverted")
                                                    ? "bg-primary border-2 border-black"
                                                    : "bg-gray-100"
                                            }`}
                                        >
                                            Extrovert
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-span-4 border border-black rounded-lg text-center bg-tertiary-light relative bg-freeze-category bg-cover py-12 flex flex-col gap-y-3.5 break-inside-avoid">
                                <h4 class="bg-white border border-black rounded-full italic px-4 py-1 shadow-medium absolute -top-0 ml-1/2 -translate-x-1/2">My favorites</h4>
                                <div class="flex flex-col my-auto gap-y-10 md:px-10">
                                    <div class="flex items-center px-10 gap-x-4">
                                        <img className="" height="35" width="35" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/profile/personality_movie.svg`} />
                                        <div class="text-left">{answers["movie"] ? formatString({ answer: answers["movie"].answer.value }) : null}</div>
                                    </div>
                                    <div class="flex items-center px-10 gap-x-4">
                                        <img className="" height="35" width="35" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/profile/personality_book.svg`} />
                                        <div class="text-left">{answers["book"] ? formatString({ answer: answers["book"].answer.value }) : null}</div>
                                    </div>
                                    <div class="flex items-center px-10 gap-x-4">
                                        <img className="" height="35" width="35" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/profile/personality_food.svg`} />
                                        <div class="text-left">{answers["food"] ? formatString({ answer: answers["food"].answer.value }) : null}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full border-b border-solid border-gray-300 my-10"></div>

                        {headerQuestion(answers["passions"])}

                        {headerQuestion(answers["goals_in_life"])}

                        {headerQuestion(answers["greatest_strengths"])}

                        {headerQuestion(answers["perfect_day"])}

                        {headerQuestion(answers["dinner_party"], false, "If you were planning a dinner and could invite any three people (living or dead, famous or not), who would they be and why?")}

                        {headerQuestion(answers["motivation"])}

                        {headerQuestion(answers["message_to_ips"])}
                    </div>
                </div>
            </div>
        );
    };

    const generalQuestionsSection = () => {
        if (!forClinic) {
            return null;
        }

        return (
            <div id="phys-attr" class="py-10 break-inside-avoid">
                <div class="grid-cols-12 lg:grid block gap-6">
                    <div class="col-span-4 col-start-1">
                        <div class="flex items-center">
                            <img className="mr-2" height="50" width="50" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/profile/personality_lightbulb.svg`} />
                            <h2 className="w-full text-left">
                                <span class="heading-underline text-left">General information</span>
                            </h2>
                        </div>
                    </div>
                    <div class="col-span-8 col-start-5 mt-3 relative">
                        <div class="flex flex-col lg:grid grid-cols-8 w-3/4 gap-y-4 mt-10 md:mt-0">
                            <div class="col-span-4">
                                <div class="flex flex-col my-auto gap-y-4">
                                    {attributeValueColumn("Name", answers["first_name"], false, `${user.firstName} ${user.lastName}`, false)}
                                    {attributeValueColumn("Date of birth", answers["dob"], false, shorthandDate(user.dateOfBirth), false)}
                                    {attributeValueColumn("Phone", answers["primary_phone_number"], false, `${formatPhoneNumber(user.phoneNumber)}`, false)}
                                    {attributeValueColumn(
                                        "Address",
                                        answers["address"],
                                        false,
                                        `${user.address1}${user.address2 ? ", " + user.address2 : ""}, ${user.city}, ${user.state}${user.zipcode ? ", " + user.zipcode : ""} ${
                                            user.country ? ", " + user.country : ""
                                        }`,
                                        false
                                    )}
                                </div>
                            </div>

                            <div class="col-span-4">
                                <div class="flex flex-col my-auto gap-y-4">
                                    {attributeValueColumn(
                                        "Citizenship",
                                        answers["citizenship"],
                                        true,
                                        `${answers["citizenship"].answer.value}${answers["citizenship"].answer.value === "no" ? ": " + answers["not_citizen_detail"].answer.value : ""}`
                                    )}

                                    {answers["travel_history_europe"] ? (
                                        <div class="grid grid-cols-2 gap-x-6 text-sm">
                                            <div class="col-span-1 text-left font-semibold">Travel History Europe</div>
                                            <div class="col-span-1 flex items-center">
                                                <div class="text-left">
                                                    {answers["travel_history_europe"].answer.value === "no" ? (
                                                        <span className="font-normal">No</span>
                                                    ) : (
                                                        answers["travel_history_countries"].answer.value.map((a) => (
                                                            <div className="text-nowrap">
                                                                {a.country}: {a.start_of_living_abroad} - {a.end_of_living_abroad}
                                                            </div>
                                                        ))
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}

                                    {answers["travel_history_outside_us"] ? (
                                        <div class="grid grid-cols-2 gap-x-6 text-sm">
                                            <div class="col-span-1 text-left font-semibold">Travel History Outside US</div>
                                            <div class="col-span-1 flex items-center">
                                                <div class="text-left">
                                                    {answers["travel_history_outside_us"].answer.value === "no" ? (
                                                        <span className="font-normal">No</span>
                                                    ) : (
                                                        answers["travel_history_outside_us_details"].answer.value.map((a) => (
                                                            <div className="text-nowrap">
                                                                {a.country}: {a.start_of_travel} - {a.end_of_travel}
                                                            </div>
                                                        ))
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const physicalAttributesSection = () => {
        return (
            <div id="phys-attr" class="py-10 break-inside-avoid">
                <div class="grid-cols-12 lg:grid block gap-6">
                    <div class="col-span-4 col-start-1">
                        <div class="flex items-center">
                            <img className="mr-2" height="50" width="50" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/profile/personality_lightbulb.svg`} />
                            <h2 className="w-full text-left">
                                <span class="heading-underline text-left">Physical attributes</span>
                            </h2>
                        </div>
                    </div>
                    <div class="col-span-8 col-start-5 mt-3 relative">
                        <div class="flex flex-col lg:grid grid-cols-8 w-3/4 gap-y-4 mt-10 md:mt-0">
                            <div class="col-span-4">
                                <div class="flex flex-col my-auto gap-y-4">
                                    {attributeValueColumn("Eye color", answers["eye_color"], false, null, true)}
                                    {attributeValueColumn("Complexion", answers["complexion"], false, null, true)}
                                    {attributeValueColumn("Hair color", answers["hair_color"], false, null, true)}
                                    {attributeValueColumn("Hair style", answers["hair_type"], false, null, true)}
                                    {attributeValueColumn("Hair texture", answers["hair_texture"], false, null, true)}
                                </div>
                            </div>

                            <div class="col-span-4">
                                <div class="flex flex-col my-auto gap-y-4">
                                    {attributeValueColumn("Height", answers["height_ft"], true, `${answers["height_ft"].answer.value}'${answers["height_in"].answer.value}"`)}
                                    {attributeValueColumn("Weight", answers["weight"], false, `${answers["weight"].answer.value} lbs`)}
                                    {attributeValueColumn("Dominant hand", answers["dominant_hand"])}
                                    {attributeValueColumn("Freckles", answers["freckles"])}
                                    {attributeValueColumn("Dimples", answers["dimples"])}
                                    {forClinic ? attributeValueColumn("BMI", answers["elig_bmi"]) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const eduWorkSkillsSection = () => {
        let formattedJobTitle = "";
        if (answers["job_title"] && answers["job_field"]) {
            formattedJobTitle = `${answers["job_title"].answer.value} - ${answers["job_field"].answer.value}`;
        } else if (answers["job_title"]) {
            formattedJobTitle = answers["job_title"].answer.value;
        }

        return (
            <div id="eduskill" class="py-10 break-inside-avoid">
                <div class="grid-cols-12 lg:grid block gap-6">
                    <div class="col-span-4 col-start-1">
                        <div class="flex items-center">
                            <img className="mr-2" height="50" width="50" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/profile/personality_lightbulb.svg`} />
                            <h2 className="w-full text-left">
                                <span class="heading-underline text-left">Education, work & skills</span>
                            </h2>
                        </div>
                    </div>
                    <div class="col-span-8 col-start-5 mt-3 relative">
                        <div class="flex flex-col gap-x-16 mt-10 md:mt-0">
                            {simpleQuestion(answers["education_level"], false, null, "Education")}

                            {answers["education_history"]
                                ? forClinic
                                    ? simpleQuestion(answers["education_history"], false, null, "Degrees/Major", "obj_array", ["degree_type", "specialty_or_major", "school_name"])
                                    : simpleQuestion(answers["education_history"], false, null, "Degrees/Major", "obj_array", ["degree_type", "specialty_or_major"])
                                : null}

                            {formattedJobTitle !== "" ? simpleQuestion(answers["job_title"], true, formattedJobTitle, "Current occupation & field", null) : null}

                            {conditionalQuestion(answers["past_occupations"], answers["previous_jobs"], false, null, "Occupation history", "obj_array", ["job_title", "job_field"])}

                            {answers["achievements"] ? simpleQuestion(answers["achievements"], false, null, "Awards & achievements") : null}

                            {conditionalQuestion(answers["volunteer_work"], answers["volunteer_history"], false, null, "Completed or currently involved in any volunteer work?", "obj_array", [
                                "volunteer_work",
                            ])}
                        </div>
                        <div className="w-full border-b border-solid border-gray-300 my-10"></div>

                        {conditionalQuestion(answers["musical_ability"], answers["describe_musical_ability"], false, null, "Do you play any musical instruments or sing?")}

                        {conditionalQuestion(answers["langs_not_english"], answers["languages"], false, null, "Languages", "obj_array", ["language", "language_proficiency"])}

                        {/* TODO: aptitude scale */}
                        {AptitudeChart(
                            [
                                { name: "Athleticism", value: answers["athletic_ability"] ? answers["athletic_ability"].answer.value : 0 },
                                { name: "Singing", value: answers["singing_ability"] ? answers["singing_ability"].answer.value : 0 },
                                { name: "Art", value: answers["artistic_ability"] ? answers["artistic_ability"].answer.value : 0 },
                                { name: "Science", value: answers["scientific_ability"] ? answers["scientific_ability"].answer.value : 0 },
                                { name: "Math", value: answers["mathematical_ability"] ? answers["mathematical_ability"].answer.value : 0 },
                            ],
                            true
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const lifestyleSection = () => {
        if (!forClinic) {
            return null;
        }

        return (
            <div id="phys-attr" class="py-10 break-inside-avoid">
                <div class="grid-cols-12 lg:grid block gap-6">
                    <div class="col-span-4 col-start-1">
                        <div class="flex items-center">
                            <img className="mr-2" height="50" width="50" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/profile/personality_lightbulb.svg`} />
                            <h2 className="w-full text-left">
                                <span class="heading-underline text-left">Lifestyle factors</span>
                            </h2>
                        </div>
                    </div>
                    <div class="col-span-8 col-start-5 mt-3 relative">
                        <div class="flex flex-col lg:grid grid-cols-8 w-3/4 gap-y-4 mt-10 md:mt-0">
                            <div class="col-span-4">
                                <div class="flex flex-col my-auto gap-y-4">
                                    {attributeValueColumn(
                                        "Nicotine products",
                                        null,
                                        false,
                                        `${formatString({ answer: answers["elig_nicotine_use"].answer.value })}${
                                            answers["elig_nicotine_use"].answer.value === "occasionally" || answers["elig_nicotine_use"].answer.value === "rarely"
                                                ? `: ${formatString({ answer: answers["describe_occasional_nicotine_use"].answer.value })}`
                                                : ""
                                        }`,
                                        false
                                    )}
                                    {attributeValueColumn("Marijuana", answers["marijuana_use"], false, null, false)}
                                    {attributeValueColumn("Alcohol", null, false, `${answers["alcoholic_consumption"].answer.value.split("_").join("-")} drinks/week`, false)}
                                    {attributeValueColumn(
                                        "Drugs",
                                        null,
                                        false,
                                        `${formatString({ answer: answers["drug_use"].answer.value })}${
                                            answers["drug_use"].answer.value === "yes" ? `: ${formatString({ answer: answers["drug_use_details"].answer.value })}` : ""
                                        }`,
                                        false
                                    )}
                                    {answers["legal_history"] ? (
                                        <div class="grid grid-cols-2 gap-x-6 text-sm">
                                            <div class="col-span-1 text-left font-semibold">Legal history</div>
                                            <div class="col-span-1 flex items-center">
                                                <div class="text-left flex flex-col gap-y-3">
                                                    {answers["legal_history"].answer.value === "no" ? (
                                                        <span className="font-normal">No</span>
                                                    ) : (
                                                        answers["legal_history_details"].answer.value.map((a) => (
                                                            <div>
                                                                {a.date_charged}: {a.legal_charge}
                                                            </div>
                                                        ))
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    {answers["incarceration_history"] ? (
                                        <div class="grid grid-cols-2 gap-x-6 text-sm">
                                            <div class="col-span-1 text-left font-semibold">Incarceration history</div>
                                            <div class="col-span-1 flex items-center">
                                                <div class="text-left flex flex-col gap-y-3">
                                                    {answers["incarceration_history"].answer.value === "no" ? (
                                                        <span className="font-normal">No</span>
                                                    ) : (
                                                        answers["incarceration_details"].answer.value.map((a) => (
                                                            <div>
                                                                {a.start_date} - {a.end_date}: {a.reason_for_incarceration}
                                                            </div>
                                                        ))
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    {attributeValueColumn(
                                        "Piercing, acupuncture, tattoos",
                                        null,
                                        false,
                                        `${formatString({ answer: answers["body_modification"].answer.value })}${
                                            answers["body_modification"].answer.value === "yes" ? `: ${formatString({ answer: answers["body_modification_details"].answer.value })}` : ""
                                        }`,
                                        false
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const familyAttributesSection = () => {
        return (
            <div id="fam-attr" class="py-10 break-inside-avoid">
                <div class="grid-cols-12 lg:grid block gap-6">
                    <div class="col-span-4 col-start-1">
                        <div class="flex items-center">
                            <img className="mr-2" height="50" width="50" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/profile/personality_lightbulb.svg`} />
                            <h2 className="w-full text-left">
                                <span class="heading-underline text-left">Family attributes</span>
                            </h2>
                        </div>
                    </div>
                    <div class="col-span-8 col-start-5 mt-3 relative">
                        <div class="flex flex-col gap-y-4 lg:grid grid-cols-8 gap-x-6 mt-10 md:mt-0">
                            <div class="bg-tertiary-light col-span-4 border border-black rounded-md flex flex-col break-inside-avoid">
                                <div className="flex justify-between">
                                    <div class="uppercase font-bold px-6 lg:px-10 py-4 flex gap-2 items-center">
                                        <div class="h-3 w-3 bg-tertiary border border-black rounded-full"></div>
                                        <span>Mother</span>
                                    </div>
                                </div>
                                <div class="flex flex-col gap-y-4 lg:grid grid-cols-4 grid-rows-3 gap-x-6 grid-flow-col border border-black rounded-md p-6 lg:p-10 bg-white-200 h-full self-stretch -m-px">
                                    <div class="col-span-2">
                                        <p class="text-xs font-semibold mb-1">Age</p>
                                        <p class="text-sm flex gap-2">{answers["mother_age"] ? answers["mother_age"].answer.value : `Deceased - ${answers["mother_death_age"].answer.value}`}</p>
                                    </div>
                                    <div class="col-span-2">
                                        <p class="text-xs font-semibold mb-1">Body frame</p>
                                        <p class="text-sm flex gap-2">{answers["mother_body_frame"] ? formatString({ answer: answers["mother_body_frame"].answer.value }) : null}</p>
                                    </div>
                                    <div class="col-span-2">
                                        <p class="text-xs font-semibold mb-1">Height</p>
                                        <p class="text-sm flex gap-2">
                                            {answers["mother_height_ft"] ? answers["mother_height_ft"].answer.value : null}'
                                            {answers["mother_height_in"] ? answers["mother_height_in"].answer.value : null}"
                                        </p>
                                    </div>
                                    <div class="col-span-2">
                                        <p class="text-xs font-semibold mb-1">Eye color</p>
                                        <div className="flex items-center">
                                            {answers["mother_eye_color"] ? (
                                                <img
                                                    srcSet={`${process.env.PUBLIC_URL}/images/profile/${answers["mother_eye_color"].answer.value}.svg`}
                                                    className="inline-block w-3 h-3 mr-2"
                                                    alt={`${answers["mother_eye_color"].answer.value}-eye`}
                                                />
                                            ) : null}
                                            <p class="text-sm flex gap-2">{answers["mother_eye_color"] ? formatString({ answer: answers["mother_eye_color"].answer.value }) : null}</p>
                                        </div>
                                    </div>
                                    <div class="col-span-2">
                                        <p class="text-xs font-semibold mb-1">Hair color</p>
                                        <div className="flex items-center">
                                            {answers["mother_hair_color"] ? (
                                                <img
                                                    srcSet={`${process.env.PUBLIC_URL}/images/profile/${answers["mother_hair_color"].answer.value}.svg`}
                                                    className="inline-block w-3 h-3 mr-2"
                                                    alt={`${answers["mother_hair_color"].answer.value}-eye`}
                                                />
                                            ) : null}
                                            <p class="text-sm flex gap-2">{answers["mother_hair_color"] ? formatString({ answer: answers["mother_hair_color"].answer.value }) : null}</p>
                                        </div>
                                    </div>
                                    <div class="col-span-2">
                                        <p class="text-xs font-semibold mb-1">Complexion</p>
                                        <div className="flex items-center">
                                            {answers["mother_complexion"] ? (
                                                <img
                                                    srcSet={`${process.env.PUBLIC_URL}/images/profile/${answers["mother_complexion"].answer.value}.svg`}
                                                    className="inline-block w-3 h-3 mr-2"
                                                    alt={`${answers["mother_complexion"].answer.value}-eye`}
                                                />
                                            ) : null}
                                            <p class="text-sm flex gap-2">{answers["mother_complexion"] ? formatString({ answer: answers["mother_complexion"].answer.value }) : null}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="bg-tertiary-light col-span-4 border border-black rounded-md flex flex-col break-inside-avoid">
                                <div className="flex justify-between">
                                    <div class="uppercase font-bold px-6 lg:px-10 py-4 flex gap-2 items-center">
                                        <div class="h-3 w-3 bg-tertiary border border-black rounded-full"></div>
                                        <span>Father</span>
                                    </div>
                                </div>
                                <div class="flex flex-col gap-y-4 lg:grid grid-cols-4 grid-rows-3 gap-x-6 grid-flow-col border border-black rounded-md p-6 lg:p-10 bg-white-200 h-full self-stretch -m-px">
                                    <div class="col-span-2">
                                        <p class="text-xs font-semibold mb-1">Age</p>
                                        <p class="text-sm flex gap-2">{answers["father_age"] ? answers["father_age"].answer.value : `Deceased - ${answers["father_death_age"].answer.value}`}</p>
                                    </div>
                                    <div class="col-span-2">
                                        <p class="text-xs font-semibold mb-1">Body frame</p>
                                        <p class="text-sm flex gap-2">{answers["father_body_frame"] ? formatString({ answer: answers["father_body_frame"].answer.value }) : null}</p>
                                    </div>
                                    <div class="col-span-2">
                                        <p class="text-xs font-semibold mb-1">Height</p>
                                        <p class="text-sm flex gap-2">
                                            {answers["father_height_ft"] ? answers["father_height_ft"].answer.value : null}'
                                            {answers["father_height_in"] ? answers["father_height_in"].answer.value : null}"
                                        </p>
                                    </div>
                                    <div class="col-span-2">
                                        <p class="text-xs font-semibold mb-1">Eye color</p>
                                        <div className="flex items-center">
                                            {answers["father_eye_color"] ? (
                                                <img
                                                    srcSet={`${process.env.PUBLIC_URL}/images/profile/${answers["father_eye_color"].answer.value}.svg`}
                                                    className="inline-block w-3 h-3 mr-2"
                                                    alt={`${answers["father_eye_color"].answer.value}-eye`}
                                                />
                                            ) : null}
                                            <p class="text-sm flex gap-2">{answers["father_eye_color"] ? formatString({ answer: answers["father_eye_color"].answer.value }) : null}</p>
                                        </div>
                                    </div>
                                    <div class="col-span-2">
                                        <p class="text-xs font-semibold mb-1">Hair color</p>
                                        <div className="flex items-center">
                                            {answers["father_hair_color"] ? (
                                                <img
                                                    srcSet={`${process.env.PUBLIC_URL}/images/profile/${answers["father_hair_color"].answer.value}.svg`}
                                                    className="inline-block w-3 h-3 mr-2"
                                                    alt={`${answers["father_hair_color"].answer.value}-eye`}
                                                />
                                            ) : null}
                                            <p class="text-sm flex gap-2">{answers["father_hair_color"] ? formatString({ answer: answers["father_hair_color"].answer.value }) : null}</p>
                                        </div>
                                    </div>
                                    <div class="col-span-2">
                                        <p class="text-xs font-semibold mb-1">Complexion</p>
                                        <div className="flex items-center">
                                            {answers["father_complexion"] ? (
                                                <img
                                                    srcSet={`${process.env.PUBLIC_URL}/images/profile/${answers["father_complexion"].answer.value}.svg`}
                                                    className="inline-block w-3 h-3 mr-2"
                                                    alt={`${answers["father_complexion"].answer.value}-eye`}
                                                />
                                            ) : null}
                                            <p class="text-sm flex gap-2">{answers["father_complexion"] ? formatString({ answer: answers["father_complexion"].answer.value }) : null}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {answers["siblings"].answer.value === "yes" ? (
                            <div className="break-inside-avoid">
                                <div className="w-full border-b border-solid border-gray-300 my-10"></div>
                                <div class="flex overflow-x-auto mt-5">
                                    <div className="grid grid-cols-2">
                                        {answers["sibling_details"].answer.value.map((s) => (
                                            <div class="bg-freeze-secondary-50 w-card border border-black rounded-md flex flex-col m-4 break-inside-avoid">
                                                <div className="flex justify-between">
                                                    <div class="uppercase font-bold px-6 lg:px-10 py-4 flex gap-2 items-center">
                                                        <div class="h-3 w-3 bg-freeze-secondary-200 border border-black rounded-full"></div>
                                                        <span>Sibling</span>
                                                    </div>
                                                </div>
                                                <div class="flex flex-col gap-x-6 gap-y-4 grid-flow-col  border border-black rounded-md p-6 lg:p-10 bg-white-200 h-full self-stretch -m-px">
                                                    <div class="">
                                                        <p class="text-xs font-semibold mb-1">Age</p>
                                                        <p class="text-sm flex gap-2">{s.sibling_age ? s.sibling_age : s.sibling_death_age ? `Deceased - ${s.sibling_death_age}` : "Unknown"}</p>
                                                    </div>
                                                    <div class="">
                                                        <p class="text-xs font-semibold mb-1">Sex at birth</p>
                                                        <p class="text-sm flex gap-2">{formatString({ answer: s.sex_at_birth })}</p>
                                                    </div>
                                                    <div class="">
                                                        <p class="text-xs font-semibold mb-1">Half or full sibling</p>
                                                        <p class="text-sm flex gap-2">{formatString({ answer: s.half_or_full_sibling })}</p>
                                                    </div>
                                                    <div class="">
                                                        <p class="text-xs font-semibold mb-1">Height</p>
                                                        <p class="text-sm flex gap-2">
                                                            {s.height_feet}'{s.height_inches}"
                                                        </p>
                                                    </div>
                                                    <div class="">
                                                        <p class="text-xs font-semibold mb-1">Body frame</p>
                                                        <p class="text-sm flex gap-2">{formatString({ answer: s.body_frame })}</p>
                                                    </div>
                                                    <div class="">
                                                        <p class="text-xs font-semibold mb-1">Eye color</p>
                                                        <div className="flex items-center">
                                                            <img
                                                                srcSet={`${process.env.PUBLIC_URL}/images/profile/${s.eye_color}.svg`}
                                                                className="inline-block w-3 h-3 mr-2"
                                                                alt={`${s.eye_color}-eye`}
                                                            />
                                                            <p class="text-sm flex gap-2">{formatString({ answer: s.eye_color })}</p>
                                                        </div>
                                                    </div>
                                                    <div class="col-span-2">
                                                        <p class="text-xs font-semibold mb-1">Hair color</p>
                                                        <div className="flex items-center">
                                                            <img
                                                                srcSet={`${process.env.PUBLIC_URL}/images/profile/${s.hair_color}.svg`}
                                                                className="inline-block w-3 h-3 mr-2"
                                                                alt={`${s.hair_color}-eye`}
                                                            />
                                                            <p class="text-sm flex gap-2">{formatString({ answer: s.hair_color })}</p>
                                                        </div>
                                                    </div>
                                                    <div class="col-span-2">
                                                        <p class="text-xs font-semibold mb-1">Complexion</p>
                                                        <div className="flex items-center">
                                                            <img
                                                                srcSet={`${process.env.PUBLIC_URL}/images/profile/${s.complexion}.svg`}
                                                                className="inline-block w-3 h-3 mr-2"
                                                                alt={`${s.complexion}-eye`}
                                                            />
                                                            <p class="text-sm flex gap-2">{formatString({ answer: s.complexion })}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        <div className="w-full border-b border-solid border-gray-300 my-10"></div>
                        <div class="col-span-8">
                            <div class="flex flex-col my-auto gap-y-4">
                                {singleAttributeValueColumn("Maternal grandmother age", answers["maternal_grandmother_living"], false, getGrandparentStatus("maternal_grandmother"))}
                                {singleAttributeValueColumn("Maternal grandfather age", answers["maternal_grandfather_living"], false, getGrandparentStatus("maternal_grandfather"))}
                                {singleAttributeValueColumn("Paternal grandmother age", answers["paternal_grandmother_living"], false, getGrandparentStatus("paternal_grandmother"))}
                                {singleAttributeValueColumn("Paternal grandfather age", answers["paternal_grandfather_living"], false, getGrandparentStatus("paternal_grandfather"))}
                            </div>
                        </div>

                        <div className="w-full border-b border-solid border-gray-300 my-10"></div>
                        <div class="col-span-8">
                            <div class="flex flex-col my-auto gap-y-4">
                                {singleAttributeValueColumn("Marital status", answers["marital_status"])}
                                {singleAttributeValueColumn("Religion", answers["religion"])}
                                {singleAttributeValueColumn("Jewish ancestry", answers["jewish_ancestry"])}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const fertilitySection = () => {
        return (
            <div id="phys-attr" class="py-10 break-inside-avoid">
                <div class="grid-cols-12 lg:grid block gap-6">
                    <div class="col-span-4 col-start-1">
                        <div class="flex items-center">
                            <img className="mr-2" height="50" width="50" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/profile/personality_lightbulb.svg`} />
                            <h2 className="w-full text-left">
                                <span class="heading-underline text-left">Fertility</span>
                            </h2>
                        </div>
                    </div>
                    <div class="col-span-8 col-start-5 mt-3 relative">
                        <div class="col-span-8 mt-10 md:mt-0">
                            <div class="flex flex-col my-auto gap-y-4">
                                <div class="w-full md:grid md:grid-cols-4 text-sm md:w-full ">
                                    <div class="md:col-span-1 text-left font-semibold">AMH</div>
                                    <div class="md:col-span-3 flex items-center">
                                        <div class="text-left">
                                            {forClinic
                                                ? amh
                                                    ? `${amh} ng/mL`
                                                    : "This donor has not taken an AMH test yet."
                                                : amh >= 2.0
                                                ? "This donor meets Cofertility's AMH requirements of 2.0 or above."
                                                : "AMH results for this donor will be in soon. If you place a match now and their AMH comes back below 2.0, you are eligible for a free rematch or a refund as part of our Baby Guarantee."}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-full border-b border-solid border-gray-300 my-10"></div>
                        {forClinic ? (
                            <div class="flex flex-col my-auto gap-y-4">
                                {attributeValueColumn("Menstruation start", answers["age_first_period"], true, null)}
                                {attributeValueColumn(
                                    "Menstruation regularity",
                                    null,
                                    true,
                                    answers["period_regularity"]
                                        ? `${formatString({ answer: answers["period_regularity"].answer.value })}${
                                              answers["period_regularity"].answer.value === "irregular" || answers["period_regularity"].answer.value === "unsure"
                                                  ? `: ${formatString({ answer: answers["period_details"].answer.value })}`
                                                  : ""
                                          }`
                                        : ""
                                )}
                                {attributeValueColumn(
                                    "Contraception",
                                    answers["contraception"],
                                    true,
                                    answers["contraception"]
                                        ? `${formatString({ answer: answers["contraception"].answer.value })}\n${
                                              answers["contraception"].answer.value.includes("other")
                                                  ? `\nOther method: ${answers["other_contraception_method"] ? formatString({ answer: answers["other_contraception_method"].answer.value }) : ""}`
                                                  : ""
                                          }${
                                              answers["contraception"].answer.value.includes("birth_control_pills")
                                                  ? `\nPill brand: ${answers["birth_control_pills_brand"] ? formatString({ answer: answers["birth_control_pills_brand"].answer.value }) : ""}`
                                                  : ""
                                          }${
                                              answers["contraception"].answer.value.includes("depo_provera")
                                                  ? `\nDP Last shot: ${answers["depoprovera_last_shot"] ? answers["depoprovera_last_shot"].answer.value : ""}\nDP Next shot: ${
                                                        answers["depoprovera_next_shot"] ? answers["depoprovera_next_shot"].answer.value : ""
                                                    }`
                                                  : ""
                                          }`
                                        : ""
                                )}
                                {attributeValueColumn(
                                    "Willing to use BC before cycle",
                                    null,
                                    true,
                                    answers["willing_to_use_bc"]
                                        ? `${formatString({ answer: answers["willing_to_use_bc"].answer.value })}${
                                              answers["willing_to_use_bc"].answer.value === "no"
                                                  ? `: ${answers["cant_use_bc_reason"] ? formatString({ answer: answers["cant_use_bc_reason"].answer.value }) : ""}`
                                                  : ""
                                          }`
                                        : ""
                                )}
                                {answers["sti_pelvic"] ? (
                                    <div class="grid grid-cols-2 gap-x-6 text-sm">
                                        <div class="col-span-1 text-left font-semibold">STI/Pelvic infection history</div>
                                        <div class="col-span-1 flex items-center">
                                            <div class="text-left flex flex-col gap-y-3">
                                                {answers["sti_pelvic"].answer.value === "no" ? (
                                                    <span className="font-normal">No</span>
                                                ) : (
                                                    answers["sti_history"].answer.value.map((a) => (
                                                        <div>
                                                            {a.sti}: Treated {a.sti_treatment_date}
                                                        </div>
                                                    ))
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                                {attributeValueColumn(
                                    "Currently pregnant",
                                    null,
                                    true,
                                    `${formatString({ answer: answers["currently_pregnant"].answer.value })}${
                                        answers["currently_pregnant"].answer.value === "yes" ? `: due ${answers["pregnancy_due_date"].answer.value}` : ""
                                    }`
                                )}
                                {attributeValueColumn(
                                    "Currently breastfeeding",
                                    null,
                                    true,
                                    `${formatString({ answer: answers["currently_breastfeeding"].answer.value })}${
                                        answers["currently_breastfeeding"].answer.value === "yes" ? `: stopping ${answers["stopping_breastfeeding"].answer.value}` : ""
                                    }`
                                )}
                                {attributeValueColumn(
                                    "Past pregnancy",
                                    answers["ever_pregnant"],
                                    true,
                                    `${formatString({ answer: answers["ever_pregnant"].answer.value })}${
                                        answers["ever_pregnant"].answer.value === "yes"
                                            ? `\nTotal pregnancies: ${answers["total_pregnancies"].answer.value}
                                    Miscarriages: ${answers["miscarriage"].answer.value}
                                    Abortion: ${answers["abortion"].answer.value}
                                    Stillbirth: ${answers["stillbirth"].answer.value}
                                    Livebirth: ${answers["live_birth"].answer.value}`
                                            : ""
                                    }`
                                )}
                                {numberedTable(
                                    "Live births",
                                    answers["live_birth_details"],
                                    ["Sex at birth", "Delivery date", "Complications", "Length (cm)", "Weight (lbs)", "Eye color", "Hair color"],
                                    ["sex_at_birth", "delivery_date", "complications", "length_at_birth_cm", "weight_at_birth_lbs", "eye_color_at_birth", "hair_color_at_birth"]
                                )}
                                {attributeValueColumn("Past AMH testing", answers["amh_testing"], true, formatAMHTesting())}
                                {attributeValueColumn(
                                    "Past fertility testing",
                                    answers["fertility_testing"],
                                    true,
                                    `${answers["fertility_testing"].answer.value === "yes" ? `${formatString({ answer: answers["fertility_testing_type"].answer.value })}` : "No"}`
                                )}
                            </div>
                        ) : null}

                        {numberedTable(
                            "Past egg retrieval cycles",
                            answers["egg_retrieval_history"],
                            ["Personal/Donation", "Retrieval date", "# of eggs retrieved", "Clinic name", "Program name"],
                            ["freeze_cycle_reason", "egg_retrieval_history_date", "egg_retrieval_history_count", "egg_retrieval_history_clinic", "egg_retrieval_history_program"]
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const formatAMHTesting = () => {
        if (!answers["amh_testing"]) {
            return "";
        }

        const amhTesting = answers["amh_testing"].answer.value;

        let label = "";

        if (amhTesting === "yes") {
            const when = answers["amh_tested_when"] ? answers["amh_tested_when"].answer.value : "";
            const result = answers["amh_tested_result"] ? answers["amh_tested_result"].answer.value : "";
            const bc = answers["amh_birth_control"] ? answers["amh_birth_control"].answer.value : "";
            const bcType = answers["amh_birth_control_type"] ? answers["amh_birth_control_type"].answer.value : "";

            if (result) label = `Result: ${result}`;
            if (bc) label = `\nOn birth control: ${bc}`;
            if (bc === "yes") label = `\nBC type: ${bcType}`;
            if (when) label = `\nDate: ${when}`;
        } else {
            label = "No";
        }

        return label;
    };

    const medicalHistory = () => {
        return (
            <div id="phys-attr" class="py-10 break-inside-avoid">
                <div class="grid-cols-12 lg:grid block gap-6">
                    <div class="col-span-4 col-start-1">
                        <div class="flex items-center">
                            <img className="mr-2" height="50" width="50" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/profile/personality_lightbulb.svg`} />
                            <h2 className="w-full text-left">
                                <span class="heading-underline text-left">Medical history</span>
                            </h2>
                        </div>
                    </div>
                    <div class="col-span-8 col-start-5 mt-3 relative">
                        <div class="col-span-8 mt-10 md:mt-0">
                            <div class="flex flex-col my-auto gap-y-4">{singleAttributeValueColumn("Blood type", answers["blood_type"])}</div>
                        </div>

                        <div className="w-full border-b border-solid border-gray-300 my-10"></div>
                        <div class="flex flex-col my-auto gap-y-4">
                            {singleAttributeValueColumn("Vision", answers["vision_quality"])}
                            {singleAttributeValueColumn("Near/farsighted", answers["near_far_sighted"])}
                            {singleAttributeValueColumn("Corrective lenses", answers["corrective_lenses"])}
                        </div>

                        <div className="w-full border-b border-solid border-gray-300 my-10"></div>

                        {conditionalQuestion(answers["dental_work"], answers["dental_work_details"], false, null, "Dental work or orthodontic work?")}

                        {answers["allergies"] && answers["allergies"].answer.value === "yes"
                            ? medCards(
                                  "Allergies",
                                  answers["allergy_history"],
                                  "allergy_substance",
                                  ["Symptoms", "Age of first reaction"],
                                  ["allergy_symptoms", "allergy_reaction"],
                                  "med_hist_allergy"
                              )
                            : null}

                        <div className="w-full border-b border-solid border-gray-300 my-10"></div>
                        {conditionalQuestion(answers["outgrown_allergies"], answers["outgrown_allergies_details"], false, null, "Outgrown allergies")}

                        {answers["prescription_medications"] && answers["prescription_medications"].answer.value === "yes"
                            ? medCards(
                                  "Prescription medications taken in the last 12 months",
                                  answers["recent_prescriptions"],
                                  "medication_name",
                                  ["Reason", "Frequency", "Date last taken", "Time on medication", "Willing to stop"],
                                  ["medication_reason", "medication_frequency", "medication_date_last_taken", "medication_length_of_time", "medication_willing_stop"],
                                  "med_hist_rx"
                              )
                            : null}

                        {answers["otc_medications"] && answers["otc_medications"].answer.value === "yes"
                            ? medCards(
                                  "Over-the-counter medications taken in the last 12 months",
                                  answers["recent_otc"],
                                  "otc_name",
                                  ["Reason", "Date last taken"],
                                  ["otc_reason", "otc_date_last_taken"],
                                  "med_hist_otc"
                              )
                            : null}

                        <div className="w-full border-b border-solid border-gray-300 my-10"></div>
                        {conditionalQuestion(answers["vitamins_supplements"], answers["vitamin_supplement_history"], false, null, "Vitamins or supplements")}

                        <div className="w-full border-b border-solid border-gray-300 my-10"></div>
                        <div className="mb-5">
                            <div className="flex items-center">
                                <div className="font-semibold">Diet</div>
                            </div>

                            <div>
                                {answers["diet"].answer.value === "other"
                                    ? formatString({ answer: answers["diet_type_other"].answer.value })
                                    : answers["diet"].answer.value === "meat_eater_non_vegetarian"
                                    ? formatString({ answer: "Meat eater/non-vegetarian" })
                                    : formatString({ answer: answers["diet"].answer.value })}
                            </div>
                        </div>

                        <div className="w-full border-b border-solid border-gray-300 my-10"></div>
                        {conditionalQuestion(answers["have_you_ever_had_anesthesia"], answers["anesthesia_complications_reason"], false, null, "Anesthesia history")}

                        {answers["surgical_history"] ? <div className="w-full border-b border-solid border-gray-300 my-10"></div> : null}
                        {numberedTable("Surgical history", answers["surgical_history"], ["Surgery", "Date", "Age", "Reason"], ["surgery_name", "surgery_date", "surgery_age", "surgery_reason"])}

                        <div className="w-full border-b border-solid border-gray-300 my-10"></div>
                        {conditionalQuestion(answers["vaccinations"], answers["vaccination_history"], false, null, "Vaccines or immunizations within the last 12 months?")}
                        {conditionalQuestion(answers["covid_vax"], answers["covid_vax_details"], false, null, "Have you had any COVID-19 vaccinations, and if so when?")}

                        {answers["serious_medical_problems"] ? <div className="w-full border-b border-solid border-gray-300 my-10"></div> : null}
                        {numberedTable(
                            "Hospitalizations, surgeries, or institutionalizations that have occured in the past 2 years",
                            answers["serious_medical_problems"],
                            ["Diagnosis", "Age", "Treatment"],
                            ["serious_diagnosis", "age_serious_diagnosis", "treatment_serious_diagnosis"]
                        )}

                        <div className="w-full border-b border-solid border-gray-300 my-10"></div>
                        {conditionalQuestion(answers["blood_donation"], answers["blood_donation_refusal_details"], false, null, "Have you ever been refused as a blood donor or told not to donate?")}

                        {conditionalQuestion(answers["blood_transfusion"], answers["blood_transfusion_details"], false, null, "Have you received a blood transfusion or any blood products?")}

                        {answers["eating_disorders"] ? <div className="w-full border-b border-solid border-gray-300 my-10"></div> : null}
                        {numberedTable(
                            "Eating disorders",
                            answers["eating_disorders"],
                            ["Diagnosis", "Age of diagnosis", "Applicable treatment"],
                            ["ed_diagnosis", "ed_age_diagnosed", "ed_treatments"]
                        )}

                        <div className="w-full border-b border-solid border-gray-300 my-10"></div>
                        {conditionalQuestion(
                            answers["toxic_substance"],
                            answers["toxic_substance_details"],
                            false,
                            null,
                            "Have you ever been exposed to a substance that may be transferred in toxic doses, such as lead, mercury, or gold?"
                        )}

                        <div className="w-full border-b border-solid border-gray-300 my-10"></div>
                        {conditionalQuestion(
                            answers["occupational_exposure"],
                            answers["occupational_exposure_details"],
                            false,
                            null,
                            "Have you ever been in an occupation with increased risk of radiation or chemical exposure?"
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const famMedHistSection = () => {
        return (
            <div id="phys-attr" class="py-10 break-inside-avoid">
                <div class="grid-cols-12 lg:grid block gap-6">
                    <div class="col-span-4 col-start-1">
                        <div class="flex items-center">
                            <img className="mr-2" height="50" width="50" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/profile/personality_lightbulb.svg`} />
                            <h2 className="w-full text-left">
                                <span class="heading-underline text-left">Family medical history</span>
                            </h2>
                        </div>
                    </div>
                    <div class="col-span-8 col-start-5 mt-3 relative">
                        <div class="flex flex-col gap-4 mt-10 md:mt-0">
                            {Object.keys(famMedHist).map((key) => {
                                const skip = {
                                    birth_defect: true,
                                    cancer: true,
                                    ears_hearing: true,
                                    gastro: true,
                                    genetic_dd: true,
                                    genital_repro: true,
                                    heart_blood: true,
                                    mental_hlth_dev: true,
                                    metabolic_endocrine: true,
                                    muscle_joints: true,
                                    neuro: true,
                                    other_disease: true,
                                    respiratory: true,
                                    skin: true,
                                    visual_dd: true,
                                    anything_else: true,
                                };
                                if (skip[key]) {
                                    return null;
                                }
                                return (
                                    <div class="p-6 rounded-md border border-neutral-400 flex flex-col gap-6 break-inside-avoid">
                                        <div className="flex items-center justify-between">
                                            <h4 class="mb-0">{famMedHist[key].question.label}</h4>
                                        </div>
                                        {Array.isArray(famMedHist[key].answer.value)
                                            ? famMedHist[key].answer.value.map((y, i) => {
                                                  if (key === "death_prior_50") {
                                                      return (
                                                          <div>
                                                              <div className="w-full border-b border-solid border-gray-300 mb-5"></div>
                                                              <div class="md:grid md:grid-cols-3 gap-4 w-full">
                                                                  <div class="flex flex-col gap-y-1 mb-4">
                                                                      <p class="text-sm col-span-2">Who</p>
                                                                      <p class="font-bold col-span-2">{formatString({ answer: y.person })}</p>
                                                                  </div>
                                                                  <div class="flex flex-col gap-y-1 mb-4">
                                                                      <p class="text-sm col-span-2">Age of death</p>
                                                                      <p class="col-span-2">{formatString({ answer: y.age_of_death })}</p>
                                                                  </div>
                                                                  <div class="flex flex-col gap-y-1 mb-4">
                                                                      <p class="text-sm col-span-2">Cause of death</p>
                                                                      <p class="col-span-2">{formatString({ answer: y.cause_of_death })}</p>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      );
                                                  }

                                                  return (
                                                      <div>
                                                          <div className="w-full border-b border-solid border-gray-300 mb-5"></div>
                                                          <div class="md:grid md:grid-cols-3 gap-4 w-full">
                                                              <div class="flex flex-col gap-y-1 mb-4">
                                                                  <p class="text-sm col-span-2">Who</p>
                                                                  <p class="font-bold col-span-2">{formatString({ answer: y.person })}</p>
                                                              </div>
                                                              <div class="flex flex-col gap-y-1 mb-4">
                                                                  <p class="text-sm col-span-2">Age Diagnosed</p>
                                                                  <p class="col-span-2">{formatString({ answer: y.diagnosis_age })}</p>
                                                              </div>
                                                              <div class="flex flex-col gap-y-1 mb-4">
                                                                  <p class="text-sm col-span-2">Treatment/Additional info</p>
                                                                  <p class="col-span-2">{formatString({ answer: y.treatments })}</p>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  );
                                              })
                                            : famMedHist[key].answer.value}
                                    </div>
                                );
                            })}
                            {famMedHist["anything_else"] ? (
                                <div class="p-6 rounded-md border border-neutral-400 flex flex-col gap-6">
                                    <div className="flex items-center justify-between">
                                        <h4 class="mb-0">{famMedHist["anything_else"].question.label}</h4>
                                    </div>
                                    <div>
                                        <div className="w-full border-b border-solid border-gray-300 mb-5"></div>
                                        <div class="w-full">
                                            <div class="flex flex-col gap-y-1 mb-4">
                                                <p class="font-bold col-span-2">{formatString({ answer: famMedHist["anything_else"].answer.value })}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const medCards = (label, answer, titleKey, columns, valueKeys, iconName) => {
        return (
            <div className="break-inside-avoid">
                <div className="w-full border-b border-solid border-gray-300 my-10"></div>
                <div className="flex items-center">
                    <div className="font-semibold">{label}</div>
                </div>
                <div class="flex overflow-x-auto mt-5">
                    <div className="grid grid-cols-2">
                        {answer.answer.value.map((d) => (
                            <div class="w-card grid grid-cols-1 grid-rows-1 rounded-md border border-neutral-400 p-5 m-4 flex-none">
                                <img
                                    className="row-start-1 row-end-1 col-start-1 col-end-1 justify-self-end -mr-3 -mt-3"
                                    height="40"
                                    width="40"
                                    alt="FreezeByCo logo"
                                    src={`${process.env.PUBLIC_URL}/images/profile/${iconName}.svg`}
                                />
                                <div class="row-start-1 row-end-1 col-start-1 col-end-1 flex flex-col gap-y-4">
                                    <h4 class="pr-10 mb-0">{d[titleKey]}</h4>
                                    {columns.map((c, i) => {
                                        if (d[valueKeys[i]]) {
                                            return (
                                                <div>
                                                    <header class="text-xs">{c}</header>
                                                    <p class="text-sm">{formatString({ answer: d[valueKeys[i]] })}</p>
                                                </div>
                                            );
                                        }

                                        return null;
                                    })}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    const numberedTable = (title, answer, columns, valueKeys) => {
        if (!answer || !answer.answer) {
            return;
        }

        return (
            <div className="break-inside-avoid">
                <div className="flex items-center">
                    <div className="font-semibold mb-5">{title}</div>
                </div>
                {answer.answer.value.map((d, i) => {
                    return (
                        <div className="md:flex md:w-full">
                            <h1 className="mr-5 ">0{i + 1}.</h1>
                            <div className="md:grid md:grid-cols-3 md:justify-between mb-5 md:w-full">
                                {columns.map((c, j) => {
                                    return (
                                        <div className="md:col-span-1 flex md:block align-top mb-5 md:mr-10 items-center">
                                            <div className="text-sm font-medium text-left mr-3 md:mr-0">
                                                {c}
                                                <span className="md:hidden">:</span>
                                            </div>
                                            <div className="text-left">{d[valueKeys[j]] ? formatString({ answer: d[valueKeys[j]] }) : "NA"}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    const finalAcknowledgementsSection = () => {
        if (!forClinic) {
            return null;
        }

        return (
            <div id="phys-attr" class="py-10 break-inside-avoid">
                <div class="grid-cols-12 lg:grid block gap-6">
                    <div class="col-span-4 col-start-1">
                        <div class="flex items-center">
                            <img className="mr-2" height="50" width="50" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/profile/personality_lightbulb.svg`} />
                            <h2 className="w-full text-left">
                                <span class="heading-underline text-left">Final Acknowledgements</span>
                            </h2>
                        </div>
                    </div>
                    <div class="col-span-8 col-start-5 mt-3 relative">
                        <div class="flex flex-col lg:grid grid-cols-8 w-3/4 gap-y-4 mt-10 md:mt-0">
                            <div class="col-span-4">
                                <div class="flex flex-col my-auto gap-y-4">
                                    {attributeValueColumn("Preferred recipient families", answers["recipient_families"], false, null, false)}
                                    {attributeValueColumn("Communication preferences", answers["communication_preferences"], false, null, false)}
                                    {attributeValueColumn("Relationship preferences", answers["relationship_preferences"], false, null, false)}
                                    {attributeValueColumn("Understand impact of donation", answers["donation_awareness"], false, null, false)}
                                    {attributeValueColumn("Genetic disclosure", answers["genetic_disclosure"], false, null, false)}
                                    {attributeValueColumn("Second round interest", answers["program_acknowledgement"], false, null, false)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const getGrandparentStatus = (name) => {
        const isLiving = answers[`${name}_living`].answer.value;
        if (isLiving === "not_sure") {
            return "Unknown";
        } else if (isLiving === "yes") {
            return answers[`${name}_age`].answer.value;
        }

        return `Deceased - ${answers[`${name}_death_age`].answer.value}`;
    };

    const singleAttributeValueColumn = (labelOverride, answer, group = false, valOverride = null, icon = null) => {
        if (!answer) {
            return null;
        }

        return (
            <div class="flex justify-between w-full md:grid md:grid-cols-2 text-sm md:w-1/2 ">
                <div class="md:col-span-1 text-left font-semibold">{labelOverride}</div>
                <div class="md:col-span-1 flex items-center">
                    {icon ? (
                        <img
                            srcSet={`${process.env.PUBLIC_URL}/images/profile/${valOverride ? valOverride : formatString({ answer: answer.answer.value })}.svg`}
                            className="inline-block w-3 h-3 mx-2"
                            alt={`${valOverride ? valOverride : formatString({ answer: answer.answer.value })}-eye`}
                        />
                    ) : null}
                    <div class="text-right md:text-left">{valOverride ? valOverride : formatString({ answer: answer.answer.value })}</div>
                </div>
            </div>
        );
    };

    const attributeValueColumn = (labelOverride, answer, group = false, valOverride = null, icon = false) => {
        return (
            <div class="grid grid-cols-2 gap-x-6 text-sm">
                <div class="col-span-1 text-left font-semibold">{labelOverride}</div>
                <div class="col-span-1 flex items-center">
                    {icon && !forClinic ? (
                        <img
                            srcSet={`${process.env.PUBLIC_URL}/images/profile/${valOverride ? valOverride : answer.answer.value}.svg`}
                            className="inline-block w-3 h-3 mx-2"
                            alt={`${valOverride ? valOverride : answer.answer.value}-eye`}
                        />
                    ) : null}
                    <div class="text-left whitespace-pre-line">{answer ? formatString({ answer: valOverride ? valOverride : answer.answer.value }) : "Empty"}</div>
                </div>
            </div>
        );
    };

    const calculateAge = (dateOfBirth) => {
        if (!dateOfBirth) {
            return "";
        }

        const today = new Date();
        const birthDate = new Date(dateOfBirth);

        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();

        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    };

    if (!user) {
        return null;
    }

    const setPhotoMargin = (i) => {
        if (i === 0) {
            return "mb-1 mr-1";
        } else if (i === 1) {
            return "mb-1";
        } else if (i === 2) {
            return "mr-1";
        }

        return "";
    };

    const imageCarousel = () => {
        let photosToRender = [];
        if (!photos.length) {
            photosToRender = [
                <div key={0} className={`flex-shrink-0 w-full block`}>
                    <div
                        className="bg-gray-200"
                        style={{
                            width: 400,
                            height: 400,
                            backgroundSize: "cover",
                            backgroundPosition: "top",
                        }}
                    ></div>
                </div>,
            ];
        } else {
            photosToRender = photos.map((p, i) => (
                <div key={i} className={`flex-shrink-0 w-full ${i === activeCarouselIndex ? "block" : "hidden"}`}>
                    <div
                        style={{
                            backgroundImage: `url("${p.cropped_source ? p.cropped_source : p.source}")`,
                            width: 400,
                            height: 400,
                            backgroundSize: "cover",
                            backgroundPosition: "top",
                        }}
                    ></div>
                </div>
            ));
        }

        return (
            <div className="relative md:hidden">
                <div {...swipeHandlers} className="flex overflow-x-auto">
                    {photosToRender}
                </div>
            </div>
        );
    };

    const imageGrid = () => {
        let headerImage = [];
        let gridImages = [];

        if (!photos.length) {
            headerImage = [
                <div
                    className="mr-1 bg-gray-200"
                    style={{
                        height: 500,
                        gridRowStart: null,
                        gridColumnStart: null,
                        backgroundSize: "cover",
                        backgroundPosition: "top",
                    }}
                ></div>,
            ];
            gridImages = [0, 1, 2, 3].map((p, i) => (
                <div
                    className={`${setPhotoMargin(i)} bg-gray-200`}
                    style={{
                        gridRowStart: null,
                        gridColumnStart: null,
                        backgroundSize: "cover",
                        backgroundPosition: "top",
                    }}
                ></div>
            ));
        } else if (photos.length === 1) {
            headerImage = [
                <div
                    className="mr-1"
                    style={{
                        backgroundImage: `url("${photos[0].cropped_source ? photos[0].cropped_source : photos[0].source}")`,
                        height: 500,
                        gridRowStart: null,
                        gridColumnStart: null,
                        backgroundSize: "cover",
                        backgroundPosition: "top",
                    }}
                ></div>,
            ];
            gridImages = [0, 1, 2, 3].map((p, i) => (
                <div
                    className={`${setPhotoMargin(i)} bg-gray-200`}
                    style={{
                        gridRowStart: null,
                        gridColumnStart: null,
                        backgroundSize: "cover",
                        backgroundPosition: "top",
                    }}
                ></div>
            ));
        } else if (photos.length > 1) {
            headerImage = [
                <div
                    className="mr-1"
                    style={{
                        backgroundImage: `url("${photos[0].cropped_source ? photos[0].cropped_source : photos[0].source}")`,
                        height: 500,
                        gridRowStart: null,
                        gridColumnStart: null,
                        backgroundSize: "cover",
                        backgroundPosition: "top",
                    }}
                ></div>,
            ];
            gridImages = photos.slice(1).map((p, i) => (
                <div
                    className={`${setPhotoMargin(i)}`}
                    style={{
                        backgroundImage: `url("${p.cropped_source ? p.cropped_source : p.source}")`,
                        gridRowStart: null,
                        gridColumnStart: null,
                        backgroundSize: "cover",
                        backgroundPosition: "top",
                    }}
                ></div>
            ));

            const gridDiff = 4 - gridImages.length;
            for (let i = 0; i < gridDiff; i++) {
                gridImages.push(
                    <div
                        className={`${setPhotoMargin(i + gridDiff)} bg-gray-200`}
                        style={{
                            gridRowStart: null,
                            gridColumnStart: null,
                            backgroundSize: "cover",
                            backgroundPosition: "top",
                        }}
                    ></div>
                );
            }
        }

        return (
            <div className="hidden md:grid grid-cols-2 h-full relative z-10">
                {headerImage}
                <div className="grid grid-cols-2 grid-rows-2">{gridImages}</div>
            </div>
        );
    };

    const handleCarouselNext = () => {
        if (!photos.length) {
            return;
        }

        setActiveCarouselIndex((prev) => (prev === photos.length - 1 ? 0 : prev + 1));
    };

    const handleCarouselPrev = () => {
        if (!photos.length) {
            return;
        }

        setActiveCarouselIndex((prev) => (prev === 0 ? photos.length - 1 : prev - 1));
    };

    return (
        <div>
            <div id="profile" class="flex flex-col">
                <div class={`${forClinic ? "bg-red-500" : "bg-yellow-400"} w-full sticky top-0 z-20 h-16 grid grid-cols-3 print:hidden items-center`}>
                    {forClinic ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="ml-4 bi bi-exclamation-diamond-fill" viewBox="0 0 16 16">
                            <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                        </svg>
                    ) : (
                        <div></div>
                    )}
                    <div className="flex justify-center items-center">
                        {forClinic ? (
                            <h4 className="text-center">
                                You are viewing the admin PDF. Take care not to send this to an IP as it contains very sensitive personal information! Print to save as PDF to preview and save.
                            </h4>
                        ) : (
                            <h4>You are viewing the IP PDF. Print to save as PDF to preview and save.</h4>
                        )}
                    </div>
                    {forClinic ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="ml-4 bi bi-exclamation-diamond-fill justify-self-end mr-4" viewBox="0 0 16 16">
                            <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                        </svg>
                    ) : (
                        <div></div>
                    )}
                </div>
                <div class={`bg-[#bbadd5] flex justify-center`}>
                    <div class="w-full lg:px-6 xl:px-0 md:w-3/4">
                        <div class="bg-white lg:shadow-hard lg:rounded-lg lg:mt-10 overflow-hidden">
                            <section style={{ zIndex: 10 }}>
                                {imageGrid()}
                                {imageCarousel()}
                            </section>
                            {forClinic ? (
                                <div className="text-xs pt-4">
                                    <h4>Confidentiality notice</h4>
                                    <p className="py-1">
                                        This document contains confidential and proprietary information that is intended solely for the use of the recipient. The information within this profile is
                                        provided for the exclusive purpose of assisting in the evaluation of potential donors and is not to be disclosed, shared, or distributed in any form without the
                                        explicit written consent of Cofertility. Unauthorized use or disclosure of this information is strictly prohibited and may result in legal action.
                                    </p>
                                    <p className="py-1">
                                        By accessing this document, you agree to maintain the confidentiality of all information contained herein and acknowledge that any unauthorized use or
                                        distribution is a violation of this agreement and applicable privacy laws. Please ensure this document is kept secure and confidential at all times.
                                    </p>
                                    <p className="py-1">If you are not the intended recipient, please notify us immediately and destroy any copies of this document in your possession.</p>
                                </div>
                            ) : null}
                            <h1 class="mb-2 pt-1">
                                <div className="flex justify-between items-baseline">
                                    <div>
                                        <span class="heading-underline">{forClinic ? `[${user.donorCode}] ${user.firstName} ${user.lastName}` : user.donorCode}</span>
                                    </div>
                                    {forClinic ? <span className="mr-2 text-xl">{user.email}</span> : null}
                                </div>
                            </h1>
                            <div class="grid grid-cols-2 gap-x-6 mt-5">
                                <section class="">
                                    <div className={`grid grid-cols-12 ${forClinic ? "py-1" : "py-4"}`}>
                                        <p className="col-span-5 text-sm font-semibold">Education</p>
                                        <p className="col-span-7 text-sm text-left">{formatString({ answer: answers["education_level"].answer.value })}</p>
                                    </div>
                                    <div className={`grid grid-cols-12 ${forClinic ? "py-1" : "py-4"}`}>
                                        <p className="col-span-5 text-sm font-semibold">Race/Identity</p>
                                        <p className="col-span-7 text-sm text-left">
                                            {answers["race"] ? answers["race"].answer.value.map((r) => RaceMap[r]).join(", ") : <span className="italic text-gray-300 font-normal">Empty</span>}
                                        </p>
                                    </div>
                                    <div className={`grid grid-cols-12 ${forClinic ? "py-1" : "py-4"}`}>
                                        <p className="col-span-5 text-sm font-semibold">Father's heritage</p>
                                        <p className="col-span-7 text-sm text-left">
                                            {answers["fathers_ethnicities"] ? (
                                                answers["fathers_ethnicities"].answer.value.map((e) => EthnicitiesMap[e.ethnicity]).join(", ")
                                            ) : (
                                                <span className="italic text-gray-300 font-normal">Empty</span>
                                            )}
                                        </p>
                                    </div>
                                    <div className={`grid grid-cols-12 ${forClinic ? "py-1" : "py-4"}`}>
                                        <p className="col-span-5 text-sm font-semibold">Mother's heritage</p>
                                        <p className="col-span-7 text-sm text-left">
                                            {answers["mothers_ethnicities"] ? (
                                                answers["mothers_ethnicities"].answer.value.map((e) => EthnicitiesMap[e.ethnicity]).join(", ")
                                            ) : (
                                                <span className="italic text-gray-300 font-normal">Empty</span>
                                            )}
                                        </p>
                                    </div>
                                    <div className="flex flex-col py-1">
                                        <div className="md:flex items-center col-span-2 ">
                                            <p className="w-1/2 text-sm mr-5 font-semibold">Age</p>
                                            <p className="w-3/4 text-sm">{calculateAge(user.dateOfBirth)}</p>
                                        </div>
                                    </div>
                                    <div className={`grid grid-cols-12 ${forClinic ? "py-1" : "py-4"}`}>
                                        <p className="col-span-5 text-sm font-semibold">Height</p>
                                        <p className="col-span-7 text-sm text-left">{`${answers["height_ft"].answer.value}'${answers["height_in"].answer.value}"`}</p>
                                    </div>
                                    <div className={`grid grid-cols-12 ${forClinic ? "py-1" : "py-4"}`}>
                                        <p className="col-span-5 text-sm font-semibold">Weight</p>
                                        <p className="col-span-7 text-sm text-left">{`${answers["weight"].answer.value} lbs`}</p>
                                    </div>
                                </section>
                                <section>
                                    <div className={`grid grid-cols-12 ${forClinic ? "py-1" : "py-4"}`}>
                                        <p className="col-span-5 text-sm font-semibold">Eye color</p>
                                        <p className="col-span-7 text-sm text-left">{formatString({ answer: answers["eye_color"].answer.value })}</p>
                                    </div>
                                    <div className={`grid grid-cols-12 ${forClinic ? "py-1" : "py-4"}`}>
                                        <p className="col-span-5 text-sm font-semibold">Hair color</p>
                                        <p className="col-span-7 text-sm text-left">{formatString({ answer: answers["hair_color"].answer.value })}</p>
                                    </div>
                                    <div className={`grid grid-cols-12 ${forClinic ? "py-1" : "py-4"}`}>
                                        <p className="col-span-5 text-sm font-semibold">Relationship</p>
                                        <p className="col-span-7 text-sm text-left">
                                            {answers["relationship_preferences"]
                                                ? formatString({
                                                      answer:
                                                          answers["relationship_preferences"].answer.value === "either"
                                                              ? "Either disclosed or undisclosed"
                                                              : answers["relationship_preferences"].answer.value,
                                                  })
                                                : "Empty"}
                                        </p>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-white-100 mt-4 break-inside-avoid">
                    <div className="mt-10 pb-10 px-10 break-inside-avoid">
                        <img className="mx-auto" height="72" width="72" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/profile/profile-heart-message.svg`} />
                        <div className="flex justify-center mt-3">
                            <h1 className="text-center heading-underline-forest-50">About {user.donorCode}</h1>
                        </div>
                        {user.freeze_member.profile_bio ? <div className="text-center">{user.freeze_member.profile_bio}</div> : null}
                        {user.freeze_member.match_preferences ? <div className="text-center mt-4">{formatString({ answer: user.freeze_member.match_preferences })}</div> : null}
                        {user.freeze_member.travel_availability ? <div className="text-center mt-4">{formatString({ answer: user.freeze_member.travel_availability })}</div> : null}
                    </div>

                    <div className="grid grid-cols-2 mx-auto w-5/6">
                        {photos.map((p, i) => (
                            <div
                                className={`m-1 break-inside-avoid h-[500px] print:h-[300px]`}
                                style={{
                                    backgroundImage: `url("${p.cropped_source ? p.cropped_source : p.source}")`,
                                    gridRowStart: null,
                                    gridColumnStart: null,
                                    backgroundSize: "cover",
                                    backgroundPosition: "top",
                                }}
                            ></div>
                        ))}
                    </div>

                    <div className="mx-auto w-5/6">{generalQuestionsSection()}</div>
                    {forClinic ? <div className="w-full border-b border-solid border-black my-4"></div> : null}

                    <div ref={persRef} className={`mx-auto w-5/6`}>
                        {personalitySection()}
                    </div>
                    <div className="w-full border-b border-solid border-black my-4"></div>

                    <div ref={physAttrsRef} className={`mx-auto w-5/6`}>
                        {physicalAttributesSection()}
                    </div>
                    <div className="w-full border-b border-solid border-black my-4"></div>

                    <div ref={eduWorkRef} className={`mx-auto w-5/6`}>
                        {eduWorkSkillsSection()}
                    </div>
                    <div className="w-full border-b border-solid border-black my-4"></div>

                    <div ref={eduWorkRef} className={`mx-auto w-5/6`}>
                        {lifestyleSection()}
                    </div>
                    {forClinic ? <div className="w-full border-b border-solid border-black my-4"></div> : null}

                    <div ref={famAttrRef} className={`mx-auto w-5/6`}>
                        {familyAttributesSection()}
                    </div>
                    <div className="w-full border-b border-solid border-black my-4"></div>

                    <div ref={fertRef} className={`mx-auto w-5/6`}>
                        {fertilitySection()}
                    </div>
                    <div className="w-full border-b border-solid border-black my-4"></div>

                    <div ref={medHistRef} className={`mx-auto w-5/6`}>
                        {medicalHistory()}
                    </div>
                    <div className="w-full border-b border-solid border-black my-4"></div>

                    <div ref={famMedHistRef} className={`mx-auto w-5/6`}>
                        {famMedHistSection()}
                    </div>
                    <div className="w-full border-b border-solid border-black my-4"></div>

                    <div ref={famMedHistRef} className={`mx-auto w-5/6`}>
                        {finalAcknowledgementsSection()}
                    </div>
                </div>
            </div>

            <div className="fixed bottom-0 left-0 w-full flex text-black px-4 py-4 font-normal">
                <div>{forClinic ? `[${user.donorCode}] ${user.firstName} ${user.lastName}` : `[${user.donorCode}]`}</div>
            </div>
        </div>
    );
};

export default PDFProfile;
