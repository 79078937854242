import React, { useContext } from "react";
import { Link, Outlet } from "react-router-dom";
import { StaffSplitMemberPageContext } from "./SplitMemberPage";

const Activity = () => {
    const { user } = useContext(StaffSplitMemberPageContext);

    const determineSubtab = () => {
        if (window.location.pathname.includes("views")) {
            return "views";
        }

        return "favorites";
    };

    return (
        <div className="pb-5">
            <div className={`bg-white w-full pb-5`}>
                <div className="ml-30 flex flex-row w-1/2 pt-5">
                    <Link
                        to={`/staff/split-members/${user.id}/activity/favorites`}
                        className={`${
                            determineSubtab() === "favorites" ? "border-secondary" : "border-transparent"
                        } text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                    >
                        FAVORITES
                    </Link>
                    <Link
                        to={`/staff/split-members/${user.id}/activity/views`}
                        className={`${
                            determineSubtab() === "views" ? "border-secondary" : "border-transparent"
                        } text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                    >
                        VIEWS
                    </Link>
                </div>
            </div>

            <div>
                <Outlet />
            </div>
        </div>
    );
};

export default Activity;
