import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IPDashboardContext } from "../intendedParent/Dashboard";

const FAQ = () => {
    const [selected, setSelected] = useState([]);
    const navigate = useNavigate();
    const { setCurrentPage } = useContext(IPDashboardContext);

    useEffect(() => {
        document.title = "Cofertility | FAQ";
        setCurrentPage("faq");
    });

    const toggleSelected = (num) => {
        const filteredSelected = structuredClone(selected).filter((selected) => selected !== num);

        if (!selected.includes(num)) {
            filteredSelected.push(num);
        }

        setSelected(filteredSelected);
    };

    const goBack = (path) => {
        navigate(path);
    };

    return (
        <div className="pb-20 bg-freeze-light-dashboard bg-cover bg-no-repeat bg-center">
            <div className={`px-6 grow flex flex-col`}>
                <div id="faq" className="container mx-auto my-auto">
                    <div className="mt-6 mb-6">
                        <div onClick={() => goBack("/family/donors")} className="flex gap-x-2 items-center !no-underline cursor-pointer">
                            <span className="h-5 w-5">
                                <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M3.5711 15.4322C7.90888 15.4322 11.4356 11.9056 11.4356 7.56777C11.4356 7.25306 11.6886 7 12.0033 7C12.318 7 12.5711 7.25306 12.5711 7.56777C12.5711 10.9452 10.6991 13.8944 7.94132 15.4322H28.5758C28.8905 15.4322 29.1436 15.6853 29.1436 16C29.1436 16.3147 28.8905 16.5678 28.5758 16.5678H7.93808C10.6991 18.1056 12.5679 21.0548 12.5679 24.4322C12.5679 24.7469 12.3148 25 12.0001 25C11.6854 25 11.4323 24.7469 11.4323 24.4322C11.4323 20.0944 7.90563 16.5678 3.56785 16.5678C3.25315 16.5678 3.00008 16.3147 3.00008 16C3.00008 15.6853 3.25315 15.4322 3.56785 15.4322H3.5711Z"
                                        fill="currentColor"
                                    ></path>
                                </svg>
                            </span>
                            <span className="!no-underline">Back</span>
                        </div>
                    </div>

                    <h1 className="mb-1">
                        <span className="heading-underline">Frequently asked questions</span>
                    </h1>

                    <div className="mb-6">
                        <p>If you have any questions at all, please don't hesitate to email. We're here to help.</p>
                        <p>
                            <a href="mailto:support@cofertility.com" rel="noopener noreferrer" target="_blank">
                                support@cofertility.com
                            </a>
                        </p>
                    </div>

                    <div>
                        <div className="mb-12">
                            <h2>Matching process</h2>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(1)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        Where do I start?
                                        <span className={`${selected.includes(1) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(1) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        The first step is to fill out a short form in which you share more about your family and what you’re looking for in an egg donor. You’ll then gain immediate, free access to our matching platform and can start browsing for your perfect match. When you’re ready to begin the process with a specific donor, you can place a hold on a match for up to seven days.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(2)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        What kinds of intended parents do you work with?
                                        <span className={`${selected.includes(2) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(2) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        We believe the concept of “family” takes many forms, and we’re here to help your family grow, whatever that looks like. So regardless of your gender identity, sexual orientation, or relationship status, we do not discriminate, period.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(3)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        What is egg sharing?
                                        <span className={`${selected.includes(3) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(3) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        In traditional egg donation, all of the eggs retrieved in a given cycle are given to the intended parents in exchange for payment. However, in egg sharing, the donor has the opportunity to keep half of the eggs retrieved for their own future family planning in exchange for donating the other half to your family.
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        At Cofertility, we exclusively work under the egg sharing model, which we call Split, because we think it’s better for everyone involved – the intended parents, the egg donor, and ultimately the donor-conceived child.
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Read more about the <a href="https://www.cofertility.com/family-learn/six-reasons-why-egg-sharing-is-an-ideal-egg-donation-model-for-intended-parents" title="Reasons Why Egg Sharing is an Ideal Egg Donation Model for Intended Parents">Reasons Why Egg Sharing is an Ideal Egg Donation Model for Intended Parents</a>
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(4)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        Tell me about your Baby Guarantee
                                        <span className={`${selected.includes(4) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(4) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        While some agencies require you to pay extra to receive any form of a guarantee, Cofertility’s baby guarantee is built into our pricing. We want to help you bring your baby home, and we’ll re-match you for free until that happens.
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        What does this mean? If the donor doesn’t clear her screening… we’ll rematch you. If the cycle doesn’t lead to any blastocysts… we’ll rematch you. If none of the blastocysts turn into a pregnancy… we’ll rematch you.
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        In short, if at any point along your egg donation journey, something out of your control prevents you from bringing your baby home, we’ll re-match you for free or we’ll offer you a full or partial refund if you do not move forward with another donor.
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        To read more about Cofertility’s Baby Guarantee, including how it applies to our fresh and frozen egg donation programs, <a href="https://www.cofertility.com/family-learn/cofertilitys-egg-donation-baby-guarantee" title="Cofertility's Baby Guarantee">click here</a>.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(5)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        Where is Cofertility based?
                                        <span className={`${selected.includes(5) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(5) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        We work with anyone who is looking to build a family through egg donation, in the U.S. or anywhere in the world. Our donors are primarily based in the U.S., and we are happy to work with intended parents around the globe, depending on your country’s laws around third-party reproduction.
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        When it comes to our team, we collaborate remotely and are distributed across the U.S.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(6)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        How do I place a hold on a match?
                                        <span className={`${selected.includes(6) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(6) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        When you’ve found a donor you want to move forward with, press the “Hold match” button at the top of their profile. From there, you’ll pay a $500 Match Initiation Fee to place a hold on that match for the next seven days. Placing a hold removes a donor from the platform and prevents them from matching with any other intended parents. Within 24 business hours, your Member Advocate will reach out to set up a kickoff call and discuss next steps. To proceed with the donor, you must confirm your decision and pay the full match fee within the 7-day hold period.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(7)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        How long does the matching process take?
                                        <span className={`${selected.includes(7) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(7) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        When you match with a donor in our fresh egg donation program, the process from match to cycle may generally be two to four months. Once you create a free account, you’ll gain immediate access to our matching platform and can start to learn more about our donors. When you’re ready to begin the matching process, you can place a hold on the match. As soon as the mutual match is confirmed, we can begin the process of scheduling her screening and testing with your clinic, which generally takes a few weeks. Once your doctor gives the go-ahead, we’d set a date for the cycle at a time that is mutually convenient.
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Matching with a donor in our frozen program can provide a unique opportunity to move forward with your family-building plans even faster, as frozen eggs can be fertilized or shipped to your clinic immediately. Time from match to cycle can be a lot faster with frozen since the screening – which is what takes the most time – is already completed. If you’re looking to transfer embryos ASAP, matching with a frozen donor can help make that happen.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(8)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        Does the donor have a say in the match?
                                        <span className={`${selected.includes(8) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(8) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Yes. Unlike others in the space, we pride ourselves on facilitating mutual matches that meet the needs of you, your family, and the donor. After you place a hold on a match, a member of our team will reach out to the donor to ensure that they have the opportunity to voice their preferences around disclosure status. Our hope is to facilitate a mutual match in which your and the donor’s disclosure preferences are aligned.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(9)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        What is the difference between fresh and frozen egg donation with Cofertility?
                                        <span className={`${selected.includes(9) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(9) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        With a <a href="https://www.cofertility.com/family-learn/an-intended-parents-guide-to-fresh-egg-donation-with-cofertility" title="Everything You Need to Know About Fresh Egg Donation">fresh donation</a> cycle, you match with the donor before she undergoes any screening. Her screening and retrieval will be done at a clinic of your choice. With a <a href="https://www.cofertility.com/family-learn/an-intended-parents-guide-to-frozen-egg-donation-with-cofertility" title="Everything You Need To Know About Frozen Egg Donation">frozen donation cycle</a>, the donor has already completed all of her screening and cycle. Her screening and retrieval will have been done at one of Cofertility’s partner clinics. You can either thaw and fertilize the eggs there or have them shipped to your clinic.
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Ultimately, the path that’s best for you depends on your timeline and family-building goals. To learn more about how to decide which path to pursue, <a href="https://www.cofertility.com/family-learn/cofertilitys-fresh-vs-frozen-egg-donation-program-which-is-right-for-me" title="Choosing Between Fresh and Frozen Egg Donation">check out this blog</a> we’ve written on the topic.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(10)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        What are my disclosure options?
                                        <span className={`${selected.includes(10) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(10) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        In a disclosed donation with Cofertility, you and the donor will exchange contact information and can communicate directly. In an undisclosed donation, you will not exchange contact information and only communicate via Cofertility. With an undisclosed donation, you could arrange to have the information available to your child down the road (this is sometimes called Open ID). 
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        We highly encourage disclosed donations as we’ve met with the US Donor Conceived Council and heard from the donor conceived community that disclosed is best for the donor conceived person. However, if you choose to move forward with an undisclosed donation after careful consideration, we will honor that decision.
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Read more in <a href="https://www.cofertility.com/family-learn/disclosed-vs-undisclosed-donation" title="What's the difference between Fresh and Frozen Egg Donation">Disclosed vs. Undisclosed Donation: What's the Difference?</a>
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(12)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        What relationship will I have with the donor?
                                        <span className={`${selected.includes(12) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(12) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Every relationship is unique and depends on the desires of all parties involved: you, the donor, and any future donor-conceived children. The extent of the relationship, including the point at which your contact information is shared and the frequency of communication, is up to both parties and will be determined during the agreement phase.
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        It’s important to separate your disclosure status from any potential relationship that you have with the donor. For example, you may have a disclosed donation, in which you share contact information but decide not to maintain any regular communication. 
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        In any case, more research shows the benefits of being open with children about their donor-conceived roots. As such, we encourage you to be open with your own children about their conception story. We share more about our stance on disclosure in this article.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(13)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        Will I meet the donor?
                                        <span className={`${selected.includes(13) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(13) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Regardless of your disclosure status, you and the donor may choose to meet as part of the match process. This meeting can take many forms. For example, you can meet over Zoom, over the phone, or in person. The meeting can be facilitated with or without sharing contact information. Some intended parents choose not to meet live but to instead share information about themselves via a letter, slides, or video to their donor. 
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        If you do meet “live,” we’ve put together a list of suggested questions and conversation starters that we’ll share with you and the donor before your meeting. Your Member Advocate will also join the call to help facilitate introductions and conversation. 
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        If you match with a donor in our fresh egg donation program, your match meeting can take place before she begins her screening. If you match with a donor in the frozen program and are both open to a meeting, it will take place after her retrieval. 
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Ultimately, we view a match meeting as a way to connect with the donor on a deeper level but there’s no right or wrong way to do this. If it’s something you’re interested in, we’ll work with you to do so in a way that feels good for you.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(14)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        Do you have anonymous donors?
                                        <span className={`${selected.includes(14) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(14) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        It is impossible to guarantee anonymity in egg donation. With widely available genetic testing and more state laws banning anonymous gamete donation, it’s increasingly likely that identity and shared genetics will be discovered. We ensure that our Split members (who will be freezing her eggs and donating half to your family) are aware of the possibility that, even in an undisclosed donation where contact information is not shared, donor-conceived children may eventually want to reach out with their own questions.
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Read more in <a href="https://www.cofertility.com/family-learn/why-we-dont-believe-in-anonymous-egg-donation" title="Does Anonymous Egg Donation Exist?">Why We Don’t Believe in Anonymous Egg Donation</a>
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(15)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        What if I don’t find a donor who is a good fit?
                                        <span className={`${selected.includes(15) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(15) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        We’re committing to working with donors who are as unique as you and your family, and hope we can find someone who is a great fit. There is no fee to access our matching platform and once you have an account, you can indicate specific characteristics or criteria that you’re looking for in a match. Sign up for emails so you can get notified, as we are constantly adding new members who may be a good match for you and your family. Still don’t see what you’re looking for in a donor? Send us an email at support@cofertility.com and we can discuss how we can help.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-12">
                            <h2>Screening and retrieval</h2>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(16)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        Are the eggs that I receive fresh or frozen?
                                        <span className={`${selected.includes(16) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(16) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        At Cofertility, we offer both fresh and frozen egg donation. Ultimately, for many intended parents, the choice between our fresh and frozen programs often comes down to finding the right donor. Both programs have their merits, and the best choice for you will depend on your specific circumstances, preferences, and the connection you feel with potential donors.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(17)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        Do I have to be working with a clinic already?
                                        <span className={`${selected.includes(17) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(17) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Nope! We’re excited to work with you regardless of where you are in the family-building journey. Whether you’re just starting the process or have an established relationship with a clinic, we’d love to walk the rest of the road with you. 
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(18)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        Can you refer me to a clinic?
                                        <span className={`${selected.includes(18) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(18) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Absolutely. While we do not have any exclusive clinic relationships, we pride ourselves on working with a range of clinics whose clinical teams deliver high quality, compassionate care. Get in touch and we’ll be happy to connect you with one in your area
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(19)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        Do you have medical advisors?
                                        <span className={`${selected.includes(19) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(19) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Yes! We work with an incredible team of medical advisors who keep us in-the-know of the latest outcomes-based research, regulatory updates, and best practices. You can learn about the members of our Medical Advisory Board on <a href="https://www.cofertility.com/about-us" title="About Cofertility">Our Story page</a>. 
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(20)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        When does the donor undergo genetic testing?
                                        <span className={`${selected.includes(20) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(20) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        For donors who are completing a fresh donation cycle, we will work with your clinic to run a genetic screening for your donor upon matching. Their results, along with the intended father or sperm source’s genetic testing results, will be evaluated by a genetic counselor to ensure there is no genetic issue or mismatch between the two.
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Donors who are completing a frozen donation cycle complete genetic carrier screening in advance of their retrieval. Once you place a match, we can share all of those records with you and your physician.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(21)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        Can you help me find a surrogate?
                                        <span className={`${selected.includes(21) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(21) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        While we don’t currently offer surrogacy matching, we can refer you to a reputable surrogacy agency who can help make this happen!
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(22)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        How many times can an individual donate?
                                        <span className={`${selected.includes(22) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(22) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        We follow ASRM guidelines that limit an individual to six donation cycles across her lifetime. 
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(23)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        Do you have any restrictions on the clinic that I work with?
                                        <span className={`${selected.includes(23) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(23) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Cofertility is clinic agnostic as long as the clinic reports outcomes to <a href="https://www.sart.org/" title="SART">SART</a> and has a <a href="https://www.cap.org/laboratory-improvement/accreditation/laboratory-accreditation-program" title="CAP">CAP</a> certified lab. Some clinics have specific requirements related to working with outside donors and have policies in place for how they handle those cases. If you already have a clinic in mind, <a href="https://www.cofertility.com/contact" title="Contact Cofertility">reach out</a> to our team and we can send a list of questions that we recommend you ask them before getting started.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(24)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        Will I need to make legal arrangements?
                                        <span className={`${selected.includes(24) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(24) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Yes, but we will help with this part. When you and the donor agree to move forward with your match, you’ll enter into a legal agreement designed to protect the rights of both parties. We can refer you to a lawyer with expertise in the fertility industry and our programs; they will draft and review the contract, ensuring you have no unanswered questions. While it’s likely faster to work with one of the attorneys we’ve worked with before, if you prefer, you may also use your own outside counsel, though we ask that he/she has experience dealing in fertility and egg donation.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(25)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        Will I get enough eggs?
                                        <span className={`${selected.includes(25) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(25) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        At Cofertility, we exclusively work under the egg sharing model. Rather than cash compensation, our donors keep half of the eggs retrieved and donate the other half to your family. We believe this model benefits everyone involved, particularly the donor-conceived child.
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Egg share programs like ours implement meticulous testing and medical screening to ensure donors have a high likelihood of success. For those who match with a donor undergoing a fresh donation cycle, the average number of mature eggs a family receives and fertilizes is 12. The number of eggs retrieved varies by patient and cycle, but can be predicted by a donor’s age, AMH, and antral follicle count, all of which will be known to you after the donor’s initial screening. Egg share donors also often work closely with a fertility doctor to determine, based on their own medical history, the optimal number of eggs needed for their own future family-building goals. Should it make sense for the donor, they may choose to pursue a second egg-sharing cycle to maximize the chances of success for everyone involved.
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Donors undergoing frozen donation cycles complete equally rigorous ovarian reserve testing. While the total number of frozen eggs available will vary based on the donor’s retrieval outcomes, every frozen match is guaranteed to have a minimum of at least 6 frozen eggs.
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        To learn more, <a href="https://cdn.prod.website-files.com/631b5af6b94fb3254c66e456/662c28d03e752b6c4d22096f_Egg%20Sharing%20Downloadable.pdf" title="How to know if I'll get enough eggs through egg sharing">click here</a> to download our "Egg Sharing: Will I get enough eggs?" guide.
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line italic">
                                        * Note: this number is updated periodically based on cycle outcomes. 
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(26)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        How does your process change for intended parents located outside of the United States?
                                        <span className={`${selected.includes(26) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(26) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        All Cofertility egg retrievals are conducted at fertility clinics in the US; as such, the donation process will be the same regardless of where you’re currently located. If you are not yet established for care at a clinic in the US, we can help you select a clinic local to your donor or in another city of your choice. After the retrieval process is complete and/or embryos are created, there may be options to ship them to clinics outside of the US. However, it's important to note that different clinics and countries have specific regulations governing the shipment and acceptance of frozen eggs and embryos. Before starting the process, be sure to reach out to us at match@cofertility.com with questions about your specific situation.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(27)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        Can you tell me more about the donor screening process?
                                        <span className={`${selected.includes(27) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(27) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Our cycles take place within the United States and as such, our team screens donors through a rigorous, multi-step process to ensure each person is qualified and prepared to become an egg donor, going above and beyond FDA and ASRM-recommended screening guidelines. Before donors can be listed on our platform, we review their personal and family medical history and collect information on their lifestyle and behavioral habits. We also obtain AMH results for most donors before they match. Most importantly, we meet 1:1 with every donor to get at the core of who she is as a person — her values, her goals, her personality. This allows us to provide you with the most thoughtful match possible. 
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        If you match with a donor available for a fresh cycle, the donor will undergo a physical examination, psychological evaluation, and full genetic screen after you match. Your doctor will review these results and give us the green light to move forward. We will also ensure that donors are screened for background and education checks.
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        If you match with a donor available for a frozen cycle, she will have completed a genetic carrier screen, psychological evaluation and third party background check in addition to all required infectious disease testing as required by the FDA in advance of her retrieval. Once you place a match, we can share all of those records with you and your physician.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-12">
                            <h2>Fresh egg donation</h2>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(28)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        If the donor is out of state, do I need to switch clinics to one near her?
                                        <span className={`${selected.includes(28) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(28) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        If you match with a donor available for a fresh donation cycle, we’ll work with both parties to determine the best place to conduct her testing, monitoring, and retrieval. In some cases, we will complete the donor’s testing and monitoring at a clinic close to her home to eliminate travel and can have the eggs shipped to your clinic. In other cases, donors will travel to your clinic for the retrieval. Ultimately, this is a decision that will be determined during the matching process.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(29)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        What if I want all the eggs from the round?
                                        <span className={`${selected.includes(29) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(29) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        We work with some families that want as many eggs as possible to increase the chances of having more than one child. In these cases, we can work with the family, donor, and clinic to arrange two egg retrieval cycles. The family can either split both cycles, or agree to an arrangement in which eggs from the first cycle go to the family while eggs from the second cycle go to the donor.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(30)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        Can I do a second cycle with the same donor?
                                        <span className={`${selected.includes(30) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(30) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Yes! If both parties agree to complete a second cycle, you’ll pay a reduced coordination fee of $5,000. While you won’t need to pay for screening expenses, you will be responsible for covering fees related to the donor’s medications, retrieval cycle, and shipping her portion of eggs to her existing storage facility. You can find more detailed pricing information on a second cycle in the "Pricing" section below.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(31)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        What happens if my doctor does not clear the donor who I match with?
                                        <span className={`${selected.includes(31) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(31) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        If the donor does not pass your doctor’s initial screen, we will waive our coordination fee for another match. You may also get a full refund of our coordination fee if you do not wish to match again.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(32)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        When does the donor undergo a psychological exam?
                                        <span className={`${selected.includes(32) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(32) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        After your donor has passed her medical screening, we will coordinate with your clinic to schedule a psychological exam for your donor. The psychological exam ensures that the donor understands the egg donation process and the implications of her decision to be a donor and that she fully consents to the Split program’s rules and requirements.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(33)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        How many fresh eggs will I get?
                                        <span className={`${selected.includes(33) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(33) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        At Cofertility, we exclusively work under the egg sharing model. Rather than cash compensation, our donors keep half of the eggs retrieved and donate the other half to your family. We believe this model benefits everyone involved, particularly the donor-conceived child.
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Egg share programs like ours implement meticulous testing and medical screening to ensure donors have a high likelihood of success. For those who match with a donor undergoing a fresh donation cycle, the average number of mature eggs a family receives and fertilizes is 12. The number of eggs retrieved varies by patient and cycle, but can be predicted by a donor’s age, AMH, and antral follicle count, all of which will be known to you after the donor’s initial screening. Egg share donors also often work closely with a fertility doctor to determine, based on their own medical history, the optimal number of eggs needed for their own future family-building goals. Should it make sense for the donor, they may choose to pursue a second egg-sharing cycle to maximize the chances of success for everyone involved.
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        To learn more, <a href="https://cdn.prod.website-files.com/631b5af6b94fb3254c66e456/662c28d03e752b6c4d22096f_Egg%20Sharing%20Downloadable.pdf" title="Will I get enough eggs through egg sharing?">click here</a> to download our "Egg Sharing: Will I get enough eggs?" guide.
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line italic">
                                        * Note: this number is updated periodically based on cycle outcomes.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-12">
                            <h2>Frozen egg donation</h2>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(34)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        Can we still meet with a frozen donor?
                                        <span className={`${selected.includes(34) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(34) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        If you and the donor are both open to meeting, we can absolutely facilitate a match meeting. We’ve collected all donors preferences on meeting and can let you know whether a given donor is open to this. With our frozen program, we facilitate match meetings after the donor has cycled.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(35)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        Are frozen donors open to disclosed donations?
                                        <span className={`${selected.includes(35) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(35) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Yes! Most donors who are completing a frozen donation cycle are open to either disclosed or undisclosed donations. Provided you’re both aligned on disclosure, we can absolutely facilitate the exchange of contact information between two parties. We can also still facilitate match meetings that take place once the cycle is complete. 
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(36)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        Do you know if my clinic will accept these frozen eggs?
                                        <span className={`${selected.includes(36) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(36) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Most clinics will review the retrieval clinic’s freeze and thaw protocols alongside the screening and embryology records, all of which we’re able to provide. We’re also happy to connect with your clinic to confirm whether there’s anything else they’ll need in order to accept the eggs.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(37)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        Do I have to work with the clinic where the eggs were frozen?
                                        <span className={`${selected.includes(37) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(37) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        While we have seen great outcomes with these clinics and would be happy to recommend completing your cycle there, you can also ship the frozen eggs to your clinic for fertilization and transfer. We can share the retrieving clinic’s freeze / thaw protocols along with anything else that your clinic wants to see before accepting the frozen eggs.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(38)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        Where were the eggs frozen?
                                        <span className={`${selected.includes(38) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(38) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Depending on the donor’s location, eggs were frozen at one of our partner clinics in either Los Angeles, CA or New York, NY. All partner clinics are in good standing with SART and have CAP-certified labs.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(39)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        How many frozen eggs will I get?
                                        <span className={`${selected.includes(39) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(39) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        The number of eggs that you receive will vary based on the donor’s cycle outcomes. With our egg sharing model, you must keep half of the mature eggs retrieved. Meaning, if she retrieved 18 mature eggs, you must keep all 9 frozen eggs available for donation.
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Unlike traditional egg banks, the donated eggs are not divided into multiple groups of 6, though in a limited number of cases, the donated eggs from one retrieval may be shared by up to two families. As a result, our model reduces the number of biological half siblings that your donor conceived child(ren) may have in the future. If a given donor has already donated to another family, that will be clearly noted on her profile.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(40)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        How come some donors are doing frozen cycles and some aren’t?
                                        <span className={`${selected.includes(40) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(40) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        We're currently running the frozen donation program with select clinics in select cities. As a result, we only have frozen eggs from a limited number of donors. Donors participating in our frozen egg donation program will have a note in the “About this donor” section of their profile. We also have many donors in the process of cycling now and expect to continue adding more to the platform over time.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(41)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        How do I find donors with frozen eggs available?
                                        <span className={`${selected.includes(41) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(41) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        We now have a limited number of donors participating in our frozen egg donation program. Just like our fresh split program, each donor kept half of the eggs retrieved and donated the other half to intended parents.
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Donors participating in our frozen program will have a note in the “About this donor” section of their profile.
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        In an effort to help meet the goals of all our intended parents, we make donors who are pursuing frozen cycles available to match on our platform as soon as possible.
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        If you are looking specifically for a donor in our frozen egg donation program, get in touch with us at match@cofertility.com. We have several donors who are in the process of completing their screenings who have not yet been listed on our matching platform that we may be able to share with you.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-12">
                            <h2>Browsing donors</h2>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(42)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        How do I find my donor?
                                        <span className={`${selected.includes(42) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(42) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Log into your account and click the "Find Donors" tab at the top of the screen. You can browse donor profiles at your own pace, filter your search based on your
                                        match preferences, and if you'd like, click the "Save current search" button to save your filter search criteria for future use.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(43)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        What criteria can I use to filter donor profiles?
                                        <span className={`${selected.includes(43) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(43) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Explore donor profiles with filters that let you focus on what’s most important to you, from physical features to personal qualities, including:
                                    </div>
                                    <br />
                                    <li className="ml-4 my-1">Race and heritage</li>
                                    <li className="ml-4 my-1">Jewish ancestry</li>
                                    <li className="ml-4 my-1">Hair color and style</li>
                                    <li className="ml-4 my-1">Eye color</li>
                                    <li className="ml-4 my-1">Height</li>
                                    <li className="ml-4 my-1">Level of education and school ranking</li>
                                    <li className="ml-4 my-1">Personality traits</li>
                                    <li className="ml-4 my-1">Aptitude and communication style</li>
                                    <br />
                                    <div>This allows you to refine your search based on the traits that matter most to you.</div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(44)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        How do I narrow my search criteria using filters?
                                        <span className={`${selected.includes(44) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(44) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        To refine your donor search, log into your account and click the "Find Donors" tab at the top of your screen. Use the fields on the left-hand side to filter
                                        profiles based on physical and personal traits that matter to you. If you’d like to save your filtered search to come back to later, or to inform your email
                                        preferences, just click the “save current search” button. If you do not save your search, your filters will be reset upon your next login.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(45)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        How do I save my donor searches?
                                        <span className={`${selected.includes(45) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(45) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        After logging in and applying your desired filters under the "Find Donors" tab, click the "Save current search" button to save your preferences. This allows you
                                        to easily revisit your customized search later. If you've set your email preferences to receive updates, you'll be notified when new donors matching your
                                        criteria become available.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(46)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        How do I manage my saved searches?
                                        <span className={`${selected.includes(46) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(46) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        To manage your saved searches, go to the "Favorites" section and toggle to the "Favorite Searches" tab. From there, you can easily rename or delete any of your
                                        saved searches.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(47)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        How do I learn more about a donor?
                                        <span className={`${selected.includes(47) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(47) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        While browsing, simply click on a donor's profile photo or donor code to view her full profile and access more detailed information.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(48)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        How do I favorite a donor?
                                        <span className={`${selected.includes(48) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(48) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        While browsing on the "Find Donors" tab or viewing a donor's profile, click the heart icon to favorite a donor. You can access your favorites anytime by
                                        clicking the "Favorites" button in the top right corner of your navigation bar.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(49)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        How do I access my favorited donors?
                                        <span className={`${selected.includes(49) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(49) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        To view your favorited donors, simply click the "Favorites" button located in the top right-hand corner of your navigation bar. This will show you all the
                                        donors you've favorited for easy reference.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => toggleSelected(50)} className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary">
                                        How do I see if a donor has any travel restrictions or blackout dates?
                                        <span className={`${selected.includes(50) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(50) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Any travel restrictions or blackout dates for a donor will be listed in the 'Donor Overview' section of her profile. If no restrictions are mentioned, the donor
                                        is available to begin the match process immediately.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(51)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        How do I know if a donor is in the fresh or frozen egg donation program?
                                        <span className={`${selected.includes(51) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(51) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Donors participating in our frozen program will have a note in the “About this donor” section of their profile. In frozen egg donation, the donor completes her
                                        screening at a Cofertility partner clinic before matching, and frozen eggs are then shipped to your clinic. In fresh egg donation, the donor completes her
                                        screening and retrieval after matching at a clinic you choose. To learn more about these options,{" "}
                                        <a href="https://www.cofertility.com/family-learn/cofertilitys-fresh-vs-frozen-egg-donation-program-which-is-right-for-me" target="_blank" rel="noreferrer">
                                            click here
                                        </a>
                                        .
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(52)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        Do you have waitlists for specific donors?
                                        <span className={`${selected.includes(52) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(52) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        We do not maintain waitlists for donors. If a donor appears on the platform, it means she is not currently matched with any intended parents and is available to
                                        match.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(53)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        What does it mean if a donor I previously saw disappears from the platform?
                                        <span className={`${selected.includes(53) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(53) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        If a donor is no longer visible on the platform, it means she has been matched with other intended parents or is otherwise no longer available to match.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(54)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        Can I know a donor's location?
                                        <span className={`${selected.includes(54) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(54) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        To protect our donors' privacy, we do not display their locations on their profiles. If you have specific questions about a donor’s location, feel free to
                                        contact us for more information. However, with our clinic-agnostic model, geography rarely impacts our ability to facilitate a successful match.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(55)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        What if I want to know something about a donor that isn’t listed on the profile?
                                        <span className={`${selected.includes(55) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(55) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        If you have any questions about a donor that aren't covered on her profile, feel free to reach out to us, and we'll do our best to get you the information
                                        you’re looking for.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(56)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        What happens after I place a match with a donor?
                                        <span className={`${selected.includes(56) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(56) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        You will have the support of a Cofertility Member Advocate through every step of this process. We know this is an incredibly important journey - your advocate
                                        will provide the guidance, compassion, and communication to make it a smooth experience for you and your donor.
                                    </div>
                                    <br />
                                    <div className="mt-4 answer whitespace-pre-line">
                                        You will be able to visualize your journey and action items as your journey progresses using the "Checklist" tab of your account. After you've matched with a
                                        donor, you will no longer be able to view donor profiles on our matching platform, and a member of our team will contact you to discuss the next steps. If you
                                        plan to match with multiple donors for your family-building goals, please reach out to{" "}
                                        <a href="mailto:support@cofertility.com" target="_blank" rel="noreferrer">
                                            support@cofertility.com
                                        </a>{" "}
                                        or your Member Advocate for further assistance.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-12">
                            <h2>Placing a match</h2>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(57)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        How do I place a hold on a match with a donor?
                                        <span className={`${selected.includes(57) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(57) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        To place a hold on a potential match, select the "Hold match" button at the top of their profile. You'll need to enter your payment information to pay your $500
                                        Match Initiation Fee. Once completed, the donor will be reserved for you and removed from the platform for seven days, ensuring no other intended parents can match with
                                        her while we confirm the details of the match.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(58)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        Is a Match Initiation Fee required to place a hold on a donor?
                                        <span className={`${selected.includes(58) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(58) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Yes, a $500 Match Initiation Fee is required to place a hold on a match with a donor. This ensures the donor is reserved for you and removed from the platform while we
                                        kick off with both you and your donor.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(59)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        How much is owed after matching, and how do I pay?
                                        <span className={`${selected.includes(59) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(59) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        A $500 Match Initiation Fee is required when you place a hold on a match with your donor. The remaining balance will depend on whether you've matched with a donor for a
                                        fresh cycle or a frozen cycle. For more information, please visit our{" "}
                                        <a href="https://www.cofertility.com/family/pricing" target="_blank" rel="noreferrer">
                                            pricing page
                                        </a>
                                        . A member of our team will also reach out to you with details about the remaining payments and how to complete them.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(60)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        How do I know what my next steps are?
                                        <span className={`${selected.includes(60) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(60) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        After you put a hold on a match, be on the lookout for an email from your Member Advocate. They will reach out to schedule a Zoom call to discuss the process,
                                        understand your match preferences, and answer any questions you might have. Please schedule the call as soon as possible and if you need additional
                                        availability, let your Member Advocate know. We do need to hear from you within a week of paying your Match Initiation Fee in order to keep your donor on hold.
                                    </div>
                                    <br />
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Log into your account and click the "Checklist" tab at the top of the page. This will show you any outstanding steps and provide an overview of where you are in
                                        the process.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-12">
                            <h2>Life after match</h2>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(61)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        What happens after the embryo transfer?
                                        <span className={`${selected.includes(61) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(61) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        We do not view egg donation as a one-and-done transaction. In fact, we think about your embryo transfer as the beginning of your relationship with Cofertility. We’re passionate about supporting donor-conceived children and families and as such provide a range of educational and support tools and services to help you raise happy and healthy families. You’ll retain access to those resources for life.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(62)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        What if I or my surrogate doesn’t get pregnant?
                                        <span className={`${selected.includes(62) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(62) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                    Our Match Initiation Fee and one-time coordination fee comes with a baby guarantee, and we will match you again and again until that happens.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(63)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        What happens if a medical issue comes up with my child?
                                        <span className={`${selected.includes(63) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(63) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Regardless of the match disclosure status, our donors are aware that they may be asked to provide more information in the event that your child develops any serious medical issues. We also ask our donors to proactively share any updates to their medical history so that we can pass relevant notes along to you and your family.
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        If you and the donor’s relationship is such that you are communicating these updates to one-another directly, please also share them with us so we can ensure any internal records are kept up-to-date.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-12">
                            <h2>Account settings</h2>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(64)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        How do I update or add my personal information?
                                        <span className={`${selected.includes(64) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(64) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        After logging in, click on your profile icon in the top right-hand corner and select "Settings" to update any information you’ve provided us in your account.
                                        All details remain confidential and will not be shared with donors, but help our team find your perfect match.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(65)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        How do I update my clinic and insurance information?
                                        <span className={`${selected.includes(65) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(65) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        After logging in, click your profile icon in the top right-hand corner, select "Settings," and then choose "Clinic & Insurance" from the menu on the left side
                                        of the screen. Here, you can update any relevant details from your intake form.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(66)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        How do I update or add match preferences?
                                        <span className={`${selected.includes(66) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(66) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        After logging in, click your profile icon in the top right-hand corner, select "Settings," and then choose "Match Preferences" from the left-hand menu. You can
                                        update or add any relevant details you’ve provided us in your account, so our team can help you find your perfect match.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(67)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        How do I update or add email or SMS preferences?
                                        <span className={`${selected.includes(67) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(67) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        After logging in, click your profile icon in the top right-hand corner, select "Settings," and then choose "Email Preferences" from the left-hand menu. You can
                                        choose from the following options:
                                    </div>
                                    <li className="ml-4 my-1">Receive emails when a new donor matches your saved searches</li>
                                    <li className="ml-4 my-1">Receive emails whenever any new donor joins</li>
                                    <li className="ml-4 my-1">Receive a weekly summary of all new donors</li>
                                    <li className="ml-4 my-1">Opt out of emails entirely</li>
                                </div>
                            </div>
                        </div>

                        <div className="mb-12">
                            <h2>Platform support</h2>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(68)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        Do you have additional educational resources or content?
                                        <span className={`${selected.includes(68) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(68) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Yes, once you log into your account, click the "Learn" tab at the top of the page to access additional educational resources and content. You can also visit{" "}
                                        <a href="https://www.cofertility.com/family/learn" target="_blank" rel="noreferrer">
                                            https://www.cofertility.com/family/learn
                                        </a>{" "}
                                        for more information.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(69)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        What is the documents tab for?
                                        <span className={`${selected.includes(69) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(69) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        In the documents section, you’ll find helpful guides and onboarding materials. As you move forward throughout your journey, your Member Advocate may upload
                                        documents for you to reference or request that you upload documents here, such as test results or previous records.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(70)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        Is my information protected?
                                        <span className={`${selected.includes(70) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(70) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Absolutely. Cofertility uses a proprietary data management system that allows us to manage and protect your personal information with advanced encryption and
                                        privacy protocols.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(71)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        What do I do if I forget or want to reset my password?
                                        <span className={`${selected.includes(71) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(71) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        If you've forgotten your password or need to reset it, simply click on the "Forgot password" link on the login page. Follow the prompts to securely reset your
                                        password.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(72)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        What do I do if I accidentally make multiple accounts?
                                        <span className={`${selected.includes(72) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(72) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        If you suspect you’ve accidentally created multiple accounts, please get in touch with us as soon as possible. You can email{" "}
                                        <a href="mailto:support@cofertility.com" target="_blank" rel="noreferrer">
                                            support@cofertility.com
                                        </a>{" "}
                                        with the details, and our team will help rectify the issue promptly.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(73)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        Who can I get in touch with if I have additional questions or concerns?
                                        <span className={`${selected.includes(73) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(73) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        If you've already been in touch with a member of our team during your application process, feel free to reach out to them directly. If not, you can email us at{" "}
                                        <a href="mailto:support@cofertility.com" target="_blank" rel="noreferrer">
                                            support@cofertility.com
                                        </a>
                                        .
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <h2>Pricing</h2>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(74)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        What will it cost?
                                        <span className={`${selected.includes(74) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(74) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        The total cost of a fresh egg donation journey with Cofertility includes a Match Initiation Fee, coordination fee, and third-party expenses. In addition to these costs, clinic-dependent medical expenses will vary based on your chosen clinic, and if your donor needs to travel for any part of the process, you will be responsible for those expenses. While we don’t control these costs, we can help you estimate them once your clinic and donor are selected.
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Pricing Breakdown:
                                        <ul>
                                            <li>Match Initiation Fee: $500</li>
                                            <li>Cofertility Coordination Fee: $10,000</li>
                                            <li>Third-party expenses (<span className="italic">covers essential services, including legal fees, background checks, egg storage, and insurance. These expenses are paid directly to service providers, with funds deposited into escrow upon match confirmation and any unused funds refunded</span>): $5,900*</li>
                                        </ul>
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        *Estimate based on Cofertility’s recommended service providers
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Beyond Cofertility’s fees, total costs vary based on clinic selection and may include cycle expenses, embryo creation, and donor travel. For a more detailed breakdown, visit our <a href="https://www.cofertility.com/family/pricing" title="Cofertility Pricing">pricing page</a>.
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        In a <a href="https://www.cofertility.com/family-learn/an-intended-parents-guide-to-frozen-egg-donation-with-cofertility" title="An Intended Parent's Guide to Frozen Egg Donation">frozen egg donation journey</a> with Cofertility, the donor completes her cycle prior to matching. Pricing follows a per-egg model, so the total cost of each match depends on the number of eggs available.
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        The match cost includes all coordination fees and clinic-dependent cycle expenses (donor’s medical protocol, retrieval, and medications), with no hidden fees or extra charges.
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Price per frozen egg:
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        6-8 ----- $3,500
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        9-11 ----- $3,300
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        12+ ----- $3,100
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        For example, if the donor has 7 frozen eggs available the cost would be $24,500. If she has 9 frozen eggs available the cost would be $29,700.
                                    </div>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        For a more detailed breakdown, visit our <a href="https://www.cofertility.com/family/pricing" title="Cofertility Pricing">pricing page</a>.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(75)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        Are donor eggs tax deductible?
                                        <span className={`${selected.includes(75) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(75) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        When it comes to donor egg IVF, the Internal Revenue Service (IRS) considers it a medical expense, which means it may be tax-deductible under certain circumstances. We suggest consulting with a tax professional to determine if you qualify for a deduction and how much you can deduct. Some states offer tax credits for fertility treatments, so be sure to check if your state offers this benefit.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(76)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        Can I use FSA/HSA dollars on donor eggs?
                                        <span className={`${selected.includes(76) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(76) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        When donor eggs are deemed medically necessary by a doctor who has provided a Letter of Medical Necessity (LMN), related expenses may be eligible with a flexible spending account (FSA), health savings account (HSA) or a health reimbursement arrangement (HRA). If you work with Cofertility, you will get a receipt that you can submit for reimbursement with your plan. We recommend consulting with a tax professional in advance.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(77)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        How do you handle payment?
                                        <span className={`${selected.includes(77) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(77) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Payment will depend on the type of expense incurred. Cofertility’s fees are paid directly to Cofertility through credit card or wire transfer. Administrative fees and travel fees are paid through an escrow account managed by SeedTrust, the nation’s leading egg donation escrow fund manager. Medical fees are typically paid directly to the clinic or pharmacy.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(78)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        Is the Match Initiation Fee refundable?
                                        <span className={`${selected.includes(78) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(78) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        The $500 Match Initiation Fee is refundable if the donor declines the match, her AMH comes back under the required threshold of 2.0, or if your doctor otherwise determines that she is not a good fit for an egg sharing cycle. This fee is not refundable if you have a change of heart about moving forward. Once you sign a formal agreement with Cofertility and pay for your fresh or frozen egg donation journey, the fee is no longer refundable.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(79)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        Is there a fee to access your matching platform?
                                        <span className={`${selected.includes(79) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(79) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        Nope! There is no fee to register with Cofertility. Completing <a href="https://match.cofertility.com/family/signup" title="Join Cofertility">the form</a> will allow you to create a profile on our matching platform. Once you are ready to place a hold on a match, we do require a $500 Match Initiation Fee.
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(80)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        What does a second cycle cost?
                                        <span className={`${selected.includes(80) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(80) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        If you match with a donor in our fresh egg donation program and choose to do a second cycle with the same donor, the estimated cost is $5,900 plus any  clinic-expenses associated with the donor’s medication, egg retrieval, embryo creation process, and travel expenses (if applicable). The reduced costs reflect the fact that her screening will have already been completed and a second legal agreement may not be required.
                                    </div>
                                    <ul>
                                        <li>Reduced Cofertility Coordination Fee: $5,000</li>
                                        <li>Reduced administrative fees: $900*</li>
                                        <li>Medical expenses: varies by clinic</li>
                                    </ul>
                                    <div className="mt-4 answer whitespace-pre-line italic">
                                        * estimate based on Cofertility’s recommended service providers
                                    </div>
                                </div>
                            </div>
                            <div className={`border-b mt-4 pb-5 border-neutral-500`}>
                                <h3 className="mb-0 px-6 -mx-6 font-body text-lg question relative subtitle-large">
                                    <span className="cursor-pointer hidden sm:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            width="24"
                                            height="24"
                                            className="group-hover:md:block absolute h-4 w-4 hidden my-auto top-0 bottom-0 left-0"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                            ></path>
                                        </svg>
                                    </span>
                                    <span
                                        onClick={() => toggleSelected(81)}
                                        className="flex w-full items-center justify-between text-left !no-underline cursor-pointer !font-bold hover:text-secondary"
                                    >
                                        What is the currency of your listed pricing?
                                        <span className={`${selected.includes(81) ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                                            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </h3>
                                <div style={selected.includes(81) ? { height: "auto" } : { height: "0px", overflow: "hidden", display: "none" }}>
                                    <div className="mt-4 answer whitespace-pre-line">
                                        All pricing information provided or shared on our website, contracts, and supplementary materials is exclusively denoted in United States Dollars (USD).
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FAQ;
