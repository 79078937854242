import React, { useEffect, useState, useRef, useContext, createContext } from "react";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import { ipStatuses, migrationStatuses } from "../../../../util/statuses";
import CreateMatchModal from "../../matches/CreateMatchModal";
import { AppContext } from "../../../../App";
import { calculateLogTime, extractDate, formatPhoneNumber, handleCopy } from "../../../../util/helpers";

export const StaffIntendedParentPageContext = createContext(null);

const IntendedParentPage = () => {
    const [user, setUser] = useState(null);
    const [taskTab, setTaskTab] = useState("now");
    const [checklist, setChecklist] = useState(null);
    const [currentPhase, setCurrentPhase] = useState(null);
    const [refetchUser, setRefetchUser] = useState(1);
    const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [stickyTabs, setStickyTabs] = useState(false);
    const [copiedEmail, setCopiedEmail] = useState(false);
    const [copiedID, setCopiedID] = useState(false);

    const [showTaskCenter, setShowTaskCenter] = useState(false);
    const [matchModal, setMatchModal] = useState(false);

    const [showNoteModal, setShowNoteModal] = useState(false);
    const [noteText, setNoteText] = useState("");
    const [toggleNotePinned, setToggleNotePinned] = useState(false);

    const [showContactModal, setShowContactModal] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [preferredName, setPreferredName] = useState("");
    const [pronouns, setPronouns] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [homeState, setHomeState] = useState("");
    const [country, setCountry] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [hasError, setHasError] = useState(null);

    const { request } = useContext(AppContext);
    const navigate = useNavigate();
    const { userId } = useParams();
    const tabBarRef = useRef(null);
    const statusRef = useRef(null);
    const taskCenterRef = useRef(null);

    useEffect(() => {
        const fetchIntendedParent = async () => {
            try {
                // remove this GET request all together, we should have all the needed info within local user storage
                // once we update the prescreener and general questions logic to update to user record
                const response = await request({
                    url: `/staff/intended-parents/${userId}`,
                    method: "GET",
                });

                const { intendedParent, checklist } = response;

                setUser(intendedParent);
                setFirstName(intendedParent.firstName ? intendedParent.firstName : "");
                setLastName(intendedParent.lastName ? intendedParent.lastName : "");
                setEmail(intendedParent.email);
                if (intendedParent.preferredName) setPreferredName(intendedParent.preferredName);
                if (intendedParent.pronouns) setPronouns(intendedParent.pronouns);
                if (intendedParent.phoneNumber) setPhone(intendedParent.phoneNumber);
                if (intendedParent.address1) setAddress1(intendedParent.address1);
                if (intendedParent.address2) setAddress2(intendedParent.address2);
                if (intendedParent.city) setCity(intendedParent.city);
                if (intendedParent.state) setHomeState(intendedParent.state);
                if (intendedParent.country) setCountry(intendedParent.country);
                if (intendedParent.zipcode) setZipcode(intendedParent.zipcode);

                setChecklist(checklist);
                const currentPhase = checklist.checklist_phases.filter((phase) => phase.name === intendedParent.intended_parent.family.status)[0];
                setCurrentPhase(currentPhase);
                setIsLoading(false);

                document.title = `Staff | ${intendedParent.firstName ? intendedParent.firstName : ""} ${intendedParent.lastName ? intendedParent.lastName[0] : ""}.`;

                const handleOutsideClick = (event) => {
                    if (statusRef.current && !statusRef.current.contains(event.target)) {
                        setStatusDropdownOpen(false);
                    }
                };

                document.addEventListener("click", handleOutsideClick);

                return () => {
                    document.removeEventListener("click", handleOutsideClick);
                };
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching checklist:", error);
                setHasError(error);
            }
        };

        fetchIntendedParent();
    }, [userId, request, refetchUser]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    useEffect(() => {
        const handleScroll = () => {
            if (tabBarRef.current) {
                const rect = tabBarRef.current.getBoundingClientRect();

                const shouldBeSticky = rect.top <= 0;

                // Update the state ONLY if it has changed
                if (shouldBeSticky !== stickyTabs) {
                    setStickyTabs(shouldBeSticky);
                }
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [stickyTabs]);

    const setUserStatus = async (status) => {
        try {
            await request({
                url: `/staff/intended-parents/${userId}/status`,
                method: "PUT",
                data: {
                    status,
                },
            });

            setRefetchUser((prevKey) => prevKey + 1);
            setStatusDropdownOpen(false);
        } catch (error) {
            // TODO: handle this error
            console.error("Error updating user status:", error);
        }
    };

    const toggleMultimatch = async () => {
        try {
            await request({
                url: `/staff/intended-parents/${userId}/multimatch`,
                method: "PUT",
            });

            setRefetchUser((prevKey) => prevKey + 1);
        } catch (error) {
            // TODO: handle this error
            console.error("Error updating user status:", error);
        }
    };

    if (isLoading) {
        return <div></div>;
    }

    const handleNoteChange = (event) => {
        setNoteText(event.target.value);
    };

    const closeNote = () => {
        setNoteText("");
        setShowNoteModal(false);
    };

    const saveNote = async () => {
        try {
            await request({
                url: `/staff/intended-parents/${userId}/note`,
                method: "POST",
                data: {
                    note: noteText,
                    pinned: toggleNotePinned,
                },
            });

            closeNote();
            setRefreshKey((prevKey) => prevKey + 1);
        } catch (err) {
            console.log(err);
        }
    };

    const sendAbraInvite = async () => {
        try {
            await request({
                url: `/send-invite`,
                method: "POST",
                data: {
                    email: user.email,
                    user_id: user.id,
                },
            });

            setRefetchUser((prevKey) => prevKey + 1);
        } catch (err) {
            console.log(err);
        }
    };

    const handleInputChange = (event, setState) => {
        setState(event.target.value);
    };

    const closeContactModal = () => {
        if (user.firstName) setFirstName(user.firstName);
        else setFirstName("");
        if (user.lastName) setLastName(user.lastName);
        else setLastName("");
        setEmail(user.email);
        if (user.preferredName) setPreferredName(user.preferredName);
        else setPreferredName("");
        if (user.pronouns) setPronouns(user.pronouns);
        else setPronouns("");
        if (user.phoneNumber) setPhone(user.phoneNumber);
        else setPhone("");
        if (user.address1) setAddress1(user.address1);
        else setAddress1("");
        if (user.address2) setAddress2(user.address2);
        else setAddress2("");
        if (user.city) setCity(user.city);
        else setCity("");
        if (user.state) setHomeState(user.state);
        else setHomeState("");
        if (user.country) setCountry(user.country);
        else setCountry("");
        if (user.zipcode) setZipcode(user.zipcode);
        else setZipcode("");
        setShowContactModal(false);
    };

    const saveContactEdits = async () => {
        try {
            await request({
                url: `/staff/intended-parents/${userId}/contact-info`,
                method: "PUT",
                data: {
                    firstName,
                    lastName,
                    preferredName,
                    pronouns,
                    phone,
                    email,
                    address1,
                    address2,
                    city,
                    state: homeState,
                    country,
                    zipcode,
                },
            });

            setRefetchUser(refetchUser + 1);
            setRefreshKey(refreshKey + 1);
            closeContactModal();
        } catch (err) {
            console.log(err);
        }
    };

    const completeTask = async (taskID, status) => {
        try {
            await request({
                url: `/checklist/tasks/${taskID}`,
                method: "PUT",
                data: {
                    taskID: taskID,
                    taskStatus: status,
                },
            });

            setRefetchUser((prevKey) => prevKey + 1);
        } catch (err) {
            console.log(err);
        }
    };

    const determineSubtab = () => {
        if (window.location.pathname.includes("activity")) {
            return "activity";
        } else if (window.location.pathname.includes("documents")) {
            return "documents";
        } else if (window.location.pathname.includes("notes")) {
            return "notes";
        } else if (window.location.pathname.includes("logs")) {
            return "logs";
        }

        return "overview";
    };

    const tabScroll = () => {
        if (stickyTabs) {
            tabBarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <div className="pb-20">
            <div className="divide-y divide-solid pb-10">
                <div className="flex flex-row w-1/2 pt-5 ml-10 mb-5">
                    <div onClick={() => navigate("/staff/intended-parents")} className="mr-2 cursor-pointer text-secondary text-sm font-medium">
                        INTENDED PARENTS
                    </div>
                    <span className="text-sm font-medium">{">"}</span>
                    <div className="ml-2 text-secondary text-sm font-medium">
                        {user.firstName ? user.firstName : ""} {user.lastName ? user.lastName : ""}
                    </div>
                </div>
                <div></div>
            </div>

            <div className="flex p-4 mr-30 mb-5">
                <div className="w-24 mr-2 flex justify-center">
                    <h1 className="flex items-center justify-center w-20 h-20 rounded-full px-2 py-1 text-black text-3xl" style={{ backgroundColor: "#CACD9D" }}>
                        {user.firstName ? user.firstName.charAt(0).toUpperCase() : ""}
                    </h1>
                </div>

                <div className="flex-grow flex flex-col">
                    <div className="flex gap-2 items-center">
                        <h2>
                            {user.firstName ? user.firstName : ""} {user.lastName ? user.lastName : ""} {user.preferredName ? `- "${user.preferredName}"` : ""}{" "}
                            {user.pronouns ? `(${user.pronouns[0].toUpperCase() + user.pronouns.substring(1).split("_").join("/")})` : null}
                        </h2>
                    </div>

                    <div className="child-right flex">
                        <div className="align-left flex mb-1 mr-1">
                            <div
                                className="h-7 text-secondary px-2 py-1 rounded-md border font-medium text-xs flex items-center"
                                style={{
                                    backgroundColor: ipStatuses.filter((status) => status.value === user.intended_parent.family.status)[0].bg,
                                    color: ipStatuses.filter((status) => status.value === user.intended_parent.family.status)[0].color,
                                }}
                            >
                                <div className="inline-block mr-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard" viewBox="0 0 16 16">
                                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                                    </svg>
                                </div>
                                {ipStatuses.filter((status) => status.value === user.intended_parent.family.status)[0].label}
                            </div>
                        </div>
                        {user.migration_status ? (
                            <div className="align-left flex mb-1">
                                <div
                                    className="h-7 text-secondary px-2 py-1 rounded-md border font-medium text-xs flex items-center"
                                    style={{
                                        backgroundColor: migrationStatuses.filter((status) => status.value === user.migration_status)[0].bg,
                                        color: migrationStatuses.filter((status) => status.value === user.migration_status)[0].color,
                                    }}
                                >
                                    <div className="inline-block mr-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-person-fill-add" viewBox="0 0 16 16">
                                            <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0m-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                                            <path d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4" />
                                        </svg>
                                    </div>
                                    {migrationStatuses.filter((status) => status.value === user.migration_status)[0].label}
                                </div>
                            </div>
                        ) : null}
                        <div
                            className="origin-top-right absolute min-w-min p-6 bg-white focus:outline-none shadow-hard border border-black border-solid rounded-xl overflow-hidden"
                            aria-orientation="vertical"
                            role="menu"
                            id="alpine-menu-items-1"
                            aria-labelledby="alpine-menu-button-1"
                            tabIndex="0"
                            style={statusDropdownOpen ? { zIndex: 1000 } : { display: "none" }}
                        >
                            <div role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabIndex="-1">
                                {ipStatuses.map((status) => (
                                    <div
                                        key={status.value}
                                        onClick={() => setUserStatus(status.value)}
                                        className="cursor-pointer block text-secondary px-2 py-1 rounded-md borde w-full font-medium text-sm"
                                        role="menuitem"
                                        tabIndex="-1"
                                    >
                                        {status.label}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="my-2">
                        <div className="my-1 text-sm flex items-center">
                            <div className="inline-block mr-2 text-gray-400">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
                                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                                </svg>
                            </div>
                            {formatPhoneNumber(user.phoneNumber)}
                        </div>
                        <div className="my-1 text-sm flex items-center">
                            <div className="inline-block mr-2 text-gray-400">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                                </svg>
                            </div>
                            {user.email}
                            <div className="inline-block ml-1 cursor-pointer text-secondary" onClick={() => handleCopy(user.email, setCopiedEmail)}>
                                {copiedEmail ? (
                                    // Filled in clipboard
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-clipboard-check-fill inline-block" viewBox="0 0 16 16">
                                        <path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5z" />
                                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5zm6.854 7.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708" />
                                    </svg>
                                ) : (
                                    // Empty clipboard
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-clipboard inline-block" viewBox="0 0 16 16">
                                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                                    </svg>
                                )}
                            </div>
                        </div>
                        <div className="my-1 text-sm flex items-center">
                            <div className="inline-block mr-2 text-gray-400">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-vcard" viewBox="0 0 16 16">
                                    <path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4m4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5M9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8m1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5" />
                                    <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96q.04-.245.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1 1 0 0 1 1 12z" />
                                </svg>
                            </div>
                            {user.id}
                            <div className="inline-block ml-1 cursor-pointer text-secondary" onClick={() => handleCopy(user.id, setCopiedID)}>
                                {copiedID ? (
                                    // Filled in clipboard
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-clipboard-check-fill inline-block" viewBox="0 0 16 16">
                                        <path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5z" />
                                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5zm6.854 7.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708" />
                                    </svg>
                                ) : (
                                    // Empty clipboard
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-clipboard inline-block" viewBox="0 0 16 16">
                                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                                    </svg>
                                )}
                            </div>
                        </div>
                        <div className="my-1 text-sm flex items-center">
                            <div className="inline-block mr-2 text-gray-400">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
                                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                </svg>
                            </div>
                            {user.address1}, {user.address2 ? `${user.address2},` : ""} {user.city}, {user.state}, {user.zipcode}
                        </div>
                        <div className="my-1 text-sm flex items-center">
                            <div className="inline-block mr-2 text-gray-400">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar" viewBox="0 0 16 16">
                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                                </svg>
                            </div>
                            <span title={extractDate(user.createdAt)}>Joined: {calculateLogTime(user.createdAt)}</span>
                        </div>
                    </div>

                    {/* <div className="flex items-center">
                        <span className="text-secondary text-sm font-normal cursor-pointer flex items-center">
                            Copper Profile
                            <div className="inline-block ml-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z" />
                                </svg>
                            </div>
                        </span>
                    </div> */}
                </div>

                <div className="flex">
                    {/* <div ref={taskCenterRef}>
                        <div
                            onClick={() => setShowTaskCenter(!showTaskCenter)}
                            className="mx-3 w-12 h-12 rounded-full px-2 py-1 text-black text-3xl flex items-center border-2 border-solid border-secondary text-secondary"
                            style={{ backgroundColor: "#D2E9E6" }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-card-list" viewBox="0 0 16 16">
                                <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2z" />
                                <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8m0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0M4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0" />
                            </svg>
                        </div>

                        {showTaskCenter ? (
                            <div className="min-h-[300px] min-w-[450px] mx-5 bg-white shadow-md rounded-lg" style={{ position: "absolute", top: 170, right: 270, zIndex: 10 }}>
                                <div className="flex justify-between items-center bg-secondary text-white p-4 rounded-t-lg">
                                    <span>
                                        <h4 className="inline-block">Actions Items</h4>
                                        <span className="inline-block mx-2 text-sm">
                                            (
                                            {currentPhase && currentPhase.checklist_tasks
                                                ? currentPhase.checklist_tasks.reduce((completedCount, task) => {
                                                      if (task.user_checklist_task && task.user_checklist_task.status === "complete") {
                                                          return completedCount + 1;
                                                      }

                                                      return completedCount;
                                                  }, 0)
                                                : 0}{" "}
                                            of {currentPhase.checklist_tasks ? currentPhase.checklist_tasks.length : 0})
                                        </span>
                                    </span>
                                    <span className="cursor-pointer" onClick={() => setShowTaskCenter(!showTaskCenter)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                        </svg>
                                    </span>
                                </div>

                                <div className={`bg-secondary w-full`}>
                                    <div className="flex flex-row w-full mx-5">
                                        <div
                                            onClick={() => setTaskTab(`now`)}
                                            className={`${
                                                taskTab === "now" ? "border-white font-medium" : "border-transparent"
                                            } text-white border-x-0 px-0 pb-3 border-b-3 text-sm mr-7 cursor-pointer`}
                                        >
                                            NOW
                                        </div>
                                        <div
                                            onClick={() => setTaskTab(`completed`)}
                                            className={`${
                                                taskTab === "completed" ? "border-white font-medium" : "border-transparent"
                                            } text-white border-x-0 px-0 pb-3 border-b-3 text-sm mr-7 cursor-pointer`}
                                        >
                                            COMPLETED
                                        </div>
                                        <div
                                            onClick={() => setTaskTab(`upcoming`)}
                                            className={`${
                                                taskTab === "upcoming" ? "border-white font-medium" : "border-transparent"
                                            } text-white border-x-0 px-0 pb-3 border-b-3 text-sm mr-7 cursor-pointer`}
                                        >
                                            UPCOMING
                                        </div>
                                        <div onClick={() => setTaskTab(`all`)} className={`${taskTab === 'all' ? 'border-white font-medium' : 'border-transparent'} text-white border-x-0 px-0 pb-3 border-b-3 text-sm mr-7 cursor-pointer`}>ALL</div>
                                    </div>
                                </div>

                                {taskTab === "now" ? (
                                    <div className="ml-5 pb-5">
                                        {currentPhase.checklist_tasks && currentPhase.checklist_tasks.some((task) => task.user_checklist_task.status !== "complete") ? (
                                            currentPhase.checklist_tasks.map((task) => {
                                                return (
                                                    <div>
                                                        <div className="flex justify-between items-center m-3">
                                                            <h6 className="font-sans font-semibold">{task.name}</h6>
                                                            <div className={`inline-block cursor-pointer ${task.user_checklist_task.status === "complete" ? "text-secondary" : "text-yellow-500"}`}>
                                                                <svg
                                                                    onClick={() => completeTask(task.user_checklist_task.id, "complete")}
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="24"
                                                                    height="24"
                                                                    fill="currentColor"
                                                                    className={`bi bi-check2-circle`}
                                                                    viewBox="0 0 16 16"
                                                                >
                                                                    <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                                                                    <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div className="w-3/4 ml-10 mr-2 bg-gray-100 rounded-lg p-3">
                                                            {task.description.split("\\n").map((t) => (
                                                                <div className="block">{t}</div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div>
                                                <div className="flex justify-center text-secondary p-5 mt-5">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-hand-thumbs-up-fill" viewBox="0 0 16 16">
                                                        <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a10 10 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733q.086.18.138.363c.077.27.113.567.113.856s-.036.586-.113.856c-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.2 3.2 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.8 4.8 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z" />
                                                    </svg>
                                                </div>
                                                <div className="flex justify-center text-sm text-gray-500">No more actions items! Take a break!</div>
                                                <div className="flex justify-center text-sm text-gray-500">You deserve it!</div>
                                            </div>
                                        )}
                                    </div>
                                ) : null}

                                {taskTab === "completed" ? (
                                    <div className="ml-5 pb-5">
                                        {currentPhase.checklist_tasks
                                            ? currentPhase.checklist_tasks.map((task) => {
                                                  if (task.user_checklist_task.status !== "complete") {
                                                      return null;
                                                  }

                                                  return (
                                                      <div>
                                                          <div className="flex justify-between items-center m-3">
                                                              <h6 className="font-sans font-semibold">{task.name}</h6>
                                                              <div className={`inline-block cursor-pointer ${task.user_checklist_task.status === "complete" ? "text-secondary" : "text-yellow-500"}`}>
                                                                  <svg
                                                                      onClick={() => completeTask(task.user_checklist_task.id, "upcoming")}
                                                                      xmlns="http://www.w3.org/2000/svg"
                                                                      width="24"
                                                                      height="24"
                                                                      fill="currentColor"
                                                                      className={`bi bi-check2-circle`}
                                                                      viewBox="0 0 16 16"
                                                                  >
                                                                      <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                                                                      <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                                                                  </svg>
                                                              </div>
                                                          </div>
                                                          <div className="w-3/4 ml-10 mr-2 bg-gray-100 rounded-lg p-3">
                                                              {task.description.split("\\n").map((t) => (
                                                                  <div className="block">{t}</div>
                                                              ))}
                                                          </div>
                                                      </div>
                                                  );
                                              })
                                            : null}
                                    </div>
                                ) : null}

                                {taskTab === "upcoming" ? (
                                    <div className="ml-5 pb-5">
                                        {currentPhase.checklist_tasks
                                            ? currentPhase.checklist_tasks.map((task) => {
                                                  if (task.user_checklist_task.status !== "upcoming") {
                                                      return null;
                                                  }

                                                  return (
                                                      <div>
                                                          <div className="flex justify-between items-center m-3">
                                                              <h6 className="font-sans font-semibold">{task.name}</h6>
                                                              <div className={`inline-block cursor-pointer ${task.user_checklist_task.status === "complete" ? "text-secondary" : "text-yellow-500"}`}>
                                                                  <svg
                                                                      xmlns="http://www.w3.org/2000/svg"
                                                                      width="24"
                                                                      height="24"
                                                                      fill="currentColor"
                                                                      className={`bi bi-check2-circle`}
                                                                      viewBox="0 0 16 16"
                                                                  >
                                                                      <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                                                                      <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                                                                  </svg>
                                                              </div>
                                                          </div>
                                                          <div className="w-3/4 ml-10 mr-2 bg-gray-100 rounded-lg p-3">
                                                              {task.description.split("\\n").map((t) => (
                                                                  <div className="block">{t}</div>
                                                              ))}
                                                          </div>
                                                      </div>
                                                  );
                                              })
                                            : null}
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                    </div> */}
                    <div>
                        <button className="block h-12 w-40 bg-secondary text-white border border-secondary rounded-full py-2 px-6 font-semibold mb-3" onClick={() => setShowNoteModal(!showNoteModal)}>
                            + Add Note
                        </button>
                        <button className="block h-12 w-40 bg-secondary text-white border border-secondary rounded-full py-2 px-6 font-semibold mb-3" onClick={() => setMatchModal(!matchModal)}>
                            Create Match
                        </button>
                        {user.intended_parent.family.status === "matched" ? (
                            <button
                                className={`block h-12 w-40 ${
                                    user.intended_parent.family.multimatch ? "bg-primary text-secondary" : "bg-red-500 text-white"
                                } border border-secondary rounded-full py-2 px-6 font-semibold mb-3 flex items-center`}
                                onClick={toggleMultimatch}
                            >
                                <div>Browse access</div>
                                {user.intended_parent.family.multimatch ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                    </svg>
                                )}
                            </button>
                        ) : null}
                        {/* {user.migration_status === "activation_required" ? (
                            <button className="block h-12 w-40 bg-primary text-secondary border border-secondary rounded-full py-2 px-6 font-semibold" onClick={() => sendAbraInvite()}>
                                Send Invite
                            </button>
                        ) : null} */}
                    </div>
                </div>
            </div>

            <div className={`bg-white divide-y divide-solid w-full pb-5`} ref={tabBarRef}>
                <div className="ml-30 flex flex-row w-1/2 pt-5">
                    <Link
                        to={`/staff/intended-parents/${user.id}/overview`}
                        className={`${
                            determineSubtab() === "overview" ? "border-secondary" : "border-transparent"
                        } text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                    >
                        OVERVIEW
                    </Link>
                    <Link
                        to={`/staff/intended-parents/${user.id}/activity`}
                        className={`${
                            determineSubtab() === "activity" ? "border-secondary" : "border-transparent"
                        } text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                    >
                        ACTIVITY
                    </Link>
                    <Link
                        to={`/staff/intended-parents/${user.id}/documents`}
                        className={`${
                            determineSubtab() === "documents" ? "border-secondary" : "border-transparent"
                        } text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                    >
                        DOCUMENTS
                    </Link>
                    <Link
                        to={`/staff/intended-parents/${user.id}/notes`}
                        className={`${
                            determineSubtab() === "notes" ? "border-secondary" : "border-transparent"
                        } text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                    >
                        NOTES
                    </Link>
                    {/* <Link
                        to={`/staff/intended-parents/${user.id}/photos`}
                        className={`${determineSubtab() === "photos" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                    >
                        PHOTOS
                    </Link> */}
                    <Link
                        to={`/staff/intended-parents/${user.id}/logs`}
                        className={`${determineSubtab() === "logs" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                    >
                        LOGS
                    </Link>
                </div>

                <div></div>
            </div>

            {stickyTabs ? (
                <div className={`bg-white divide-y divide-solid w-full`} style={{ position: "fixed", top: 0, zIndex: 10 }}>
                    <div className="ml-30 flex flex-row w-1/2 pt-5">
                        <Link
                            to={`/staff/intended-parents/${user.id}/overview`}
                            className={`${
                                determineSubtab() === "overview" ? "border-secondary" : "border-transparent"
                            } text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                            onClick={tabScroll}
                        >
                            OVERVIEW
                        </Link>
                        <Link
                            to={`/staff/intended-parents/${user.id}/activity`}
                            className={`${
                                determineSubtab() === "activity" ? "border-secondary" : "border-transparent"
                            } text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                            onClick={tabScroll}
                        >
                            ACTIVITY
                        </Link>
                        <Link
                            to={`/staff/intended-parents/${user.id}/documents`}
                            className={`${
                                determineSubtab() === "documents" ? "border-secondary" : "border-transparent"
                            } text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                            onClick={tabScroll}
                        >
                            DOCUMENTS
                        </Link>
                        <Link
                            to={`/staff/intended-parents/${user.id}/notes`}
                            className={`${
                                determineSubtab() === "notes" ? "border-secondary" : "border-transparent"
                            } text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                            onClick={tabScroll}
                        >
                            NOTES
                        </Link>
                        {/* <Link
                            to={`/staff/intended-parents/${user.id}/photos`}
                            className={`${determineSubtab() === "photos" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                            onClick={tabScroll}
                        >
                            PHOTOS
                        </Link> */}
                        <Link
                            to={`/staff/intended-parents/${user.id}/logs`}
                            className={`${
                                determineSubtab() === "logs" ? "border-secondary" : "border-transparent"
                            } text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                            onClick={tabScroll}
                        >
                            LOGS
                        </Link>
                    </div>

                    <div></div>
                </div>
            ) : null}

            {matchModal ? <CreateMatchModal ip={user} closeModal={() => setMatchModal(false)} /> : null}

            {showNoteModal ? (
                <div
                    className="modal"
                    style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 10,
                    }}
                >
                    <div
                        className="modal-content w-full max-w-3xl"
                        style={{
                            backgroundColor: "white",
                            padding: "40px",
                            borderRadius: "5px",
                            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.3)",
                        }}
                    >
                        <h3 className="text-2xl font-semibold mb-4 flex justify-between items-center">
                            <span className="heading-underline-forest-50 ">Staff Note</span>
                            <button onClick={() => setToggleNotePinned(!toggleNotePinned)} className="focus:outline-none">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className={`h-6 w-6 fill-current ${toggleNotePinned ? "text-yellow-600" : "text-gray-500"} hover:text-yellow-600`}
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" />
                                </svg>
                            </button>
                        </h3>
                        <textarea className="w-full" value={noteText} onChange={handleNoteChange} rows={10} cols={50} placeholder="Type your note here..." />
                        <div className="grid grid-cols-2 gap-x-6 gap-y-4 rounded-md p-4">
                            <div className="col-span-2 flex justify-end mt-4">
                                <button onClick={closeNote} className="mr-2 px-4 py-2 rounded-md bg-gray-300 hover:bg-gray-400">
                                    Close
                                </button>
                                <button onClick={saveNote} className="px-4 py-2 rounded-md bg-blue-500 hover:bg-blue-600 text-white">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            {showContactModal ? (
                <div
                    className="modal"
                    style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 10,
                    }}
                >
                    <div
                        className="modal-content w-full max-w-3xl"
                        style={{
                            backgroundColor: "white",
                            padding: "40px",
                            borderRadius: "5px",
                            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.3)",
                        }}
                    >
                        <h3 className="text-2xl font-semibold mb-4">
                            <span className="heading-underline-goldenrod mr-5">Edit Contact Details</span>
                        </h3>
                        <div className="grid grid-cols-2 gap-x-6 gap-y-4 rounded-md p-4">
                            <div>
                                <label className="text-sm font-medium">First name:</label>
                                <input className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2" onChange={(event) => handleInputChange(event, setFirstName)} value={firstName} />
                            </div>

                            <div>
                                <label className="text-sm font-medium">Last name: </label>
                                <input className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2" onChange={(event) => handleInputChange(event, setLastName)} value={lastName} />
                            </div>

                            <div>
                                <label className="text-sm font-medium">Preferred name: </label>
                                <input
                                    className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2"
                                    onChange={(event) => handleInputChange(event, setPreferredName)}
                                    value={preferredName}
                                />
                            </div>

                            <div>
                                <label className="text-sm font-medium">Pronouns: </label>
                                <input className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2" onChange={(event) => handleInputChange(event, setPronouns)} value={pronouns} />
                            </div>

                            <div>
                                <label className="text-sm font-medium">Phone number: </label>
                                <input className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2" onChange={(event) => handleInputChange(event, setPhone)} value={phone} />
                            </div>

                            <div>
                                <label className="text-sm font-medium">Email: </label>
                                <input className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2" onChange={(event) => handleInputChange(event, setEmail)} value={email} />
                            </div>

                            <div>
                                <label className="text-sm font-medium">Address 1: </label>
                                <input className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2" onChange={(event) => handleInputChange(event, setAddress1)} value={address1} />
                            </div>

                            <div>
                                <label className="text-sm font-medium">Address 2: </label>
                                <input className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2" onChange={(event) => handleInputChange(event, setAddress2)} value={address2} />
                            </div>

                            <div>
                                <label className="text-sm font-medium">City: </label>
                                <input className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2" onChange={(event) => handleInputChange(event, setCity)} value={city} />
                            </div>

                            <div>
                                <label className="text-sm font-medium">State: </label>
                                <input className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2" onChange={(event) => handleInputChange(event, setHomeState)} value={homeState} />
                            </div>

                            <div>
                                <label className="text-sm font-medium">Country: </label>
                                <input className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2" onChange={(event) => handleInputChange(event, setCountry)} value={country} />
                            </div>

                            <div>
                                <label className="text-sm font-medium">Zipcode: </label>
                                <input className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2" onChange={(event) => handleInputChange(event, setZipcode)} value={zipcode} />
                            </div>

                            <div className="col-span-2 flex justify-end mt-4">
                                <button onClick={closeContactModal} className="mr-2 px-4 py-2 rounded-md bg-gray-300 hover:bg-gray-400">
                                    Close
                                </button>
                                <button onClick={saveContactEdits} className="px-4 py-2 rounded-md bg-blue-500 hover:bg-blue-600 text-white">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            <div className="min-h-screen">
                <StaffIntendedParentPageContext.Provider
                    value={{
                        user,
                        userId: user.id,
                        refreshKey,
                        refreshHeader: refetchUser,
                        setRefreshHeader: setRefetchUser,
                        setShowNoteModal,
                        staff: true,
                        userType: "intended-parents",
                    }}
                >
                    <Outlet />
                </StaffIntendedParentPageContext.Provider>
            </div>
        </div>
    );
};

export default IntendedParentPage;
