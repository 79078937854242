import React, { createContext, useState } from "react";
import { Link, Outlet } from "react-router-dom";

export const StaffMatchesDashboardContext = createContext(null);

const MatchesPage = ({ tab }) => {
    const [matchCount, setMatchCount] = useState(0);

    const handleMatchCount = (newMatchCount) => {
        setMatchCount(newMatchCount);
    };

    const determineTab = () => {
        return "matches";
    };

    return (
        <div className="">
            <div className="divide-y divide-solid pb-10">
                <div className="flex flex-row w-1/2 pt-5 ml-10">
                    <Link
                        to={"/staff/intended-parents"}
                        className={`${determineTab() === "matches" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7`}
                    >
                        MATCHES ({matchCount})
                    </Link>
                </div>

                <div></div>
            </div>

            <div>
                <StaffMatchesDashboardContext.Provider value={{ handleMatchCount }}>
                    <Outlet />
                </StaffMatchesDashboardContext.Provider>
            </div>
        </div>
    );
};

export default MatchesPage;
