import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../../App";
import { StaffIntendedParentPageContext } from "./IntendedParentPage";

const Viewed = () => {
    const [viewed, setViewed] = useState([]);
    const [hasError, setHasError] = useState(null);
    const { request } = useContext(AppContext);
    const { user } = useContext(StaffIntendedParentPageContext);

    document.title = `Staff | ${user.firstName ? user.firstName : ""} ${user.lastName ? user.lastName[0] : ""}. Views`;

    const navigate = useNavigate();

    useEffect(() => {
        const fetchViewed = async () => {
            try {
                const response = await request({
                    url: `/staff/intended-parents/${user.id}/activity/viewed`,
                    method: "GET",
                });

                const { viewed } = response;

                setViewed(viewed);
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching viewed:", error);
                setHasError(error);
            }
        };

        fetchViewed();
    }, [user, request]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    const navigateToFavoriteUserPage = (userId) => {
        navigate(`/staff/split-members/${userId}`);
    };

    const extractDate = (dateTimeString) => {
        const options = { weekday: "short", year: "numeric", month: "short", day: "numeric" };
        const formattedDate = new Date(dateTimeString).toLocaleDateString("en-US", options);
        return formattedDate;
    };

    return (
        <div className="mx-30 mb-10">
            <table className="table-fixed w-full">
                <thead>
                    <tr>
                        <th className="w-40 text-left py-2 border-b border-dashed border-gray-300">Viewed date</th>
                        <th className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">Donor code</th>
                        <th className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">Location</th>
                        <th className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">Travel</th>
                        <th className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">Profile</th>
                    </tr>
                </thead>
                <tbody>
                    {viewed &&
                        viewed.map((view) => {
                            return (
                                <tr key={view.id}>
                                    <td className="w-40 text-left py-2 border-b border-dashed border-gray-300">{extractDate(view.created_at)}</td>
                                    <td className="w-1/4 text-left py-2 border-b border-dashed border-gray-300 text-secondary cursor-pointer">
                                        <div onClick={() => navigateToFavoriteUserPage(view.viewed_id)}>{view.viewed_user.donorCode}</div>
                                    </td>
                                    <td className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">
                                        {view.viewed_user.city}
                                        {", "}
                                        {view.viewed_user.state}
                                    </td>
                                    <td className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">{view.viewed_user.freeze_member.travel_availability}</td>
                                    <td className="w-1/4 text-left py-2 border-b border-dashed border-gray-300" onClick={() => navigateToFavoriteUserPage(view.viewed_id)}>
                                        GO
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
};

export default Viewed;
