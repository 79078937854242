import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Auth0ProviderWithNavigate } from './components/auth/Auth0Provider';

import './styles.scss';
import AppRouter from './App';
import reportWebVitals from './reportWebVitals';

import TagManager from '@sooro-io/react-gtm-module'

let env = process.env.REACT_APP_ENV;
if (env === "prod") {
    const tagManagerArgs = {
        gtmId: 'GTM-PX4LCN6',
    }
    TagManager.initialize(tagManagerArgs)
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Auth0ProviderWithNavigate>
            <AppRouter />
        </Auth0ProviderWithNavigate>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
